@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.module {
	padding-left: 20vw;
}

.heading {
	padding-right: 10vw;
}

/* * * Анимация по скроллу для всех превью * * */
//.commonIntersect .preview {
//	opacity: 0;
//	transform: translateY(75%);
//	transition:
//		opacity var(--slow),
//		transform var(--slow);
//
//	picture {
//		clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
//		transition: clip-path var(--slow);
//	}
//
//	@include from($from-sm) {
//		@for $i from 1 through 10 {
//			&:nth-child(#{$i}) {
//				z-index: #{11 - $i};
//				transition-delay: #{.3 * $i}s;
//
//				picture {
//					transition-delay: #{.3 * $i}s;
//				}
//			}
//		}
//	}
//}
//
//.commonIntersect.visible .preview {
//	opacity: 1;
//	transform: none;
//
//	picture {
//		clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
//	}
//}
