@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.module {
	position: relative;
	height: 100vh;
	background: var(--graphite);

	@include to($to-sm) {
		height: 156.25vw;
	}

	cursor: pointer;

	@include from($from-sm) {
		cursor: none;
	}

	&:focus-visible {
		outline: none;

		&:before {
			position: absolute;
			z-index: 10;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			border: 2px solid var(--gray);
			pointer-events: none;
			content: '';
		}
	}
}

.content {
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-end;
	position: relative;
	z-index: 6;
	padding-top: 56px;
	padding-bottom: 56px;
	height: 100%;
	background: linear-gradient(0deg, rgba(17, 17, 17, 0.6), rgba(17, 17, 17, 0.6));
	box-sizing: border-box;
	pointer-events: none;
	transition: opacity var(--fast);

	@include to($to-md) {
		padding-top: 28px;
		padding-bottom: 28px;
	}
}

.bg {
	position: absolute;
	z-index: 2;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
	transition: opacity var(--fast);

	img {
		object-fit: cover;
	}
}

.video {
	display: block;
	position: absolute;
	z-index: 3;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
	object-fit: cover;
}

.player {
	display: block;
	position: absolute;
	z-index: 1;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: var(--dark);
	overflow: hidden;

	iframe {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		border: none;
	}
}

.cursor {
	z-index: 8 !important;
	opacity: 1;
	transition: opacity var(--fast);

	@include to($to-sm) {
		left: 50% !important;
		top: 50% !important;
	}
}

.progress {
	z-index: 7;
}

.fade .bg,
.fade .cursor,
.fade .content {
	opacity: 0;
	transition: opacity var(--slow);
}