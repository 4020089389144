@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

@keyframes fadeIn {
	from { opacity: 0; transform: translateY(5%); }
	to { opacity: 1; transform: translateY(0); }
}

@keyframes fadeOut {
	from { opacity: 1; }
	to { opacity: 0; }
}

.select {
	display: block;
	position: relative;
}

.header {
	display: flex;
	align-items: center;
	position: relative;
	padding: 10px 0;
	min-height: 52px;
	width: 100%;
	font: 300 24px/1.32 var(--font);
	color: var(--gray);
	background: none;
	border: 0;
	box-sizing: border-box;
	overflow: visible;
	transition: color var(--trans);
	cursor: pointer;

	span {
		display: block;
		width: 100%;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	&:before {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		height: 1px;
		background: var(--gray);
		transform-origin: 50% 0;
		transform: scaleY(1);
		transition:
			background var(--trans),
			transform var(--trans);
		pointer-events: none;
		content: '';
	}

	@include to($to-md) {
		font: 300 14px/1.32 var(--font);
	}
	@include to($to-sm) {
		font: 300 14px/1.32 var(--font);
	}
}

.header:hover,
.header.isActive {
	color: var(--white);

	&:before {
		opacity: 1;
		transform: scaleY(3);
		background: var(--accent);
	}
}

.drop {
	position: absolute;
	z-index: 1;
	left: 0;
	top: 100%;
	right: 0;
	margin-top: 6px;
	background: var(--dark);
	border: 1px solid var(--stroke-card);
	pointer-events: none;
	animation: fadeOut 300ms var(--ease) forwards;
}
.drop.isActive {
	pointer-events: auto;
	animation: fadeIn 600ms var(--ease) forwards;
}

.option {
	display: flex;
	align-items: center;
	position: relative;
	padding: 4px 18px 4px 24px;
	min-height: 80px;
	width: 100%;
	font: 300 24px/1.1 var(--font);
	color: var(--white);

	@include to($to-md) {
		padding: 2px 12px;
		min-height: 48px;
		font: 300 14px/1.1 var(--font);
	}
	@include to($to-sm) {
		padding: 2px 12px;
		min-height: 40px;
		font: 300 14px/1.1 var(--font);
	}
}

.option[disabled] {
	color: var(--gray);
	pointer-events: none;
}

.option:hover {
	color: var(--accent);
}

.option.selected {
	color: var(--accent);
}

.option ~ .option {
	border-top: 1px solid var(--stroke-card);
}