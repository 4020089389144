.flex {
	display: flex;
}

.row {
	flex-direction: row;
}
.rowReverse {
	flex-direction: row-reverse;
}

.column {
	flex-direction: column;
}
.columnReverse {
	flex-direction: column-reverse;
}

.wrap {
	flex-wrap: wrap;
}
.nowrap {
	flex-wrap: nowrap;
}
