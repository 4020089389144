@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';
@import '/src/core/CardsSlider/CardsSlider.module';

.module {
	position: relative;
}

.heading {
	position: relative;
	z-index: 3;
	margin-bottom: 104px;

	@include to($to-lg) {
		margin-bottom: 72px;
	}
	@include to($to-md) {
		margin-bottom: 32px;
	}
	@include to($to-sm) {
		margin-bottom: 40px;
	}
}

.slider {
	@include from($from-sm) {
		cursor: none;
	}
}

.preview {
	display: block;
	position: relative;
	flex-shrink: 0;
	color: var(--gray);
	background: var(--dark);
	box-sizing: border-box;
	overflow: hidden;
	height: 100%;
	transition:
		color var(--fast),
		height var(--fast);

	&:before {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		border: 2px solid var(--stroke-card);
		transition: opacity var(--fast);
		pointer-events: none;
		content: '';

		@include to($to-sm) {
			border-width: 1px;
		}
	}
}

/* * * Content * * */
.preview .content {
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-between;
	position: relative;
	z-index: 2;
	min-height: 100%;
	padding: 32px;
	box-sizing: border-box;
	transition:
		color var(--fast),
		background var(--fast);

	@include to($to-sm) {
		padding: 16px;
	}
}

.preview .num {
	margin-top: 32px;

	@include range($from-md, $to-hd) {
		font: 400 5.75vw/1.08695652173913 var(--font);
	}
}

.preview .text {
	font: 300 1.5vw/1.332 $font;

	@include to($to-md) {
		font: 300 var(--fs-16) $font;
	}
	@include to($to-sm) {
		font: 300 var(--fs-12) $font;
	}
}

/* * * Active * * */
:global(.is-mouse) {
	.preview:hover {
		z-index: 2;
		color: var(--white);

		&:before {
			opacity: 0;
		}
	}
	.preview:hover .content {
		background: var(--accent);
	}
}

:global(.is-touch .splide__slide.is-active) {
	.preview {
		z-index: 2;
		color: var(--white);

		&:before {
			opacity: 0;
		}
	}
	.preview {
		background: var(--accent);
	}
}
