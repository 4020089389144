.form {
	position: relative;
}

.formWrapper {
	position: relative;
}

.form.success {
	opacity: 0;
	transition: opacity var(--slow);
	pointer-events: none;
}