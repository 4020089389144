@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.article {
	position: relative;
}

.article ~ .article {
	margin-top: 168px;
}

.layout {
	display: flex;
	flex-flow: row nowrap;

	@include to($to-sm) {
		display: block;
	}
}

.aside {
	position: relative;
	flex-shrink: 0;
	width: 37.5%;
	margin-right: 12.5%;
	box-sizing: border-box;
}

.asideBox {
	position: sticky;
	top: 20px;

	picture {
		display: block;
		position: relative;

		&:before {
			display: block;
			height: 0;
			padding-top: 112.5%;
			content: '';
		}

		img {
			object-fit: cover;
		}

		//span, img {
		//	display: block !important;
		//	width: 100% !important;
		//	height: auto !important;
		//}
	}

	picture ~ picture {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;

		img,
		span {
			display: block !important;
			width: 100% !important;
			height: 100% !important;
			object-fit: cover;
		}
	}
}

.mobPicture {
	display: block;
	position: relative;
	margin: 32px 0; // 32px -16px;

	&:first-child {
		margin-top: 0;
	}
	&:last-child {
		margin-bottom: 0;
	}

	span, img {
		display: block !important;
		width: 100% !important;
		height: auto !important;
	}
}

.content {
	width: 100%;
}

.heading {
	margin-bottom: 88px;
	padding-top: 16px;
	border-top: 1px solid var(--stroke-light);

	@include to($to-sm) {
		margin-bottom: 32px;
		padding-top: 0;
		border-top: none;
	}
}

.title {
	margin-bottom: 20px;

	@include to($to-sm) {}

	a {
		display: block;
		position: relative;
		padding-right: 32px;
		text-decoration: none;
		color: inherit;

		svg {
			fill: var(--stroke-light);
			transition: fill var(--trans);
		}

		&:hover {
			color: var(--accent);

			svg {
				fill: var(--accent);
			}
		}
	}
}

.arrow {
	position: absolute;
	top: .25em;
	right: 0;
	width: 30px;
	height: 30px;
}

.date {
	display: block;
	color: var(--gray);
}

/*
 * Текстовые стили для статейного блока
 */
.articleText {
	/*
	 * Заголовки h1-h6
	 */
	:global {
		h1, .h1 {
			margin-top: 0;
			margin-bottom: 120px;

			@include to($to-lg) {
				margin-bottom: 88px;
			}
			@include to($to-sm) {
				margin-bottom: 56px;
			}

			color: var(--text);

			:global(.is-dark-section) & {
				color: var(--white);
			}
		}

		h2, .h2,
		h3, .h3,
		h4, .h4,
		h5, .h5,
		h6, .h6 {
			margin-top: 168px;
			margin-bottom: 56px;

			@include to($to-lg) {
				margin-top: 112px;
			}
			@include to($to-md) {
				margin-top: 72px;
				margin-bottom: 48px;
			}
			@include to($to-sm) {
				margin-top: 56px;
				margin-bottom: 24px;
			}

			&:first-child {
				margin-top: 0;
			}
			&:last-child {
				margin-bottom: 0;
			}

			color: var(--text);

			:global(.is-dark-section) & {
				color: var(--white);
			}
		}

		hr + h2, hr + .h2,
		hr + h3, hr + .h3,
		hr + h4, hr + .h4,
		hr + h5, hr + .h5,
		hr + h6, hr + .h6 {
			margin-top: 0;
			margin-bottom: 88px;

			@include to($to-sm) {
				margin-bottom: 32px;
			}
		}
	}

	/*
	 * Горизонтальный разделитель
	 */
	hr {
		margin: 168px 0 16px;

		@include to($to-lg) {
			margin: 112px 0 16px;
		}
		@include to($to-md) {
			margin: 72px 0 16px;
		}
		@include to($to-sm) {
			margin: 56px 0 16px;
		}
	}

	/*
	 * Списки
	 */
	ol {
		font: 400 30px/1.4 var(--font);
		letter-spacing: -0.02em;

		@include from($from-hd) {
			font: 400 1.875vw/1.4 var(--font);
		}

		@include to($to-md) {
			font: 400 24px/1.4 var(--font);
		}
		@include to($to-sm) {
			font: 400 16px/1.24 var(--font);
		}

		margin: 88px 0;

		@include to($to-sm) {
			margin: 32px 0;
		}

		li {
			padding-top: 8px;
			color: var(--text);
			border-top: 1px solid var(--stroke-light);

			:global(.is-dark-section) & {
				color: var(--white);
				border-top-color: var(--stroke-dark);
			}

			&:before {
				top: 8px;
			}

			margin-bottom: 88px;

			@include to($to-sm) {
				margin-bottom: 32px;
			}
		}
	}
}