@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.section {
	padding: 104px 0;
	border: solid var(--stroke-dark);
	border-width: 1px 0;

	@include to($to-lg) {
		padding: 64px 0;
	}
	@include to($to-md) {
		padding: 48px 0;
	}
	@include to($to-sm) {
		padding: 32px 0;
	}
}

.pre {
	margin-bottom: 8px;

	@include to($to-sm) {
		margin-bottom: 4px;
	}
}
