@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.module {
	position: relative;
	padding: 0;
	margin-bottom: -1px;
	overflow: hidden;
}

.head {
	margin-bottom: 56px;
}

.feed {
	position: relative;
}

.card {
	position: relative;
}