@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.wrap {
	position: relative;
	opacity: 1;
	transform: translateY(0);
	transition:
		opacity .8s var(--ease),
		transform var(--medium);

	&.isLoading {
		opacity: 0;
		transform: translateY(10vh);
	}
}

/* * * Preamble * * */
.preamble {
	display: flex;
	flex-flow: row wrap;
	position: relative;
	text-decoration: none;
	color: inherit;

	&:before {
		position: absolute;
		top: 0;
		right: -10vw;
		height: 44vh;
		width: 16px;
		background: var(--accent);
		content: none;

		@include to($to-lg) {
			right: -5vw;
			width: 8px;
		}
		@include to($to-md) {
			right: -$gapSM;
			width: 4px;
		}
		@include to($to-sm) {
			right: -$gap;
		}
	}
}

.date {
	width: 12.5%;
	padding-right: 30px;
	padding-top: 10px;
	font: 300 var(--fs-16) var(--font);
	color: var(--gray);
	box-sizing: border-box;

	@include to($to-lg) {
		width: 15%;
	}
	@include to($to-md) {
		width: 20%;
		font: 300 var(--fs-14) var(--font);
	}
	@include to($to-sm) {
		width: 100%;
		margin-bottom: 12px;
		font: 300 var(--fs-12) var(--font);
	}
}

.main {
	width: 87.5%;

	@include to($to-lg) {
		width: 85%;
	}
	@include to($to-md) {
		width: 80%;
	}
	@include to($to-sm) {
		width: 100%;
	}
}

.title {
	font: 400 52px/1.115 var(--font);

	@include from($from-hd) {
		font: 400 3.25vw/1.115 var(--font);
	}

	@include to($to-md) {
		font: 400 28px/1.115 var(--font);
	}
	@include to($to-sm) {
		font: 400 24px/1.115 var(--font);
	}
}

.records {
	margin-top: 32px;
	margin-right: -30px;

	@include to($to-md) {
		margin-top: 24px;
	}
	@include to($to-sm) {
		margin-top: 16px;
		margin-bottom: -12px;
	}
}

.record {
	width: 33.332%;
	padding-right: 30px;
	box-sizing: border-box;

	font: 300 var(--fs-24) var(--font);
	color: var(--text);

	@include to($to-md) {
		width: auto;
		font: 300 var(--fs-18) var(--font);
	}
	@include to($to-sm) {
		margin-bottom: 12px;
		font: 300 var(--fs-14) var(--font);
	}
}

.recordCaption {
	margin-bottom: 16px;
	font: 300 var(--fs-16) var(--font);
	color: var(--gray);

	@include to($to-md) {
		margin-bottom: 8px;
		font: 300 var(--fs-14) var(--font);
	}
	@include to($to-sm) {
		margin-bottom: 4px;
		font: 300 var(--fs-12) var(--font);
	}
}

/* * * Content * * */
.content {
	margin-top: 110px;

	@include to($to-md) {
		margin-top: 32px;
	}
	@include to($to-sm) {
		margin-top: 24px;
	}

	&:first-child {
		margin-top: 0;
	}
}

.section {
	margin-left: 12.5%;
	margin-top: 40px;
	padding-top: 8px;
	border-top: 1px solid var(--stroke-light);

	@include to($to-lg) {
		margin-left: 15%;
	}
	@include to($to-md) {
		margin-left: 20%;
	}
	@include to($to-sm) {
		margin-left: 0;
		margin-top: 32px;
	}
}

.sectionCaption {
	margin-bottom: 24px;

	@include to($to-sm) {
		margin-bottom: 16px;
	}
}

.sectionText {
	p {
		margin: 4px 0;

		@include to($to-sm) {
			margin: 10px 0;
		}
	}

	ul > li {
		margin: 0 0 4px;
		padding-left: 12px;

		@include to($to-sm) {
			margin: 0 0 10px;
		}
	}

	ul > li:before {
		left: 0;
		top: 0;
		width: auto;
		height: auto;
		background: none;
		content: '/';
	}
}

/* * * Footer * * */
.footer {
	margin-left: 12.5%;
	margin-top: 88px;

	@include to($to-lg) {
		margin-left: 15%;
		margin-top: 56px;
	}
	@include to($to-md) {
		margin-left: 20%;
	}
	@include to($to-sm) {
		margin-left: 0;
		margin-top: 32px;
	}
}
