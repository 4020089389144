@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.module {
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	position: absolute;
	z-index: 10;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	padding: 5vh 0;
	background: var(--stroke-card);
	// clip-path: polygon(50% 0, 50% 0, 50% 100%, 50% 100%);
	will-change: width;

	@include to($to-md) {}
	@include to($to-sm) {}

	:root {
		--odometerA: 0;
		--odometerB: 0;
		--odometerC: 0;
		--odometerD: 0;
	}
}

.bg {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	pointer-events: none;

	video, img {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&:after {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(0deg, rgba(17, 17, 17, 0.6), rgba(17, 17, 17, 0.6));
		content: '';
	}
}

.layout {
	display: flex;
	flex-flow: row nowrap;
	position: relative;
}

.left {
	width: 75%;
}

.right {
	position: relative;
	width: 25%;
	margin-top: 1.375vw;
	opacity: 0;
	transition: opacity var(--medium);

	&:before {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		border-top: 1px solid;
		opacity: 0;
		transform-origin: 0 0;
		transform: scaleX(.25);
		transition: all var(--medium);
		content: '';
	}
}

/* * * Odometer * * */
.odometerWrap {
	overflow: hidden;
	height: .8em;
	margin: 0;
	font: 400 23.125vw/1 var(--font);
	letter-spacing: -0.04em;
	text-align: center;
	mask-image: linear-gradient(180deg, transparent 0%, black 4%, black 96%, transparent 100%);
	mask-mode: alpha;

	@include smallVH {
		// font: 400 46.25vh/1 var(--font);
	}
}

.odometer {
	display: flex;
	margin: -.1em 0 -.1em -.0297em;
	height: 1em;
}

.odometerC {
	display: flex;
	flex-flow: column nowrap;
	position: relative;

	&:nth-child(1) {
		transform: translateY(var(--odometerA)) translateZ(0);
	}
	&:nth-child(2) {
		transform: translateY(var(--odometerB)) translateZ(0);
	}
	&:nth-child(3) {
		transform: translateY(var(--odometerC)) translateZ(0);
	}
	&:nth-child(4) {
		transform: translateY(var(--odometerD)) translateZ(0);
	}
}

.odometerD {
	display: block;
	position: relative;
	height: 1em;
}

.odometerAccent {
	color: var(--accent);
}

.odometerC:nth-child(2) .odometerD {
	margin-top: .3em;
}
.odometerC:nth-child(3) .odometerD {
	margin-top: .8em;
}
.odometerC:nth-child(4) .odometerD {
	margin-top: 1.3em;
}

/* * * Counter * * */
.counter {
	display: flex;
	position: absolute;
	left: 0;
	right: 0;
	bottom: .5vw;
	height: 1em;
	font: 400 2.375vw/1 var(--font);
	letter-spacing: -0.02em;
	color: var(--white);
	overflow: hidden;
	opacity: 0;
	transition: opacity var(--medium);

	span {
		margin-left: .25em;
	}
}

.counterTrack {
	display: flex;
	flex-flow: column nowrap;
	position: relative;
	height: 100%;
	text-align: center;
	transform: translateY(var(--odometerC)) translateZ(0);

	div {
		height: 1em;
		box-sizing: border-box;
	}

	div ~ div {
		margin-top: .8em;
		height: 1em;
	}
}

/* * * Symbol * * */
.symbol {
	display: block;
	width: .976em;
	padding: 0 .5em;
	margin-left: -.5em;
	height: 1em;
	margin-bottom: .1em;
	font-size: 10.75vw; // 172px;
	overflow: hidden;
	mask-image: linear-gradient(90deg, transparent 0%, black 25%, black 75%, transparent 100%);
	mask-mode: alpha;

	@include from($from-hd) {
		font-size: 172px;
	}
	@include smallVH {
		// font-size: 21.5vh;
	}
}

.symbolTrack {
	display: flex;
	flex-flow: row nowrap;
	margin-left: -.976em;
	width: calc(.976em * 3);
	transform: translateX(var(--symbolsX)) translateZ(0);
}

.symbolIcon {
	display: block;
	position: relative;
	flex-shrink: 0;
	padding: 0 .976em;
	width: .976em;
	height: 1em;

	svg {
		display: block;
		width: 100%;
		height: 100%;
		fill: currentColor;
	}
}

/* * * Texts * * */
.text {
	font: 400 2.375vw/1.3 var(--font); // fs: 38px
	letter-spacing: -0.02em;
}

.textHelper {
	height: 0;
	overflow-x: scroll;
	white-space: nowrap;
	opacity: 0;
	pointer-events: none;
}

.textLine {
	margin-top: 8px;
	height: 1.3em;
	overflow: hidden;
	mask-image: linear-gradient(180deg, transparent 0%, black 20%, black 80%, transparent 100%);
	mask-mode: alpha;
}

.text .textTrack {
	display: flex;
	flex-flow: column nowrap;
	position: relative;
	height: 100%;
	transform: translateY(var(--odometerA));

	div {
		height: 1.3em;
		box-sizing: border-box;
	}
}

.textLine:nth-child(3) .textTrack {
	transform: translateY(var(--odometerC));

	div {
		margin-bottom: .9615em;
	}
}

/* * * Ticker * * */
.ticker {
	margin-top: 5vh;
	font: 300 11.25vw/1 var(--font); // fs: 180px
	overflow: hidden;

	@include smallVH {
		// font: 300 22.5vh/1 var(--font);
	}
}

.tickerWrap {
	display: block;
	position: relative;
}

.tickerTrack {
	display: inline-flex;
	position: relative;
	left: calc(100% + 10vw);
	padding: 0 10vw 0 0;
	white-space: nowrap;
}

/* * * Activate * * */
.isActive .right {
	opacity: 1;
}

.isActive .right:before {
	opacity: 1;
	transform: none;
}

.isActive .counter {
	opacity: .3;
}
