@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

$rectTranslateY: 36px;
$rectMobTranslateY: 20px;

.white {
	color: var(--dark);
	background: var(--white);
}
.dark {
	color: var(--white);
	background: var(--dark);
}
.graphite {
	color: var(--white);
	background: var(--graphite);
}

.outer {
	position: relative;
}

.inner {
	position: relative;

	@include from($from-md) {
		background: transparent;
	}
}

.innerPadTop {
	padding-top: $offset;

	@include to($to-lg) {
		padding-top: $offsetLG;
	}
	@include to($to-md) {
		padding-top: $offsetMD;
	}
	@include to($to-sm) {
		padding-top: $offsetSM;
	}
}

.innerPadBottom {
	padding-bottom: 21.5vw;

	@include from($from-hd) {
		padding-bottom: 344px;
	}

	@include to($to-md) {
		padding-bottom: 10vw;
	}
	@include to($to-sm) {
		padding-bottom: 17.5vw; // 56px / 320px
	}
}

.base {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	height: 100%;
}

.bg {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	height: 32.25%;
}

.stripH {
	position: absolute;
	left: 0;
	right: 0;
	transform-origin: 100% 50%;
	will-change: transform;
	pointer-events: none;

	&:nth-child(1) {
		top: 32%;
		height: 13%;
	}
	&:nth-child(2) {
		top: calc(32% + 12%);
		height: 15%;
	}
	&:nth-child(3) {
		top: calc(32% + 12% + 14%);
		height: 20%;
	}
	&:nth-child(4) {
		top: calc(32% + 12% + 14% + 19%);
		height: 23%;
	}

	@include to($to-md) {
		display: none;
	}
}

.startScreen .bg,
.startScreen .inner,
.startScreen .stripH {
	opacity: 0;
	pointer-events: none;
}

.startScreen .stripV {
	top: 150vh;
	transition: top .8s;
	transition-delay: 1s;

	&:nth-child(2) {
		transition-delay: .8s;
	}
	&:nth-child(3) {
		transition-delay: .6s;
	}
	&:nth-child(4) {
		transition-delay: .4s;
	}
	&:nth-child(5) {
		transition-delay: .2s;
	}
}

.startScreenShown .bg,
.startScreenShown .inner,
.startScreenShown .stripH {
	opacity: 1;
	pointer-events: auto;
	transition: opacity .8s;
	transition-delay: 1.6s;
}
.startScreenShown .stripV {
	top: 0;
}