@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.pageUpBtn {
	position: fixed;
	z-index: 20;
	right: .7692em;
	bottom: .7692em;
	width: 1em;
	height: 1em;
	font-size: 104px;
	color: var(--white);
	opacity: 0;
	transform: translateY(100%);
	pointer-events: none;
	transition:
		opacity var(--medium),
		transform var(--medium);

	@include range($from-sm, $to-hd) {
		font-size: 6.5vw;
	}
	@include range($from-sm, $to-lg) {
		right: .5em;
		bottom: .5em;
	}
	@include to($to-sm) {
		right: 16px;
		bottom: 16px;
		font-size: 48px;
	}

	&:before {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background: var(--accent);
		clip-path: inset(0);
		transition:
			clip-path var(--trans),
			background var(--trans);
		content: '';
	}

	&:hover:before {
		@include from($from-sm) {
			clip-path: inset(10px);
		}
	}

	svg {
		display: block;
		position: relative;
		margin: 0 auto;
		width: .288461538461538em;
		height: .288461538461538em;
		fill: currentColor;

		@include to($to-sm) {
			width: 19px;
			height: 19px;
		}
	}

	&.isShown {
		opacity: 1;
		transform: none;
		pointer-events: auto;
	}
}
