@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

$cardHeight: 460px;

$cardDisplacement: 64px;
$cardDisplacementSM: 48px;

$cardShift: 40px;

.main {
	position: relative;
	min-height: 100vh;
	margin-bottom: -1px;
}

.sticky {
	display: flex;
	flex-flow: column nowrap;
	align-items: stretch;
	justify-content: space-between;
	position: sticky;
	z-index: 1;
	top: 0;
	padding: 6vh 0 0;
	height: 101vh;
	box-sizing: border-box;
	overflow: hidden;

	@include to($to-md) {
		padding-top: 12vh;
	}
	@include to($to-sm) {
		padding-top: 56px;
	}
}

.title {
	position: relative;
}

.wrap {
	margin-top: 24px;
	height: 50vh;
	max-height: $cardHeight;

	@include smallVH {
		height: 60vh;
		max-height: none;
	}

	@include to($to-md) {
		height: 60vh;
		max-height: none;
	}
	@include to($to-sm) {
		margin-top: 56px;
	}
}

.elevated {
	height: 60vh;
	max-height: none;
}

.ticker {
	display: inline-flex;
	position: relative;
	height: 100%;
	will-change: transform;
}

.feed {
	display: flex;
	flex-flow: row nowrap;
	align-items: flex-end;
	height: 100%;

	@include to($to-sm) {}
}

.card {
	flex-shrink: 0;
	height: 100%;
	width: 40vw;
	min-width: 420px;
	margin-left: -2px;
	margin-bottom: 1px;
	transition: height var(--trans);

	@include to($to-sm) {
		width: 260px;
		min-width: 260px;
	}

	&:not(.cardAutoHeight) {
		&:nth-child(3n + 1) {
			height: calc(100% - #{$cardDisplacement * .5} - #{$cardShift});

			@include smallVH {
				height: calc(100% - #{$cardDisplacementSM * .5} - #{$cardShift});
			}
		}
		&:nth-child(3n + 2) {
			height: calc(100% - #{$cardShift});

			@include smallVH {
				height: calc(100% - #{$cardShift});
			}
		}
		&:nth-child(3n + 3) {
			height: calc(100% - #{$cardDisplacement} - #{$cardShift});

			@include smallVH {
				height: calc(100% - #{$cardDisplacementSM} - #{$cardShift});
			}
		}

		&:hover {
			height: calc(100%);
		}
	}
}
