$perc: unquote('%');

$hd:          1280px            !default;
$hdp:         1600px            !default;
$fhd:         1920px            !default;

$minWidth:    320px             !default;
$maxWidth:    1600px            !default;

$from-xs:	    320px             !default;
$to-xs:       $from-xs - 1     	!default;

$from-sm:    	640px             !default;
$to-sm:      	$from-sm - 1    	!default;

$from-md:   	1024px            !default;
$to-md:     	$from-md - 1   		!default;

$from-lg:    	$hd - 20          !default;
$to-lg:      	$from-lg - 1    	!default;

$from-hd:     $hdp              !default;
$to-hd:       $from-hd - 1      !default;

$gap: 16px;
$gapSM: 32px;
$gapMD: 50px;

$pad: 5%; // 16px / 320px
// $padLG: 6.25%; // 100px / 1600px
$padLG: 10%; // 160px / 1600px
$padHD: 10%; // 160px / 1600px

$padVw: 5vw;
$padVwLG: 10vw;

$outPad: 5.5555555555556%; // 16px / 288px (320px - 16px * 2)
$outPadLG: 12.5%; // 160px / 1280px (1600px - 160px * 2)

$font: 'Suisse Intl', sans-serif;

$fs-16: #{16px}/#{1.5};
$fs-14: #{14px}/#{1.5};
$fs-12: #{12px}/#{1.5};
$fs-10: #{10px}/#{1.5};

$offset: 240px;
$offsetLG: 180px;
$offsetMD: 120px;
$offsetSM: 88px;

$offsetShort: 168px;
$offsetShortLG: 124px;
$offsetShortMD: 88px;
$offsetShortSM: 56px;