@function toRGB($color) {
  @return "rgb(" + red($color) + ", " + green($color) + ", " + blue($color)+ ")";
}

@mixin vp-full {
  height: 100vh;

  @supports (height: var(--vh)) {
    height: var(--vh);
  }
}

@mixin vp-full-min {
  min-height: 100vh;

  @supports (min-height: var(--vh)) {
    min-height: var(--vh);
  }
}

@mixin vw-full {
  position: relative;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;

  @supports (width: var(--vw)) {
    width: var(--vw);
    margin-left: calc(var(--vw) / -2);
    margin-right: calc(var(--vw) / -2);
  }
}

// flex
@mixin flex-end { display: flex; flex-flow: column nowrap; justify-content: flex-end; }
@mixin flex-center { display: flex; flex-flow: column nowrap; justify-content: center; }

// drops
@mixin drop-regular { opacity: 0; visibility: hidden; pointer-events: none; transition: opacity var(--trans), visibility var(--trans); }
@mixin drop-regular-show { opacity: 1; visibility: visible; pointer-events: auto; }

// scroll block
@mixin vertical-scroll { overflow-x: hidden; overflow-y: auto; -webkit-overflow-scrolling: touch; -ms-overflow-style: none; scrollbar-width: none; }
@mixin horizontal-scroll { overflow-y: hidden; overflow-x: auto; -webkit-overflow-scrolling: touch; -ms-overflow-style: none; scrollbar-width: none; }

// breakpoints
@mixin to($to) {
  @media (max-width: $to) {
    @content;
  }
}
@mixin from($from) {
  @media (min-width: $from) {
    @content;
  }
}
@mixin range($from, $to) {
  @media (min-width: $from) and (max-width: $to) {
    @content;
  }
}

// smallHeight
@mixin smallVH {
  @media (max-height: 800px) {
    @content;
  }
}