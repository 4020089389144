@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.sentence {
	display: block;
	position: relative;
	margin-top: 48px;
	margin-bottom: 48px;
	cursor: default;

	@include to($to-sm) {
		margin-top: 20px;
		margin-bottom: 20px;
	}

	&:first-child {
		margin-top: 0;
	}
	&:last-child {
		margin-bottom: 0;
	}

	&:before {
		display: inline-block;
		vertical-align: .2em;
		width: 80px;
		height: 6px;
		margin-right: 22px;
		background: var(--accent);
		content: '';

		@include to($to-sm) {
			width: 40px;
			height: 3px;
			margin-right: 12px;
		}
	}
}
