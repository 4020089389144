@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.heading {
	position: relative;
}

.feed {
	transition:
		opacity var(--medium),
		transform var(--medium);
}

.feed.isTransition {
	opacity: 0;
	transform: translateY(10%);
}

/* * * Nav * * */
.navPanel {
	display: flex;
	flex-flow: row wrap;
	margin: 0 0 -32px 0;
	font: 300 var(--fs-24) var(--font);
	color: var(--gray);

	@include to($to-md) {
		font: 300 var(--fs-18) var(--font);
	}
	@include to($to-sm) {
		margin: 0 0 -20px 0;
		font: 300 var(--fs-12) var(--font);
	}

	:global(.is-white-section) & {
		color: var(--gray);
	}
	:global(.is-dark-section) & {
		color: var(--gray);
	}
	:global(.is-transparent-section) & {
		color: var(--gray);
	}
}

button.navBtn {
	margin: 0 32px 32px 0;
	text-decoration: none;
	color: inherit;

	@include to($to-sm) {
		margin: 0 20px 20px 0;
	}

	&:hover {
		color: var(--white);
	}
}

:global(.is-white-section) {
	button.navBtn:hover {
		color: var(--accent);
	}
	button.navBtn.isCurrent {
		color: var(--accent);
	}
}
:global(.is-dark-section) {
	button.navBtn:hover {
		color: var(--accent);
	}
	button.navBtn.isCurrent {
		color: var(--white);
	}
}
:global(.is-transparent-section) {
	button.navBtn:hover {
		color: var(--accent);
	}
	button.navBtn.isCurrent {
		color: var(--white);
	}
}

/* * * Animation * * */
// .isVisible {}