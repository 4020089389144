@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.formElement {
  position: relative;

  .formElement ~ .formElement {
    margin-top: 16px;

    @include to($to-sm) {
      margin-top: 8px;
    }
  }
}

.isError {}
.isLoading {}
