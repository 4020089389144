@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.module {
	position: relative;
	z-index: 2;

	@include to($to-md) {}
	@include to($to-sm) {}
}

.heading {
	position: relative;
}

.layout {
	position: relative;
	min-height: 100vh;

	&:before {
		position: absolute;
		left: -1px;
		top: 0;
		bottom: 0;
		width: 2px;
		background: var(--stroke-light);
		pointer-events: none;
		content: '';
	}
}

:global(.is-dark-section) .layout:before {
	background: var(--stroke-dark);
}

.layoutContent {
	width: 62.5%;
	padding-left: 5.875vw;
	margin-right: 12.5%;
	box-sizing: border-box;

	@include to($to-lg) {
		width: 60%;
		margin-right: 10%;
	}
	@include to($to-sm) {
		width: 100%;
		padding-left: 10vw;
		margin-right: 0;
	}
}

.layoutAside {
	width: 25%;

	@include to($to-lg) {
		width: 30%;
	}
	@include to($to-sm) {
		display: none;
	}
}

.layoutAsideSticky {
	position: sticky;
	top: calc(50vh);
	margin: calc(8.25vw + 144px) 0;
	height: 0;

	@include to($to-lg) {
		margin: calc(8.25vw + 112px) 0;
	}
	@include to($to-md) {
		margin: calc(8.25vw + 72px) 0;
	}
	@include to($to-sm) {
		margin: calc(8.25vw + 56px) 0;
	}
}

.layoutAsideMedia {
	display: block;
	position: relative;
	top: -8.25vw;
	width: 26.25vw;
	height: 16.5vw;
	background: var(--anthracite);
	pointer-events: none;
	will-change: transform;
	transform:
		translateX(calc(10vw * var(--mx)))
		translateY(calc(10vh * var(--my)))
		translateZ(0);

	&:before {
		position: absolute;
		z-index: 5;
		left: -1px;
		top: -1px;
		right: -1px;
		bottom: -1px;
		border: 2px solid var(--white);
		content: '';
	}

	picture {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
	}

	span, img {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		backface-visibility: hidden;
	}
}

:global(.is-dark-section) .layoutAsideMedia:before {
	border-color: var(--dark);
}

/* * * Progress * * */
.progress {
	position: absolute;
	left: -3px;
	top: 0;
	width: 6px;
	height: 0;
	background: var(--accent);
	pointer-events: none;
	content: '';

	@include to($to-md) {
		left: -2px;
		width: 4px;
	}
	@include to($to-sm) {
		left: -1px;
		width: 2px;
	}
}

/* * * Section * * */
.section {
	display: block;
	position: relative;
	padding-bottom: 232px;

	@include to($to-lg) {
		padding-bottom: 144px;
	}
	@include to($to-md) {
		padding-bottom: 128px;
	}
	@include to($to-sm) {
		padding-bottom: 64px;
	}

	&:first-child {
		padding-top: 144px;

		@include to($to-lg) {
			padding-top: 112px;
		}
		@include to($to-md) {
			padding-top: 72px;
		}
		@include to($to-sm) {
			padding-top: 56px;
		}
	}

	&:last-child {
		padding-bottom: 144px;

		@include to($to-lg) {
			padding-bottom: 112px;
		}
		@include to($to-md) {
			padding-bottom: 72px;
		}
		@include to($to-sm) {
			padding-bottom: 56px;
		}
	}
}

.item {
	display: block;
	position: relative;
	margin-top: 44px;
	padding-right: 6.25vw;
	color: inherit;
	text-decoration: none;

	@include to($to-md) {
		margin-top: 32px;
	}
	@include to($to-sm) {
		margin-top: 24px;
	}

	&:first-child {
		margin-top: 0;
	}
}

.arrow {
	position: absolute;
	top: .9em;
	right: 0;
	width: 32px;
	height: 32px;
	fill: var(--stroke-light);
	transition: fill var(--fast);

	@include to($to-md) {
		width: 24px;
		height: 24px;
	}
	@include to($to-sm) {
		top: 4px;
		width: 16px;
		height: 16px;
	}
}

:global(.is-dark-section) .arrow {
	fill: var(--stroke-dark);
}

.caption {
	padding-bottom: 32px;
	margin-bottom: 8px;
	border-bottom: 1px solid var(--stroke-light);
	transition: color var(--fast);

	@include to($to-md) {}
	@include to($to-sm) {
		padding-bottom: 16px;
	}
}

:global(.is-dark-section) .caption {
	border-bottom-color: var(--stroke-dark);
}

.text {
	transition: color var(--fast);
}

.events {
	margin-top: 84px;

	@include to($to-md) {
		margin-top: 48px;
	}
	@include to($to-sm) {
		margin-top: 32px;
	}

	&:first-child {
		margin-top: 0;
	}
}

.events .arrow {
	top: .6em;

	@include to($to-sm) {
		top: .4em;
	}
}

.picture {
	display: block;
	margin-top: 24px;

	span, img {
		display: block;
		width: 100%;
		height: auto;
	}
}

/* * * Hover * * */
a.item:hover .arrow {
	fill: var(--text);
}
a.item:hover .text {
	color: var(--text);
}

:global(.is-dark-section) a.item:hover .arrow {
	fill: var(--white);
}
:global(.is-dark-section) a.item:hover .text {
	color: var(--white);
}

/* * * Bullets * * */
.item {
	&:before {
		position: absolute;
		z-index: 1;
		left: -5.875vw;
		top: .625em;
		margin-left: -.5em;
		width: 1em;
		height: 1em;
		font-size: 24px;
		background: var(--white);
		box-shadow: 0 0 0 .625em var(--white);
		border: 2px solid var(--stroke-light);
		transition:
			background var(--fast),
			border-color var(--fast);
		box-sizing: border-box;
		pointer-events: none;
		content: '';

		@include to($to-md) {
			top: 10px;
			font-size: 18px;
			box-shadow: 0 0 0 10px var(--white);
		}
		@include to($to-sm) {
			top: 2px;
			left: -10vw;
		}
	}
}

:global(.is-dark-section) .item:before {
	background: var(--dark);
	box-shadow: 0 0 0 .625em var(--dark);
	border-color: var(--stroke-dark);

	@include to($to-md) {
		box-shadow: 0 0 0 10px var(--dark);
	}
	@include to($to-sm) {}
}

.events .item {
	&:before {
		top: .5em;
		font-size: 16px;
		box-shadow: 0 0 0 .5em var(--white);

		@include to($to-md) {
			top: 8px;
			font-size: 12px;
			box-shadow: 0 0 0 8px var(--white);
		}
		@include to($to-sm) {
			top: 2px;
		}
	}
}

:global(.is-dark-section) .events .item:before {
	box-shadow: 0 0 0 .5em var(--dark);

	@include to($to-md) {
		box-shadow: 0 0 0 8px var(--dark);
	}
	@include to($to-sm) {}
}

/* * * Highlight * * */
.item.highlight {
	&:before {
		background: var(--accent);
		border-color: var(--accent);
	}
}
.item.highlight .caption {
	color: var(--accent);
}
