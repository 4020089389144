@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.sm {
  font: 300 var(--fs-16) var(--font);

  @include from($from-hd) {
    font: 300 1vw/1.5 var(--font);

    @supports (font-size: var(--vw)) {
      font: 400 calc(var(--vw) * .01)/1.5 var(--font);
    }
  }

  @include to($to-md) {
    font: 300 var(--fs-12) var(--font);
  }
  @include to($to-sm) {
    font: 300 var(--fs-12) var(--font);
  }
}

.md {
  font: 300 var(--fs-24) var(--font);

  @include from($from-hd) {
    font: 300 1.5vw/1.332 var(--font);

    @supports (font-size: var(--vw)) {
      font: 400 calc(var(--vw) * .015)/1.332 var(--font);
    }
  }

  @include to($to-md) {
    font: 300 var(--fs-16) var(--font);
  }
  @include to($to-sm) {
    font: 300 var(--fs-12) var(--font);
  }
}

.lg {}

.dark {
  color: var(--dark);
}

.gray {
  color: var(--gray);
}

.white {
  color: var(--white);
}

.inherit {
  color: inherit;
}