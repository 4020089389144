@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.module {
	position: relative;
}

.head {
	margin-bottom: 104px;

	@include to($to-lg) {
		margin-bottom: 72px;
	}
	@include to($to-md) {
		margin-bottom: 32px;
	}
	@include to($to-sm) {
		margin-bottom: 40px;
	}
}

.headWrap {
	@include to($to-sm) {
		max-width: 320px;
	}
}

.project {
	display: block;
	position: relative;
	flex-shrink: 0;
	color: var(--gray);
	background: var(--dark);
	box-sizing: border-box;
	overflow: hidden;
	transition: height var(--trans);
	cursor: none;

	@include to($to-sm) {
		cursor: pointer;
	}

	&:before {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		border: 2px solid var(--stroke-card);
		pointer-events: none;
		content: '';

		@include to($to-sm) {
			border-width: 1px;
		}
	}
}

.project .title {
	@include from($from-hd) {
		font: 400 38px/1.263 var(--font);
	}

	@include to($to-md) {
		font: 400 24px/1.2 var(--font);
	}
	@include to($to-sm) {
		font: 400 20px/1.2 var(--font);
	}
}

.project .arrow {
	position: absolute;
	top: 32px;
	right: 32px;
	width: 32px;
	height: 32px;
	fill: currentColor;

	@include to($to-sm) {
		top: 16px;
		right: 16px;
		width: 16px;
		height: 16px;
	}
}

.project .content {
	position: relative;
	z-index: 2;
	min-height: 100%;
	padding: 32px 10vw 32px 32px;
	box-sizing: border-box;

	@include to($to-sm) {
		padding: 16px 50px 16px 16px;
	}
}

.project .pic {
	position: absolute;
	z-index: 1;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	min-height: 460px;
	pointer-events: none;
	opacity: 0;
	transition: opacity var(--trans);

	@include to($to-sm) {
		min-height: 260px;
	}

	&:before {
		position: absolute;
		z-index: 1;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.42), rgba(0, 0, 0, 0.42));
		content: '';
	}

	img {
		object-fit: cover;
	}
}

:global(.is-mouse) {
	.project:focus-visible {
		outline: none;
		box-shadow: none;
	}

	.project:hover,
	.project:focus-visible {
		color: var(--white);
	}
	.project:hover .pic,
	.project:focus-visible .pic {
		opacity: 1;
	}
}

:global(.is-touch .splide__slide.is-active) {
	.project {
		color: var(--white);
	}
	.project .pic {
		opacity: 1;
	}
}
