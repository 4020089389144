@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.heading {
	position: relative;
}

.feed {
	transition:
		opacity var(--medium),
		transform var(--medium);
}

.feed.isLoading {
	opacity: 0;
	transform: translateY(10%);
}

.paging {
	margin-top: 144px;

	@include to($to-lg) {
		margin-top: 112px;
	}
	@include to($to-md) {
		margin-top: 72px;
	}
	@include to($to-sm) {
		margin-top: 56px;
	}
}

/* * * Animation * * */
.card {
	opacity: 0;
	transform: translateY(75%);
	transition:
		opacity var(--slow),
		transform var(--slow);
}

.card {
	@for $i from 1 through 10 {
		&:nth-child(#{$i}) {
			transition-delay: #{.4 * $i}s;
		}
	}
}

.visible .card {
	opacity: 1;
	transform: none;
}