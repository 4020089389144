@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.module {
	display: block;
	position: relative;
	color: var(--white);
	background: var(--dark);
	box-sizing: border-box;

	&:before {
		display: block;
		height: 0;
		padding-bottom: 107.4074074074074%;
		content: '';

		@include to($to-sm) {
			padding-bottom: 83.9506172839506%;
		}
	}

	&:after {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		border: 2px solid var(--stroke-card);
		box-sizing: border-box;
		content: '';

		@include to($to-sm) {
			border-width: 1px;
		}
	}
}

.content {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: space-between;
	position: absolute;
	z-index: 2;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	padding: 1.25vw;
	font: 300 var(--fs-16) var(--font);
	text-align: center;
	box-sizing: border-box;
	color: var(--white);
	transition:
		color var(--fast),
		background var(--fast);
	overflow: hidden;

	@include to($to-sm) {
		padding: 20px;
		font: 400 var(--fs-12) var(--font);
	}

	svg {
		display: block;
		fill: currentColor;
	}
}

.logo {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	left: 2.5vw;
	top: 2.5vw;
	right: 2.5vw;
	bottom: 2.5vw;
	pointer-events: none;

	@include to($to-sm) {
		left: 20px;
		top: 20px;
		right: 20px;
		bottom: 20px;
	}
}

.title {
	opacity: 0;
	transform: translateY(100%);
	pointer-events: none;
	transition:
		opacity var(--fast),
		transform var(--fast);
}

.docType {
	opacity: 0;
	transform: translateY(-100%);
	pointer-events: none;
	transition:
		opacity var(--fast),
		transform var(--fast);
}

:global(.is-mouse) {
	.module:hover {
		z-index: 2;
	}
	.module:hover .content {
		color: var(--white);
		background: var(--accent);
	}
	.module:hover .title,
	.module:hover .docType {
		opacity: 1;
		transform: none;
	}
}

:global(.is-touch) {
	.clicked:hover {
		z-index: 2;
	}
	.clicked .content {
		color: var(--white);
		background: var(--accent);
	}
	.clicked .title,
	.clicked .docType {
		opacity: 1;
		transform: none;
	}
}