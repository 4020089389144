@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.component {
	position: fixed;
	z-index: 50;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 32px 0;
	color: var(--gray);
	background: var(--dark);
	border-top: 2px solid var(--anthracite);
	overflow: hidden;

	@include to($to-md) {
		padding: 24px 0;
	}
	@include to($to-sm) {
		padding: 20px 0;
		border-top-width: 1px;
	}

	opacity: 0;
	pointer-events: none;
	transform: translateY(101%);
	transition:
		opacity var(--trans),
		transform var(--trans);

	&.hasTransition {
		transition:
			opacity var(--trans),
			transform var(--trans);
	}

	&.isShown {
		opacity: 1;
		pointer-events: auto;
		transform: translateY(0);
		transition-delay: 100ms;
	}
}

.container {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	justify-content: space-between;

	@include to($to-md) {
		flex-flow: column nowrap;
		align-items: flex-start;
	}

	.right {
		margin-left: 30px;

		@include to($to-md) {
			margin-left: 0;
			margin-top: 15px;
		}
	}
}

.title {
	margin-bottom: 8px;
	font: 400 var(--fs-18) var(--font);
	color: var(--white);

	@include to($to-sm) {
		margin-bottom: 6px;
		font: 400 var(--fs-14) var(--font);
	}
}

.text {
	font: 400 16px/1.375 var(--font);

	@include to($to-sm) {
		margin-right: 34px;
		font: 400 8px/1.375 var(--font);
	}

	a {
		color: var(--white);

		&:hover {
			color: var(--accent);
		}
	}
}

.acceptBtn {
	font: 400 22px/1.16 var(--font);

	@include to($to-sm) {
		font: 400 14px/1.22 var(--font);
	}
}

.declineBtn {
	position: absolute;
	top: 4px;
	right: 8px;
	width: 44px;
	height: 44px;
	color: var(--stroke-dark);

	@include to($to-sm) {
		top: 0;
		right: -2px;
	}

	&:hover {
		color: var(--accent);
	}

	svg {
		display: block;
		width: 21px;
		height: 21px;
		fill: currentColor;

		@include to($to-md) {
			width: 14px;
			height: 14px;
		}
	}
}