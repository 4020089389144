@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.item {
	margin-top: 104px;
	padding-top: 16px;
	border-top: 1px solid var(--stroke-dark);

	@include to($to-lg) {
		margin-top: 72px;
	}
	@include to($to-md) {
		margin-top: 48px;
	}
	@include to($to-sm) {
		display: block;
		margin-top: 32px;
		padding-top: 8px;
		border-top-color: var(--stroke-light);
	}
}

:global(.is-white-section) .item {
	border-top-color: var(--stroke-light);
}

:global(.is-dark-section) .item {
	border-top-color: var(--stroke-dark);
}

.itemNum {
	width: 28.571428571428571%; // or 20vw?
	color: var(--accent);

	@include to($to-sm) {
		margin-bottom: 18px;
		font: 400 20px/1.22 var(--font);
	}
}

.itemContent {
	width: 71.428571428571429%; // or 80vw?

	@include to($to-sm) {
		width: 100%;
	}
}

.itemTitle {
	margin-bottom: 48px;

	@include to($to-sm) {
		margin-bottom: 24px;
	}
}

.itemText {
	font: 300 var(--fs-24) var(--font);
	color: var(--gray);

	@include to($to-md) {
		font: 300 var(--fs-16) var(--font);
	}
	@include to($to-sm) {
		font: 300 var(--fs-12) var(--font);
	}
}

/* * * Animation * * */
.item {
	opacity: 0;
	transform: translateY(50%) translateZ(0);
	transition:
		opacity var(--slow),
		transform var(--slow);

	@for $i from 1 through 10 {
		&:nth-child(#{$i}) {
			transition-delay: #{.4 * $i}s;
		}
	}
}

:global(.is-visible) {
	.item {
		opacity: 1;
		transform: none;
	}
}
