@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.item {
	display: block;
	position: relative;
	margin-top: 88px;
	padding-top: 16px;
	text-decoration: none;
	color: inherit;
	border-top: 1px solid var(--stroke-light);
	transition: color var(--fast);

	@include to($to-md) {
		margin-top: 48px;
	}
	@include to($to-sm) {
		margin-top: 24px;
	}

	&:first-child {
		margin-top: 0;
	}

	&:before {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 100%;
		height: 6px;
		background: transparent;
		transform-origin: 50% 100%;
		transform: scaleY(0);
		transition:
			background var(--trans),
			transform var(--trans);
		content: '';
	}

	:root {
		--mx: 0;
		--my: 0;
	}
}

:global(.is-dark-section) .item {
	border-top-color: var(--stroke-dark);
}

.caption {
	position: relative;
	color: inherit;
}

.text {
	display: block;
	margin-top: 48px;
	color: var(--gray);

	@include to($to-sm) {
		margin-top: 16px;
	}
}

.picture {
	display: block;
	width: 100%;
	max-width: 180px;

	span,
	img {
		display: block !important;
	}

	@include from($from-sm) {
		position: absolute;
		z-index: 40;
		right: 0;
		bottom: -76px;
		height: auto;
		width: 15vw;
		min-width: 200px;

		/* * * For hover * * */
		opacity: 0;
		pointer-events: none;
		transition:
			opacity var(--medium);

		/* * * For mouse move * * */
		transform:
			translateX(calc(var(--mx) * -1px))
			translateY(calc(var(--my) * -1px))
			translateZ(0);
	}
	@include from($from-md) {
		min-width: 240px;
	}

	@include range($from-sm, $to-md) {
		bottom: -36px;
	}
}

.grid {
	&__left {
		width: 70%;

		@include to($to-sm) {
			width: 60%;
		}
	}

	&__right {
		display: flex;
		justify-content: flex-end;
		position: relative;
		width: 30%;

		@include to($to-sm) {
			width: 40%;
		}
	}
}

/* * * Item hover * * */
.item:hover {
	color: var(--accent);

	.picture {
		opacity: 1;
		pointer-events: auto;
	}
}

.item:hover:before {
	transform: none;
	background-color: var(--accent);
}

/* * * Animation * * */
.item {
	opacity: 0;
	transform: translateY(50%);
	transition:
		color var(--fast),
		opacity var(--slow),
		transform var(--slow);
}

.item.isVisible {
	opacity: 1;
	transform: none;
}
