@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.button {
	position: absolute;
	z-index: 10;
	top: .832em;
	right: .832em;
	width: 1em;
	height: 1em;
	padding: 0;
	font-size: 48px;
	color: transparent;
	background: none;
	border: none;
	cursor: pointer;

	svg {
		display: block;
		width: 1em;
		height: 1em;
		fill: var(--stroke-light);
		transition: fill var(--medium);
	}

	&:hover {
		svg {
			fill: var(--accent);
		}
	}

	@include to($to-lg) {
		top: .5em;
		right: .5em;
		width: 1em;
		height: 1em;
	}
	@include to($to-md) {
		font-size: 32px;
	}
	@include to($to-sm) {
		font-size: 24px;
	}
}
