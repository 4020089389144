@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.module {
	font: 400 36.8888888888889vh/1.084 var(--font);

	//@supports (font-size: var(--vh)) {
	//	font: 400 calc(var(--vh) * .368888888888889)/1.084 var(--font);
	//}

	@include from($from-md) {
		@media (min-height: 700px) {
			font-size: 332px;
			line-height: 1.084;
		}
	}
	@include to($to-md) {
		font: 400 32vh/1.084 var(--font);

		//@supports (font-size: var(--vh)) {
		//	font: 400 calc(var(--vh) * .32)/1.084 var(--font);
		//}

		@media (min-height: 900px) {
			font-size: 300px;
			line-height: 1.084;
		}
	}
	@include to($to-sm) {
		font-size: 92px;
		line-height: 1;
	}
}

.track {
	display: inline-flex;
	flex-flow: row nowrap;
	position: relative;
	user-select: none;
	pointer-events: none;
}

.trackPart {
	display: inline-flex;
	flex-flow: row nowrap;
	margin: 0 30vw;
}
.trackPart ~ .trackPart {
	position: absolute;
	left: 100%;
	top: 0;
}

.word {
	display: inline-flex;
	flex-flow: row nowrap;
}

.letter {
	display: block;
	position: relative;
	white-space: pre;

	div {
		display: flex;
		align-items: flex-end;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		height: 90%;
		color: var(--accent);
		background: var(--white);
		// overflow: hidden;
	}

	&:global(.anchor-uppercase) div {
		height: 90%;
	}

	&:before {
		display: block;
		position: relative;
		color: var(--stroke-light);
		background: var(--white);
		-webkit-text-stroke: 1px currentColor;
		-webkit-text-fill-color: transparent;
		content: attr(data-char);
	}
}

.svgClipPath {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
	content-visibility: auto;

	text {
		font: 400 36.8888888888889vh/1.084 var(--font);

		@include from($from-md) {
			@media (min-height: 900px) {
				font-size: 332px;
			}
		}
		@include to($to-md) {
			font: 400 32vh/1.084 var(--font);

			@media (min-height: 900px) {
				font-size: 300px;
			}
		}
		@include to($to-sm) {
			font-size: 92px;
			line-height: 1;
		}

		pointer-events: auto;
	}
}