@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.title {
	margin-bottom: 88px;

	@include to($to-md) {
		margin-bottom: 48px;
	}
	@include to($to-sm) {
		margin-bottom: 20px;
	}
}

.subtitle {
	margin-bottom: 96px;

	@include to($to-md) {
		margin-bottom: 48px;
	}
	@include to($to-sm) {
		margin-bottom: 20px;
	}
}

.sentence {
	margin-bottom: 48px;

	@include to($to-md) {
		margin-bottom: 48px;
	}
	@include to($to-sm) {
		margin-bottom: 20px;
	}
}

.textBox {
	margin-left: 50%;
	margin-top: 48px;

	@include to($to-hd) {
		// margin-left: 40%;
	}
	@include to($to-md) {
		margin-top: 48px;
	}
	@include to($to-sm) {
		margin-top: 20px;
		margin-left: 0;
	}

	&:first-child {
		margin-top: 0;
	}
}

/* * * Columns * * */
.columns {
	position: relative;
	flex-flow: row wrap;

	@include to($to-sm) {}
}

.left {
	width: 50%;

	//@include to($to-hd) {
	//	width: 40%;
	//}
	@include to($to-sm) {
		order: 2;
		width: 100%;
	}
}

.right {
	width: 50%;

	//@include to($to-hd) {
	//	width: 60%;
	//}
	@include to($to-sm) {
		order: 1;
		width: 100%;
	}
}

.columns .textBox {
	margin-left: 0;
}