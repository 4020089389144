.wysiwyg p,
.wysiwyg ul,
.wysiwyg ol,
.wysiwyg hr,
.wysiwyg table {
  margin-top: $textOffset;
  margin-bottom: $textOffset;
}

.wysiwyg img {
  max-width: 100%;
  height: auto;

  &.lazy {
    opacity: 0;
  }
  &[data-url],
  &[data-href] {
    cursor: pointer;
  }
}

.wysiwyg hr {
  display: block;
  margin-top: $textOffset;
  margin-bottom: $textOffset;
  max-width: 100%;
  height: 0;
  background: none;
  border: none;
  border-bottom: 1px solid var(--anthracite);
}

.wysiwyg small {
  font-size: .875em;
}
.wysiwyg strong {
  font-weight: 500;
}

.wysiwyg a:not(.btn) {
  font-weight: 400;
  text-decoration: none;
  color: var(--accent);
  border-bottom: 1px solid transparent;

  &:hover {
    border-color: var(--accent);
  }

  &[href^="tel:"] {
    text-decoration: none;
    white-space: nowrap;
    color: inherit;
    border-bottom: none;

    &:hover {
      color: var(--accent);
    }
  }

  svg {
    fill: currentColor;
  }
}

.wysiwyg td table {
  margin-top: 0;
  margin-bottom: 0;
}