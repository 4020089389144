@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.module {
	position: relative;
	min-height: 100vh;
	background: var(--dark);
}

.aside {
	position: relative;
	z-index: 1;
	width: 20%;
	padding: 10.5vw 20px 40px;
	box-sizing: border-box;
	color: var(--white);
}

.content {
	position: relative;
	z-index: 4;
	padding: 10.5vw 0 40px 30px;
	width: 80%;
	box-sizing: border-box;
	background: var(--white);
}

/* * * Aside * * */
.asideBg {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
	opacity: 0;

	&:before {
		position: absolute;
		z-index: 1;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
		content: '';
	}

	img {
		object-fit: cover;
	}
}

.asideInner {
	position: relative;
	z-index: 2;
}

.asideTitle {
	margin-bottom: 24px;
	text-align: center;

	@include to($to-lg) {
		font: 400 30px/1.26 $font;
	}
	@include to($to-md) {
		font: 400 20px/1.26 $font;
	}

	color-accent {
		color: inherit !important;
	}
}

.asideUrl {
	margin: 0 auto;
}

/* * * Previews * * */
.preview {
	opacity: 0;
	transform: translateY(75%);
	transition:
		opacity var(--slow),
		transform var(--slow);

	picture {
		clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
		transition: clip-path var(--slow);
	}

	@include from($from-sm) {
		@for $i from 1 through 10 {
			&:nth-child(#{$i}) {
				z-index: #{11 - $i};
				transition-delay: #{.6 * $i}s;

				picture {
					transition-delay: #{.6 * $i}s;
				}
			}
		}
	}
}

.visible .preview {
	opacity: 1;
	transform: none;

	picture {
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
	}
}

/* * * Visibility animation * * */
.asideUrl,
.asideTitle {
	opacity: 0;
	transform: translateY(50%);
	transition:
		opacity 1s var(--ease),
		transform 1.4s var(--ease);
}

.asideUrl {
	transition-delay: .4s;
}

.visible .asideUrl,
.visible .asideTitle {
	opacity: 1;
	transform: none;
}

.asideBg {
	opacity: 0;
	transition: opacity .8s ease;
}
.visibleBg .asideBg {
	opacity: 1;
	transition: opacity 1.6s ease;
}

/* * * Strips * * */
.strip {
	position: absolute;
	z-index: 3;
	left: 20%;
	right: 0;
	bottom: 0;
	height: 17.5vw;
	pointer-events: none;

	&:nth-child(2) {
		bottom: 17.5vw;
	}
	&:nth-child(1) {
		bottom: 35vw;
	}
}

:global(.is-dark-section) .strip {
	background: var(--dark);
}
:global(.is-white-section) .strip {
	background: var(--white);
}
