@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 10vw;
  height: 10vw;
  text-align: center;
  color: var(--white);
  background: var(--accent);
  transition: clip-path var(--trans);

  @include to($to-md) {
    font: 300 16px/1.5 var(--font);
  }
  @include to($to-sm) {
    width: 48px;
    height: 48px;
    font: 300 var(--fs-12) var(--font);
  }

  svg {
    display: block;
    width: 32px;
    max-width: 30%;
    height: auto;
    fill: currentColor;
    transition: transform var(--trans);
  }

  &:hover {
    svg {
      transform: translate(5px, -5px);
    }
  }
}
