@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.module {
	position: relative;
	z-index: 2;

	@include to($to-md) {}
	@include to($to-sm) {}
}

.sphere {
	position: absolute;
	z-index: -1;
	left: -5vw;
	top: 0;
	margin: 0;
	width: 26.125vw;
	height: 26.125vw;
	pointer-events: none;
	transform:
		translateX(calc(16vw * var(--mx)))
		translateY(calc(16vh * var(--my)))
		translateZ(0);

	div {
		width: 100%;
		height: 100%;
	}

	svg {
		display: block;
		width: 100%;
		height: 100%;
		fill: var(--accent);
		opacity: 0;
		transform: scale(.25);
		transition:
			opacity var(--trans),
			transform var(--trans);
	}

	@include to($to-sm) {
		position: relative;
		left: 40%;
		top: 12.5vh;
		margin-top: -20vw;
		width: 40vw;
		height: 40vw;
		transform: none;
	}
}

.visible .sphere {
	svg {
		opacity: 1;
		transform: none;
	}
}