@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';
@import '/src/core/CardsSlider/CardsSlider.module';

.module {
	position: relative;

	:global {
		.splide {
			@include from($from-sm) {
				cursor: none;
			}
		}

		.splide__slide {
			display: flex;
			flex-flow: column nowrap;
			justify-content: flex-end;
			width: 30vw;
			min-width: 400px;
			height: 100% !important;

			@include to($to-lg) {
				width: 40vw;
				min-width: 320px;
			}
			@include to($to-sm) {
				width: 80vw;
				min-width: 256px;
			}
		}
	}
}

.heading {
	margin-bottom: 104px;

	@include to($to-lg) {
		margin-bottom: 72px;
	}
	@include to($to-md) {
		margin-bottom: 32px;
	}
	@include to($to-sm) {
		margin-bottom: 16px;
	}
}

.preview {
	display: block;
	position: relative;
	flex-shrink: 0;
	color: var(--gray);
	background: var(--dark);
	box-sizing: border-box;
	overflow: hidden;
	height: 100%;
	transition: height var(--trans);

	&:before {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		border: 2px solid var(--stroke-card);
		pointer-events: none;
		content: '';

		@include to($to-sm) {
			border-width: 1px;
		}
	}
}

.preview.head {
	height: calc(100% - #{$cardShift});

	@include smallVH {
		height: calc(100% - #{$cardShift});
	}

	@include to($to-sm) {
		height: calc(100% - #{$cardShiftMob});
	}
}
.preview.childA {
	height: calc(100% - #{$cardShift} - 24px);

	@include smallVH {
		height: calc(100% - #{$cardShift} - 24px);
	}

	@include to($to-sm) {
		height: calc(100% - #{$cardShiftMob} - 24px);
	}
}
.preview.childB {
	height: calc(100% - #{$cardShift} - 48px);

	@include smallVH {
		height: calc(100% - #{$cardShift} - 48px);
	}

	@include to($to-sm) {
		height: calc(100% - #{$cardShiftMob} - 48px);
	}
}

.preview:hover,
.preview.clicked {
	z-index: 2;
	height: calc(100%);
}

/* * * Content * * */
.preview .content {
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-between;
	position: relative;
	z-index: 2;
	min-height: 100%;
	padding: 32px;
	box-sizing: border-box;
	transition: color var(--trans);

	@include to($to-sm) {
		padding: 16px;
	}
}

.preview.head .content {
	color: var(--white);
	background: var(--accent);
}

.preview.child .content {
	color: rgba(255, 255, 255, .4);
	background: transparent;
}

.preview.child:hover .content,
.preview.clicked .content {
	color: rgba(255, 255, 255, 1);
}

.preview .title {
	margin-bottom: 1.5em;
	font: 300 var(--fs-16) var(--font);

	@include from($from-hd) {
		font: 300 1vw/1.5 var(--font);
	}

	@include to($to-sm) {
		font: 300 var(--fs-14) var(--font);
	}
}

.preview .text {
	font: 400 30px/1.4 $font;
	letter-spacing: -0.02em;

	@include from($from-hd) {
		font: 400 1.875vw/1.4 var(--font);
	}

	@include to($to-md) {
		font: 400 24px/1.4 $font;
	}
	@include to($to-sm) {
		font: 400 18px/1.24 $font;
	}
}

:global(.splide__slide:focus-visible) .preview {
	z-index: 2;
	height: calc(100%);
}
:global(.splide__slide:focus-visible) .preview .content {
	color: rgba(255, 255, 255, 1);
}
