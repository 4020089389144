@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.outer {
	position: relative;
	margin-top: 0;
	padding-top: 14vh;
	padding-bottom: 15vh;

	@include to($to-lg) {
		margin: 0;
		padding: 0;
	}
	@include to($to-sm) {
		padding: 56px 0 60vw;
	}
}

.mobile {
	overflow: hidden;
}

.inner {
	position: relative;
	min-height: 300vh;
	content-visibility: auto;
}

.sticky {
	position: sticky;
	top: 0;
	padding: 6vh 0;
	height: 100vh;
	max-height: 100vh;
	box-sizing: border-box;
	overflow: hidden;
}

.content {
	display: flex;
	flex-flow: column;
	justify-content: space-between;
	min-height: 100%;

	@include to($to-lg) {
		justify-content: space-around;
	}
}

.title {
	@include from($from-lg) {
		@media (max-height: 800px) {
			font: 400 3.6vw/1.1875 var(--font);
		}
	}
}

.sentence {
	margin-bottom: 0;

	@include from($from-md) {
		font: 400 4.22vh/1.263 var(--font);
	}
	@include to($to-sm) {
		margin-bottom: 32px;
	}
}

.ticker {
	margin-left: 20vw;

	@include to($to-sm) {
		margin-top: 30px;
		// margin-left: -10vw;
	}
}

/* * * More text varaiant * * */
.moreText {
	@include from($from-lg) {
		@media (min-height: 900px) {
			padding-top: 16vh;
		}
	}

	@include to($to-sm) {
		padding: 56px 0 56vw;
	}
}

.moreText .sticky {
	@include from($from-lg) {
		@media (min-height: 900px) {
			padding: 4vh 0;
		}
	}
}

.moreText .content {
	@include from($from-lg) {
		justify-content: space-evenly;
	}
}

.moreText .sentence {
	@include from($from-lg) {
		margin-bottom: 13vh;
	}
}