@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.container {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  min-width: $minWidth;
  max-width: 100%; // $maxWidth;
  padding-left: $gap;
  padding-right: $gap;
  box-sizing: border-box;

  @include from($from-sm) {
    padding-left: $gapSM;
    padding-right: $gapSM;
  }
  @include from($from-md) {
    padding-left: $pad;
    padding-right: $pad;
  }
  @include from($from-lg) {
    padding-left: $padLG;
    padding-right: $padLG;
  }
  @include from($from-hd) {
    padding-left: $padHD;
    padding-right: $padHD;
  }
}

.containerWide {
  max-width: none;
}

.containerFull {
  padding-left: 0;
  padding-right: 0;
}