@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.cursor {
	display: block;
	position: absolute;
	z-index: 5;
	left: 50%;
	top: 50%;
	font-size: 10vw;
	width: 2em;
	height: 2.05em;
	margin: -1em 0 0 -1em;
	pointer-events: none;

	@include to($to-sm) {
		font-size: 80px;
	}

	.cursorText {
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		top: .05em;

		span {
			display: block;
			font: 400 .2375em/1.263157894736842 var(--font);
			text-align: center;
			white-space: nowrap;
		}
	}

	.cursorIcon {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		z-index: 5;
		left: 50%;
		bottom: .15em;
		margin: 0 0 0 -.5em;
		width: 1em;
		height: 1em;
		color: var(--white);
		background: var(--accent);
		overflow: hidden;

		//&:before {
		//	display: block;
		//	margin-left: .05em;
		//	border-style: solid;
		//	border-color: transparent transparent transparent currentColor;
		//	border-width: .14375em 0 .14375em .2875em;
		//	content: '';
		//}

		svg {
			display: block;
			width: .65em;
			height: .65em;
			fill: currentColor;

			path {
				transition: d var(--trans);
			}
		}
	}

	.cursorSpinner {
		font-size: .24em;
	}
}