@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.section {
	position: relative;
	z-index: auto;
	min-height: 100vh;
}

.track {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	pointer-events: none;

	margin-top: -60vh;

	@include to($to-lg) {
		margin-top: -70vh;
	}
	@include to($to-sm) {
		margin-top: -30vh;
	}
}

.sticky {
	position: sticky;
	top: 0;
	padding: 0;
	height: 100vh;
	box-sizing: border-box;
	overflow: hidden;
	clip-path: inset(0 0 0 0);
}

/*
 * Фреймы
 */
.twinFrames {
	position: absolute;
	top: 60%;
	left: calc(22% + calc(var(--stepA) * 33%));
	// pointer-events: none;

	@include to($to-lg) {
		top: 70%;
	}
	@include to($to-sm) {
		top: 50%;
		left: 50%; // calc(40% + calc(var(--stepA) * 10%));
	}
}

.twinFramesRect {
	position: absolute;
	left: calc(-9vw - calc(36vw * var(--stepB)) - calc(10vw * var(--stepC)));
	top: calc(-4.5vh - calc(25.5vh * var(--stepB)) - calc(30.5vh * var(--stepC)));
	width: calc(18vw + calc(62vw * var(--stepB)) + calc(20vw * var(--stepC)));
	height: calc(9vh + calc(51vh * var(--stepB)) + calc(41vh * var(--stepC)));
	clip-path: inset(0 0 0 0);

	@include to($to-hd) {
		top: calc(-9.5vh - calc(20.5vh * var(--stepB)) - calc(30.5vh * var(--stepC)));
	}
	@include to($to-lg) {
		top: calc(-19.5vh - calc(10.5vh * var(--stepB)) - calc(40.5vh * var(--stepC)));
		height: calc(9vh + calc(41vh * var(--stepB)) + calc(51vh * var(--stepC)));
	}
	@include to($to-md) {
		top: calc(-13.5vh - calc(16.5vh * var(--stepB)) - calc(40.5vh * var(--stepC)));
		width: calc(22vw + calc(58vw * var(--stepB)) + calc(20vw * var(--stepC)));
		height: calc(6vh + calc(44vh * var(--stepB)) + calc(51vh * var(--stepC)));
	}
	@include to($to-sm) {
		top: calc(calc(-10vh * var(--stepB)) - calc(40.5vh * var(--stepC)));

		left: calc(-20vw - calc(20vw * var(--stepB)) - calc(10vw * var(--stepC)));
		width: calc(40vw + calc(40vw * var(--stepB)) + calc(21vw * var(--stepC)));
	}
}

.twinFramesBase {
	background: none;
	clip-path: none;

	&:before {
		position: absolute;
		z-index: 1;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background: var(--accent);
		content: '';
	}
}

.twinFramesTop {
	z-index: 50;
}

.twinFramesStroke {
	position: absolute;
	box-sizing: border-box;
	pointer-events: none;
	border: 1px solid var(--stroke-light);

	@include to($to-sm) {
		opacity: .5;
	}
}

.twinFramesStrokeRight {
	left: 0;
	bottom: 0;
	width: calc(29.5vw + calc(90.5vw * var(--stepB)));
	height: calc(34.6vh + calc(85.4vh * var(--stepB)));

	@include to($to-hd) {
		height: calc(30.6vh + calc(89.4vh * var(--stepB)));
	}
	@include to($to-lg) {
		height: calc(26.6vh + calc(93.4vh * var(--stepB)));
	}
	@include to($to-md) {
		height: calc(16.6vh + calc(93.4vh * var(--stepB)));
	}
	@include to($to-sm) {
		width: calc(60vw + calc(40vw * var(--stepB)));
		height: calc(20vh + calc(80vh * var(--stepB)));
	}
}

.twinFramesStrokeLeft {
	right: 0;
	top: 0;
	width: calc(27.5vw + calc(91.5vw * var(--stepB)));
	height: calc(28.4vh + calc(91.6vh * var(--stepB)));

	@include to($to-hd) {
		height: calc(24.4vh + calc(87.6vh * var(--stepB)));
	}
	@include to($to-lg) {
		height: calc(20.4vh + calc(83.6vh * var(--stepB)));
	}
	@include to($to-md) {
		height: calc(14.4vh + calc(83.6vh * var(--stepB)));
	}
	@include to($to-sm) {
		width: calc(55vw + calc(45vw * var(--stepB)));
	}
}

/*
 * Медиа-контент
 */
.mediaContent {
	position: fixed;
	z-index: 50;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	background: var(--dark);
	pointer-events: auto;

	img {
		object-fit: cover;
		backface-visibility: hidden;
	}
}

.mediaContentVideo {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;

	video {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;

		@include to($to-sm) {
			object-position: 70% center;
		}
	}

	&:before {
		position: absolute;
		z-index: 1;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background: linear-gradient(0deg, rgba(17, 17, 17, 0.2), rgba(17, 17, 17, 0.2));
		opacity: var(--overlay);
		content: '';
	}
}


