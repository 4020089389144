@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.heading {
	margin-bottom: 88px;
	padding-top: 16px;
	color: var(--text);
	border-top: 1px solid var(--stroke-light);

	@include to($to-sm) {
		margin-bottom: 32px;
		padding-top: 0;
		border-top: none;
	}
}

.title {
	margin-bottom: 20px;

	@include to($to-sm) {}

	a {
		display: block;
		position: relative;
		padding-right: 32px;
		text-decoration: none !important;
		color: inherit !important;
		border-bottom: none !important;

		svg {
			fill: var(--stroke-light);
			transition: fill var(--trans);
		}

		&:focus-visible {
			outline: none;
			box-shadow: none;
		}

		&:hover,
		&:focus-visible {
			color: var(--accent) !important;

			svg {
				fill: var(--accent);
			}
		}
	}
}

.arrow {
	position: absolute;
	top: .25em;
	right: 0;
	width: 30px;
	height: 30px;
}

.date {
	display: block;
	color: var(--gray);
}

:global(.is-dark-section)  {
	.heading {
		color: var(--white);
	}
}