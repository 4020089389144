@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.module {
	position: relative;
	// padding-top: 56px;
}

.head {
	padding-bottom: 56px;
}

.content {
	position: relative;

	:global {
		.splide__slide {
			width: 100%;

			picture {
				&:after {
					position: absolute;
					z-index: 1;
					left: 0;
					bottom: 0;
					right: 0;
					height: 48px;
					background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.50) 100%);
					backface-visibility: hidden;
					content: '';
				}
			}
		}
	}

	.tip {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		padding-top: 12px;
		padding-bottom: 12px;
		font: 300 var(--fs-12) $font;
		letter-spacing: .04em;
		text-align: left;
		color: var(--white);
		pointer-events: none;
		animation: tipIn 1s forwards;
	}
	.tipHidden {
		animation: tipOut 1s forwards;
	}

	@keyframes tipIn {
		0% { opacity: 0; }
		100% { opacity: 1; }
	}
	@keyframes tipOut {
		0% { opacity: 1; }
		100% { opacity: 0; }
	}
}




