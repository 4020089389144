@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

@keyframes fadeIn {
	from { opacity: 0; }
	to { opacity: 1; }
}

@keyframes fadeOut {
	from { opacity: 1; }
	to { opacity: 0; }
}

.mapBox {
	position: relative;
	transition: opacity var(--slow);
}

.mapBoxIsLoading {
	opacity: .75;
	pointer-events: none;
}

.mapBoxInfo {
	position: absolute;
	z-index: 10;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 40px 0 58px;
	color: var(--gray);
	background: var(--dark);
	border-top: 2px solid var(--anthracite);
	pointer-events: none;
	animation: fadeOut 300ms var(--ease) forwards;
	cursor: default;

	@include to($to-sm) {
		padding: 32px 0;
	}
}

.mapBoxInfo.isShown {
	pointer-events: auto;
	animation: fadeIn 600ms var(--ease) forwards;
}

.headArea {
	width: 50%;
	padding-right: 30px;
	box-sizing: border-box;

	@include to($to-sm) {
		width: calc(100% - 32px);
		padding-right: 0;
		margin-bottom: 24px;
	}
}

.infoArea {
	// width: 37.5%;
	width: 42%;
	padding-right: 30px;
	box-sizing: border-box;

	@include to($to-sm) {
		width: 100%;
		padding-right: 0;
	}
}

.linkArea {
	display: flex;
	justify-content: flex-end;
	// width: 12.5%;
	width: 8%;

	@include to($to-sm) {
		position: absolute;
		right: 16px;
		top: 36px;
		width: 32px;
	}
}

/* * * Content * * */
.title {
	max-width: 480px;
	color: var(--white);
}

.text {
	max-width: 480px;
	color: inherit;
}

.address {
	margin-top: 40px;
	max-width: 480px;
	color: var(--white);

	@include to($to-sm) {
		margin-top: 24px;
	}
}

/* * * Links * * */
.link,
.linkOuter {
	display: block;
	position: relative;
	color: var(--stroke-light);
	text-decoration: none;

	@include to($to-sm) {
		&:before {
			position: absolute;
			left: -80%;
			top: -80%;
			right: -80%;
			bottom: -80%;
			content: '';
		}
	}

	svg {
		display: block;
		width: 32px;
		height: 32px;
		fill: currentColor;

		@include to($to-sm) {
			width: 16px;
			height: 16px;
		}
	}

	&:hover {
		color: var(--accent);
	}
}

