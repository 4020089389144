@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.outer {
	display: block;
	position: relative;
	margin-top: 110px;

	@include to($to-lg) {
		margin-top: 88px;
	}
	@include to($to-md) {
		margin-top: 56px;
	}
	@include to($to-sm) {
		margin-top: 42px;
	}

	&:first-child {
		margin-top: 0;
	}
}

.inner {
	display: flex;
	flex-flow: row wrap;
	position: relative;
	padding-top: 10px;
	text-decoration: none;
	color: inherit;
	border-top: 1px solid var(--stroke-light);
	transition: color var(--fast);

	@include to($to-sm) {
		padding-top: 6px;
	}

	&:before {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 100%;
		height: 6px;
		background: transparent;
		transform-origin: 50% 100%;
		transform: scaleY(0);
		transition:
			background var(--trans),
			transform var(--trans);
		content: '';
	}
}

:global(.is-dark-section) .inner {
	border-top-color: var(--stroke-dark);
}

.date {
	width: 12.5%;
	padding-right: 30px;
	padding-top: 5px;
	font: 300 var(--fs-16) var(--font);
	color: var(--gray);
	box-sizing: border-box;

	@include to($to-lg) {
		width: 15%;
	}
	@include to($to-md) {
		width: 20%;
		font: 300 var(--fs-14) var(--font);
	}
	@include to($to-sm) {
		width: 100%;
		margin-bottom: 12px;
		font: 300 var(--fs-12) var(--font);
	}
}

.main {
	width: 87.5%;

	@include to($to-lg) {
		width: 85%;
	}
	@include to($to-md) {
		width: 80%;
	}
	@include to($to-sm) {
		width: 100%;
	}
}

.caption {
	max-width: 50vw;
	margin-right: 48px;
}

.records {
	margin-top: 32px;
	margin-right: -30px;

	@include to($to-md) {
		margin-top: 24px;
	}
	@include to($to-sm) {
		margin-top: 16px;
		margin-bottom: -12px;
		margin-right: -15px;
	}
}

.record {
	width: 33.332%;
	padding-right: 30px;
	box-sizing: border-box;

	font: 300 var(--fs-24) var(--font);
	color: var(--text);

	@include to($to-md) {
		width: auto;
		font: 300 var(--fs-18) var(--font);
	}
	@include to($to-sm) {
		margin-bottom: 12px;
		padding-right: 15px;
		font: 300 var(--fs-14) var(--font);
	}
}

.recordCaption {
	margin-bottom: 4px;
	font: 300 var(--fs-16) var(--font);
	color: var(--gray);

	@include to($to-md) {
		font: 300 var(--fs-14) var(--font);
	}
	@include to($to-sm) {
		font: 300 var(--fs-12) var(--font);
	}
}

:global(.is-dark-section) .record {
	color: var(--white);
}


/* * * Arrow * * */
.arrow {
	position: absolute;
	top: 16px;
	right: 0;
	width: 32px;
	height: 32px;
	fill: transparent;
	transform: translate(-5px, 5px);
	transition:
		fill var(--fast),
		transform var(--fast);

	@include to($to-md) {
		width: 24px;
		height: 24px;
	}
	@include to($to-sm) {
		width: 16px;
		height: 16px;
		transform: none;

		fill: var(--stroke-light);
	}
}

:global(.is-dark-section) .arrow {
	@include to($to-sm) {
		fill: var(--stroke-dark);
	}
}

/* * * Item hover * * */
.link:hover,
.link:focus-visible {
	color: var(--accent);
	outline: none;
	box-shadow: none;
}

.link:hover:before,
.link:focus-visible:before {
	transform: none;
	background-color: var(--accent);
}

.link:hover .arrow,
.link:focus-visible .arrow {
	fill: var(--dark);
	transform: translate(0, 0);
}

:global(.is-dark-section) {
	.link:hover .arrow,
	.link:focus-visible .arrow {
		fill: var(--white);
	}
}

/* * * Animation * * */
.outer {
	opacity: 0;
	transform: translateY(50%);
	transition:
		color var(--fast),
		opacity var(--slow),
		transform var(--slow);
}

.outer.isVisible {
	opacity: 1;
	transform: none;
}
