/* * * Selection * * */
::selection {
  color: var(--dark);
  background: var(--white);
}

.is-white-section ::selection {
  color: var(--white);
  background: var(--accent);
}

/* * * Visibility * * */
.hidden {
  display: none;
  visibility: hidden;
  pointer-events: none;
}
.invisible {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
  appearance: none;
}

/* * * Alignment * * */
.aling-left {
  text-align: left;
  justify-content: flex-start;
}
.aling-center {
  text-align: center;
  justify-content: center;
}
.aling-right {
  text-align: right;
  justify-content: flex-end;
}
.aling-justify {
  text-align: justify;
  justify-content: space-between;
}

/* * * Text * * */
.nowrap-text {
  white-space: nowrap;
}
.underline-text {
  text-decoration: underline;
}
.uppercase-text {
  text-transform: uppercase;
}

/* * * Icons * * */
.inline-svg-icon {
  position: relative;
  top: .125em;
  flex-shrink: 0;
  height: 1em;
  width: 1em;
  fill: currentColor;
  transition: fill .4s;

  &--block {
    display: block;
    top: 0;
  }
}

/* * * Dimensions * * */
.vp-full {
  height: 100vh;

  @supports (height: var(--vh)) {
    height: var(--vh);
  }
}
.vp-full-min {
  min-height: 100vh;

  @supports (min-height: var(--vh)) {
    min-height: var(--vh);
  }
}
.vw-full {
  position: relative;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;

  @supports (width: var(--vw)) {
    width: var(--vw);
    margin-left: calc(var(--vw) / -2);
    margin-right: calc(var(--vw) / -2);
  }
}

/* * * Decor * * */
.text-stroke {
  -webkit-text-stroke: 1px currentColor;
  -webkit-text-fill-color: transparent;
}

/* * * Overflow * * */
.overflow-clip { overflow: hidden; }

/* * * Grecaptcha badge * * */
.grecaptcha-badge {
  opacity: 0;
}