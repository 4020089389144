@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.feed {
	margin: 0 -5vw -92px;

	@include to($to-md) {
		margin: 0 -5vw -48px;
	}
	@include to($to-sm) {
		margin: 0 0 -32px;
	}
}

.card {
	margin: 0 5vw 92px;
	width: calc(50% - 10vw);

	@include to($to-md) {
		margin: 0 5vw 48px;
	}
	@include to($to-sm) {
		margin: 0 0 32px;
		width: 100%;
	}
}

/* * * Animation * * */
.card,
.heading {
	opacity: 0;
	transform: translateY(75%) translateZ(0);
	transition:
		opacity var(--slow),
		transform var(--slow);
}

.card {
	@for $i from 1 through 10 {
		&:nth-child(#{$i}) {
			transition-delay: #{.4 * $i}s;
		}
	}
}

.visible .card,
.visible .heading {
	opacity: 1;
	transform: none;
}