@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.module {
	@include to($to-sm) {
		margin-bottom: -17.5vw;
	}
}

.card {
	position: relative;

	& ~ & {
		margin-top: 40px;
	}
}

/*
 * Колоризация картинок при скролле на мобильном
 */
.card {
	img {
		filter: grayscale(100%) !important;
		transition: filter 2s var(--ease) !important;
	}
}
.cardActive {
	img {
		filter: grayscale(0%) !important;
		transition: filter var(--slow);
	}
}