@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.quote {
	display: flex;
	flex-flow: row nowrap;
	font: 400 30px/1.4 var(--font);
	letter-spacing: -0.02em;
	color: var(--dark);
	cursor: default;

	@include from($from-hd) {
		font: 400 1.875vw/1.4 var(--font);
	}
	
	@include to($to-md) {
		font: 400 24px/1.4 var(--font);
	}
	@include to($to-sm) {
		flex-flow: column nowrap;
		font: 400 20px/1.24 var(--font);
	}
}

.picture {
	position: relative;
	flex-shrink: 0;
	width: 37.5%;
	margin-right: 12.5%;
	box-sizing: border-box;
	filter: grayscale(1);
	transition: filter var(--slow);

	span, img {
		display: block !important;
		width: 100% !important;
		height: auto !important;
	}

	@include to($to-sm) {
		width: calc(100% + 32px);
		margin: 0 -16px 32px;
	}
}

.content {
	position: relative;
	margin-top: .6em;
	width: 100%;

	:global {
		.text {
			text-indent: 112px;

			@include to($to-md) {
				text-indent: 90px;
			}
			@include to($to-sm) {
				text-indent: 56px;
			}
		}

		.author {
			margin-top: 88px;
			padding-top: 8px;
			border-top: 1px solid var(--stroke-light);

			@include to($to-md) {
				margin-top: 48px;
			}
			@include to($to-sm) {
				margin-top: 32px;
			}
		}

		.author__position {
			margin-top: 24px;
			font: 300 var(--fs-16) var(--font);
			color: var(--gray);

			@include from($from-hd) {
				font: 300 1vw/1.5 var(--font);
			}
			
			@include to($to-md) {
				margin-top: 12px;
				font: 300 var(--fs-14) var(--font);
			}
			@include to($to-sm) {
				margin-top: 8px;
				font: 300 var(--fs-12) var(--font);
			}
		}
	}
}

.qt {
	position: absolute;
	left: 0;
	top: -.25em;
	width: 1em;
	height: .702702702702703em;
	font-size: 74px;
	fill: var(--accent);

	@include to($to-md) {
		font-size: 60px;
	}
	@include to($to-sm) {
		font-size: 42px;
	}
}

.quote:hover .picture {
	filter: grayscale(0);
}

:global(.is-dark-section) .quote {
	color: var(--white);
}

:global(.is-dark-section) .content {
	.author {
		border-top-color: var(--stroke-dark);
	}
	.author__position {
		color: var(--gray);
	}
}
