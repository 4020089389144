@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.module {
	display: block;
	position: relative;
	color: var(--gray);
	background: var(--dark);
	box-sizing: border-box;

	&:before {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		border: 2px solid var(--stroke-card);
		box-sizing: border-box;
		content: '';

		@include to($to-sm) {
			border-width: 1px 0 0;
		}
	}
}

.canvas {
	position: absolute;
	left: 50%;
	top: 50%;
	margin: -10.3125vw 0 0 -10.3125vw;
	width: 20.625vw;
	height: 20.625vw;
	pointer-events: none;
	transform:
		translateX(calc(24vw * var(--mx)))
		translateY(calc(24vh * var(--my)))
		translateZ(0);

	div {
		width: 100%;
		height: 100%;
	}

	svg {
		display: block;
		width: 100%;
		height: 100%;
		fill: var(--dark);
		opacity: 0;
		transform: scale(.25);
		transition:
			opacity var(--trans),
			transform var(--trans);
	}

	@include to($to-sm) {
		display: none;
	}
}

.content {
	position: relative;
	z-index: 2;
	padding: 32px 10vw 32px 32px;
	height: 100%;
	box-sizing: border-box;
	color: var(--white);
	transition:
		color var(--trans),
		background var(--trans);

	@include to($to-sm) {
		display: flex;
		align-items: center;
		min-height: 96px;
		padding: 24px 48px 24px 16px;
	}
}

.title {
	position: relative;

	@include to($to-sm) {
		font-size: 20px;
		line-height: 1.2;
	}
}

.arrow {
	position: absolute;
	top: 32px;
	right: 32px;
	width: 32px;
	height: 32px;
	fill: var(--stroke-dark);
	transition: fill var(--trans);

	@include to($to-sm) {
		top: 16px;
		right: 16px;
		width: 16px;
		height: 16px;
	}
}

:global(.is-mouse) {
	.module:focus-visible {
		outline: none;
		box-shadow: none;
	}

	.module:hover .content,
	.module:focus-visible .content {
		color: var(--white);
		background: var(--accent);
	}
	.module:hover .arrow,
	.module:focus-visible .arrow {
		fill: var(--white);
	}
	.module:hover .canvas,
	.module:focus-visible .canvas {
		svg {
			opacity: 1;
			transform: none;
		}
	}
}

:global(.is-touch) {
	.clicked .content {
		color: var(--white);
		background: var(--accent);
	}
	.clicked .arrow {
		fill: var(--white);
	}
}