@import '../config/variables';

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  position: relative;
  text-align: center;
  text-decoration: none;
  color: inherit;
  background: transparent;
  border: 1px solid transparent;
  box-sizing: border-box;
  overflow: hidden;
  transition:
      color .4s,
      background .4s,
      border .4s,
      opacity .4s;
  cursor: pointer;

  span {
    position: relative;
  }
}

/* * * Sizes * * */
.btn--sm {
  padding: 0;
  height: auto;
  font: 300 var(--fs-16) var(--font);
  letter-spacing: -0.02em;

  @include to($to-md) {
    font: 300 var(--fs-14) var(--font);
  }
  @include to($to-sm) {
    font: 300 var(--fs-12) var(--font);
  }
}

.btn--md {
  padding: 0;
  height: auto;
  font: 400 30px/1.1875 var(--font);
  letter-spacing: -0.02em;

  @include from($from-hd) {
    font: 400 1.875vw/1.1875 var(--font);
  }

  @include to($to-md) {
    font: 400 26px/1.2 var(--font);
  }
  @include to($to-sm) {
    font: 400 20px/1.2 var(--font);
  }
}

.btn--lg {
  padding: 0;
  height: auto;
  font: 400 64px/1.1875 var(--font);
  letter-spacing: -0.02em;

  @include from($from-hd) {
    font: 400 4vw/1.1875 var(--font);
  }

  @include to($to-lg) {
    font: 400 56px/1.1875 var(--font);
  }
  @include to($to-md) {
    font: 400 28px/1.1875 var(--font);
  }
  @include to($to-sm) {
    font: 400 24px/1.2 var(--font);
  }
}

/* * * Designs * * */
.btn--flat {
  color: var(--accent);
  background: none;
  border: none;

  &:hover,
  &:active {
    color: inherit;
  }

  &:focus-visible {
    outline: none;
    box-shadow: none;
    color: inherit;
  }

  &[disabled],
  &.is-disabled, {
    color: var(--gray);
    opacity: .65;
    pointer-events: none;
    cursor: default;
  }
}

.btn--flat-white {
  color: var(--white);
  background: none;
  border: none;

  &:hover,
  &:active {
    color: var(--accent);
  }

  &:focus-visible {
    outline: none;
    box-shadow: none;
    color: var(--accent);
  }

  &[disabled],
  &.is-disabled, {
    color: var(--gray);
    opacity: .65;
    pointer-events: none;
    cursor: default;
  }
}

/* * * Wide * * */
.btn--wide {
  display: flex;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

/* * * With icons * * */
.btn__icon {
  svg {
    display: block;
    fill: currentColor;
  }
}

.btn__icon--left {
  margin: 0 6px 0 0;
}
.btn__icon--right {
  margin: 0 0 0 6px;
}

/* * * Loading * * */
.btn {
  span {
    transition: opacity var(--trans);
  }

  &__spinner {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    font-size: 8px;
    opacity: 0;
    pointer-events: none;
    transition: opacity var(--trans);

    & > div:after {
      border-width: 3px;
    }
  }

  &[class*="btn--flat"].is-loading {
    color: inherit;

    span {
      opacity: .1;
      transition: opacity var(--trans);
    }

    &[disabled],
    &.is-disabled, {
      opacity: 1 !important;
    }

    .btn__icon {
      opacity: 0;
    }

    .btn__spinner {
      color: var(--accent);
      opacity: 1;
      transition: opacity var(--trans);
    }
  }
}
