@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.module {
	position: relative;
	// padding-top: 190px;
	padding-bottom: 240px;

	@include to($to-md) {
		// padding-top: 100px;
		padding-bottom: 140px;
	}
	@include to($to-sm) {
		// padding-top: 56px;
		padding-bottom: 56px;
	}
}

.track {
	width: 50%;

	@include to($to-md) {
		width: 40%;
	}
	@include to($to-sm) {
		width: 100%;
	}
}

.content {
	width: 50%;

	@include to($to-md) {
		width: 60%;
	}
	@include to($to-sm) {
		display: flex;
		flex-flow: column nowrap;
		width: 100%;
	}
}

.animation {
	display: block;
	position: relative;
	order: 2;
	height: 160px;

	:global {
		.canvas {
			margin-top: -300px;
			height: 600px !important;
		}
	}
}

.section {
	position: relative;
	margin-bottom: 256px;

	@include to($to-lg) {
		margin-bottom: 180px;
	}
	@include to($to-md) {
		margin-bottom: 120px;
	}
	@include to($to-sm) {
		margin-bottom: 56px;
		width: 100%;
	}

	&:last-child {
		margin-bottom: 0;
	}

	@include to($to-sm) {
		&:nth-child(2) {
			order: 1;
		}
		&:nth-child(2) ~ & {
			order: 3;
		}
	}
}

.sticky {
	display: flex;
	align-items: center;
	justify-content: center;
	position: sticky;
	top: calc(50vh - 73px);
	min-height: 144px;

	:global {
		@include to($to-md) {
			.canvas {
				right: 60%;
				margin: -320px -40vw 0 0;
				width: 80vw !important;
				height: 640px !important;
			}
		}
	}
}