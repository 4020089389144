@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.fixed {
	position: fixed;
	z-index: 10;
	left: 0;
	bottom: 0;
	right: 0;
	pointer-events: none;
}

.outer {
	position: absolute;
	left: 0;
	bottom: 0;
	right: 0;
	height: 4px;
	pointer-events: none;

	@include to($to-sm) {
		height: 2px;
	}
}

.inner {
	position: absolute;
	left: 0;
	bottom: 0;
	height: 100%;
	width: 0;
	background: var(--accent);
}

.withTransition {
	transition: width var(--medium);
}