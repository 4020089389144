@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

$duration: .8s;

$rectTranslateY: -36px;
$rectMobTranslateY: -20px;

.module {
  position: fixed;
  z-index: 200;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}

.strip {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 21%;
  will-change: transform;

  @for $i from 1 through 5 {
    &:nth-child(#{$i}) {
      left: #{20% * ($i - 1)};
      margin-top: #{$rectTranslateY * ($i - 1)};
    }
  }

  @include to($to-sm) {
    width: 51%;

    &:nth-child(2) {
      left: 50%;
    }

    &:nth-child(3), &:nth-child(4), &:nth-child(5) {
      display: none;
    }
  }
}

.waiting .strip {
  transform: translateY(100%);
}

.enter .strip,
.inter .strip {
  transform: translateY(0);
  transition: transform #{$duration} var(--ease);

  @for $i from 1 through 5 {
    &:nth-child(#{$i}) {
      transition-delay: #{120 * (5 - $i)}ms;
    }
  }
}

.exit .strip {
  transform: translateY(-100%);
  transition: transform #{$duration} var(--ease);

  @for $i from 1 through 5 {
    &:nth-child(#{$i}) {
      transition-delay: #{120 * (5 - $i)}ms;
    }
  }
}

/* * * Colors * * */
.dark {
  background: var(--dark);
}

.white {
  background: var(--white);
}
