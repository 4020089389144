@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.choice {
  display: block;
  position: relative;
  font: 300 var(--fs-24) var(--font);

  @include to($to-lg) {
    font: 300 var(--fs-16) var(--font);
  }
  @include to($to-sm) {
    font: 300 var(--fs-12) var(--font);
  }

  & > input {
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    margin: -2px;
    width: 22px;
    height: 22px;
    border: none;
    clip: rect(0 0 0 0);
    appearance: none;
  }

  & > input ~ span {
    display: block;
    min-height: 1.332em;
    padding-top: 0;
    padding-left: 1.5em;
    box-sizing: border-box;
    color: var(--gray);
    transition: color var(--trans);
    cursor: pointer;
  }

  & > input ~ span:before {
    position: absolute;
    left: 0;
    top: .075em;
    width: 1em;
    height: 1em;
    background: var(--white);
    border: 1px solid var(--stroke-light);
    box-sizing: border-box;
    transition: all var(--trans);
    content: '';

    @include to($to-lg) {
      top: .2em;
    }
  }
  & > input ~ span:after {
    position: absolute;
    left: 0;
    top: .075em;
    margin: calc(.25em);
    width: .50em;
    height: .50em;
    background: var(--text);
    opacity: 0;
    transition: all var(--trans);
    content: '';

    @include to($to-lg) {
      top: .2em;
    }
  }

  & > input[type="radio"] ~ span:before {
    border-radius: 50%;
  }
  & > input[type="radio"] ~ span:after {
    border-radius: 50%;
  }

  & > input:checked ~ span:after {
    opacity: 1;
  }

  & > input:hover ~ span {
    color: inherit;
  }

  & > input:focus-visible ~ span:before {
    border-color: var(--accent);
  }

  & > input:disabled {
    pointer-events: none;
    cursor: default;
  }
  & > input:disabled ~ span {
    color: var(--gray);
    pointer-events: none;
    cursor: default;
  }
  & > input:disabled ~ span:before {
    border-color: var(--stroke-light);
    opacity: .3;
  }
}

.choice ~ .choice {
  margin-top: 16px;

  @include to($to-sm) {
    margin-top: 8px;
  }
}
