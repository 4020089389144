@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

$mobShift: 100px;

.canvas {
	display: block;
	background: var(--white);
	// pointer-events: none;

	&:before {
		display: block;
		height: 0;
		padding-bottom: 54.7413793103448%;
		content: '';
	}

	@include to($to-sm) {
		margin-bottom: $mobShift;
	}
}

.svg {
	position: absolute;
	left: -3px;
	top: -3px;
	right: -3px;
	width: calc(100% + 6px);

	&:before {
		display: block;
		height: 0;
		padding-bottom: 43.3962264150943%;
		content: '';
	}
}

.curve {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	fill: none;
	stroke: var(--stroke-light);
	stroke-width: 2px;

	@include to($to-sm) {
		stroke-width: 6px;
	}
}

.curveGray {
	transition: opacity var(--slow);

	path {
		transition: stroke-dashoffset var(--slow);
	}
}

.curveAccent {
	stroke: var(--accent);
	stroke-width: 6px;
	// stroke-linecap: round;

	@include to($to-sm) {
		stroke-width: 10px;
	}
}

.columns {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	position: absolute;
	top: 0;
	left: 8.2758620689655%;
	right: 9.1379310344828%;
	bottom: 8.9763779527559%;
}

.column {
	position: relative;
	width: 2px;
	height: 100%;
}

.columnStroke {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	background: var(--stroke-light);
	transform-origin: 50% 100%;
	transition:
		opacity var(--slow),
		transform var(--slow);

	@include to($to-sm) {
		padding-bottom: $mobShift;
		bottom: -$mobShift;
	}
}

.columnPoint {
	position: absolute;
	width: 16px;
	height: 16px;
	left: -7px;
	margin-top: -10px;
	border: 2px solid var(--stroke-light);
	box-shadow:
		0 0 0 5px var(--white),
		inset 0 0 0 8px var(--white);
	box-sizing: border-box;
	transition: opacity var(--slow);

	@include to($to-sm) {
		left: -5px;
		margin-top: -8px;
		width: 12px;
		height: 12px;
		box-shadow:
			0 0 0 3px var(--white),
			inset 0 0 0 6px var(--white);
	}
}

.columnFill {
	position: absolute;
	left: -7px;
	right: -7px;
	bottom: 0;
	margin-top: -9px;
	background: var(--accent);
	box-shadow: 0 0 0 5px var(--white);
	transform-origin: 50% 100%;

	@include to($to-sm) {
		left: -2px;
		right: -2px;
		box-shadow: 0 0 0 3px var(--white);
	}
	@include to($to-sm) {
		bottom: -$mobShift;
	}
}

.column:last-child .columnFill {
	margin-top: -15px;
}

.columnLabel {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	left: 50%;
	top: 100%;
	margin-left: -50px;
	width: 100px;
	height: 3.5625vw;
	font: 300 var(--fs-24) var(--font);
	text-align: center;
	color: var(--gray);
	transition:
		opacity var(--slow),
		color var(--slow);

	@include to($to-md) {
		height: 48px;
		font: 300 var(--fs-16) var(--font);
	}
	@include to($to-sm) {
		top: calc(100% + #{$mobShift});
		height: 32px;
		font: 300 var(--fs-12) var(--font);
	}
}

.columnLabelActive {
	color: inherit;
}

/* * * On dark bg * * */
:global(.is-dark-section) .canvas {
	background: var(--dark);
}

:global(.is-dark-section) .columnStroke {
	background: var(--stroke-dark);
}

:global(.is-dark-section) .columnPoint {
	box-shadow:
		0 0 0 5px var(--dark),
		inset 0 0 0 8px var(--dark);

	@include to($to-sm) {
		box-shadow:
			0 0 0 3px var(--dark),
			inset 0 0 0 6px var(--dark);
	}
}

:global(.is-dark-section) .columnFill {
	box-shadow: 0 0 0 5px var(--dark);

	@include to($to-sm) {
		box-shadow: 0 0 0 3px var(--dark);
	}
}

/* * * Start animation * * */
.columnStroke {
	opacity: 0;
	transform: scaleY(0);
}

.columnPoint, .columnLabel {
	opacity: 0;
}

.isVisible .columnStroke {
	opacity: 1;
	transform: none;
}

.isVisible .columnPoint,
.isVisible .columnLabel,
.isVisible .curveGray {
	opacity: 1;
}

.isVisible .column {
	@for $i from 1 through 10 {
		&:nth-child(#{$i}) .columnPoint,
		&:nth-child(#{$i}) .columnLabel,
		&:nth-child(#{$i}) .columnStroke {
			transition-delay: #{.2 * $i}s;
		}
	}
}
