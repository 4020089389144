@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.titleLink {
	display: block;
	text-decoration: none;
	color: inherit;

	svg {
		display: inline-block;
		vertical-align: top;
		top: .44em;
		position: relative;
		width: 32px;
		height: 32px;
		margin-left: 24px;
		fill: currentColor;
		transition: transform var(--trans);

		@include to($to-md) {
			width: 24px;
			height: 24px;
		}
		@include to($to-sm) {
			position: absolute;
			top: auto;
			width: 14px;
			height: 14px;
			margin-left: 8px;
			margin-top: .37em;
			vertical-align: 0;
		}
	}

	color-accent + svg {
		fill: var(--accent);
	}

	&:focus-visible {
		outline: none;
		box-shadow: none;
	}

	&:hover,
	&:focus-visible {
		color: var(--accent);

		svg {
			transform: translate(.1em, -.1em);
		}
	}
}

:global(.h1) .titleLink svg {
	top: .375em;

	@include from($from-md) {
		position: absolute;
		top: auto;
		margin-top: .375em;
	}

	@include to($to-sm) {
		top: auto;
		width: 16px;
		height: 16px;
		margin-top: .44em;
	}
}
