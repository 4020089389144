@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';


.spinner {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	font-size: 30px;
	width: 3.333332em;
	height: 3.333332em;
	overflow: hidden;
	pointer-events: none;
}

.ring {
	position: absolute !important;
	left: 50%;
	top: 50%;
	margin-left: -1em;
	margin-top: -1em;
	width: 2em;
	height: 2em;

	&:after {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		width: 1em;
		height: 1em;
		margin: auto;
		border-radius: 50%;
		border-width: 2px;
		border-style: solid;
		border-color:
			currentColor transparent currentColor transparent;
		opacity: 1;
		animation: ring-spin 2s linear infinite;
		content: '';
	}
}

@keyframes ring-spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}
