@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.module {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	z-index: 15;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	pointer-events: none;
}

.bg {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background: var(--graphite);
}

.container {
	display: flex;
	align-items: center;
	justify-content: center;

	@include to($to-md) {}
	@include to($to-sm) {}
}

.word {
	display: inline-flex;
	flex-flow: row nowrap;
	position: relative;
	font-size: 9.3125vw;
	overflow: hidden;

	@include to($to-sm) {
		font-size: 50px; // should calc with css custom prop
	}
	@include from($from-hd) {
		font-size: 149px;
	}
}

.curtain {
	position: absolute;
	z-index: 5;
	left: 0;
	top: 0;
	height: 95.75%;
	width: 100%;
	overflow: hidden;
	transform-origin: 0 50%;

	.strip {
		position: absolute;
		left: 0;
		top: -2px;
		bottom: -2px;
		width: 18%;
		transform: translateY(100%) translateZ(0);

		//animation: strips 4s linear infinite;
		animation: stripsForward 2.6s linear forwards;

		&:after,
		&:before {
			position: absolute;
			left: 0;
			top: 100%;
			width: 100%;
			height: 100%;
			background: var(--accent);
			content: '';
		}
		&:after {
			top: 300%;
		}

		@for $i from 1 through 6 {
			&:nth-child(#{$i}) {
				left: #{16.67% * ($i - 1)};
				animation-delay: #{(7 - $i) * .1}s;
			}
		}
	}
}

@keyframes strips {
	0% { transform: translateY(0) translateZ(0); }
	10% { transform: translateY(-100%) translateZ(0); }
	25% { transform: translateY(-100%) translateZ(0); }
	35% { transform: translateY(-200%) translateZ(0); }
	50% { transform: translateY(-200%) translateZ(0); }
	60% { transform: translateY(-300%) translateZ(0); }
	75% { transform: translateY(-300%) translateZ(0); }
	85% { transform: translateY(-400%) translateZ(0); }
	100% { transform: translateY(-400%) translateZ(0); }
}
@keyframes stripsForward {
	0% { transform: translateY(0) translateZ(0); }

	10% { transform: translateY(-100%) translateZ(0); }
	30% { transform: translateY(-100%) translateZ(0); }

	40% { transform: translateY(-200%) translateZ(0); }
	60% { transform: translateY(-200%) translateZ(0); }

	70% { transform: translateY(-300%) translateZ(0); }
	90% { transform: translateY(-300%) translateZ(0); }

	100% { transform: translateY(-300%) translateZ(0); }
}

.letter {
	display: block;
	position: relative;
	overflow: hidden;
	color: var(--white);

	&:nth-child(1) {
		color: var(--accent);
	}

	&:nth-child(1) {
		width: .697986577181208em;
	}
	&:nth-child(2) {
		width: .704697986577181em;
	}
	&:nth-child(3) {
		margin-left: -1px;
	}
	&:nth-child(4) {
		margin-left: -.040268456375839em;
		margin-right: -.033557046979866em;
		width: .791946308724832em;
	}
	&:nth-child(5) {
		width: .912751677852349em;
	}
	&:nth-child(6) {
		margin-left: -.080536912751678em;
		width: .953020134228188em;
	}

	svg {
		display: block;
		position: relative;
		width: auto;
		height: 1em;
		fill: currentColor;
		// opacity: 0;
	}

	&:nth-child(1) svg {
		opacity: 0;
	}
	&:nth-child(2) svg {
		transform: translateX(110%) translateZ(0);
	}
	&:nth-child(3) svg {
		transform: translateY(-110%) translateZ(0);
	}
	&:nth-child(4) svg {
		transform: translateY(110%) translateZ(0);
	}
	&:nth-child(5) svg {
		transform: translateX(-110%) translateZ(0);
	}
	&:nth-child(6) svg {
		transform: translateX(110%) translateZ(0);
	}
}

.playing .letter {
	svg {
		opacity: 1;
		transition:
			// opacity 1s var(--ease),
			transform .8s var(--ease);
	}

	&:nth-child(1) svg {
		opacity: 1;
	}
	&:nth-child(2) svg {
		transform: none;
		transition-delay: .8s;
	}
	&:nth-child(3) svg {
		transform: none;
		transition-delay: 1.2s;
	}
	&:nth-child(4) svg {
		transform: none;
		transition-delay: 1.6s;
	}
	&:nth-child(5) svg {
		transform: none;
		transition-delay: 2.0s;
	}
	&:nth-child(6) svg {
		transform: none;
		transition-delay: 2.4s;
	}
}

.playing .curtain {
	transform: scaleX(0);
	transition: transform 1.4s var(--ease);
	background: var(--accent);

	.strip {
		display: none;
		animation: none;
	}
}

.playing .bg {
	opacity: 0;
	transition: opacity 3.4s ease;
}

.tip {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 5.556vh;
	height: 17.778vh;
	font: 400 16px/1.25 var(--font);
	overflow: hidden;
	opacity: 0;
	transform: translateY(200%);

	@include to($to-md) {
		font: 400 14px/1.25 var(--font);
	}
	@include to($to-sm) {
		display: none;
		font: 400 12px/1.25 var(--font);
	}
}

.tipTrack {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	animation: scrollTip 3.6s infinite;
}

.tipIcon {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	height: 17.778vh;

	&::after,
	&::before {
		display: block;
		height: 50%;
		border-style: solid;
		border-color: currentColor;
		border-width: 0 0 0 1px;
		content: '';
	}

	span {
		display: block;
		margin: 10px 0;
		flex-shrink: 0;
	}
}

.complete .tip {
	opacity: .23;
	transform: none;
	transition:
		opacity var(--trans),
		transform var(--trans);
}

@keyframes scrollTip {
	0% { transform: translateY(0) translateZ(0); }
	50%, 100% { transform: translateY(-50%) translateZ(0); }
}