@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.input {
  display: block;
  position: relative;

  &__label {
    margin: 0 0 8px;
    font: 300 var(--fs-24) var(--font);
    color: var(--gray);

    @include to($to-lg) {
      font: 300 var(--fs-16) var(--font);
    }
    @include to($to-sm) {
      font: 300 var(--fs-12) var(--font);
    }

    &.isRequired:after {
      color: var(--accent);
      content: ' \002A';
    }

    &.isPlaceholder {
      position: absolute;
      z-index: 5;
      left: 0;
      top: 16px;
      padding: 0;
      margin-bottom: 0;
      width: auto;
      font: 300 var(--fs-24) var(--font);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: var(--gray);
      transform-origin: 0 0;
      transition: none;
      pointer-events: none;
      backface-visibility: hidden;

      @include to($to-lg) {
        font: 300 var(--fs-16) var(--font);
      }
      @include to($to-sm) {
        top: 11px;
        font: 300 var(--fs-12) var(--font);
      }

      &.isPlaceholderFocused {
        top: -16px;
        transform: scale(.75);
        opacity: 1;

        @include to($to-sm) {
          top: -11px;
          transform: scale(1);
        }
      }
    }

    &.hasTransition {
      transition:
        top var(--trans),
        transform var(--trans);
    }
  }

  &__field {
    position: relative;

    textarea,
    input:not([type="file"]) {
      display: block;
      position: relative;
      padding: 16px 0;
      width: 100%;
      max-width: 100%;
      height: 64px;
      font: 300 var(--fs-24) var(--font);
      text-overflow: ellipsis;
      text-shadow: none;
      color: var(--text);
      background: var(--white);
      border: solid var(--stroke-light);
      border-width: 0 0 1px;
      border-radius: 0;
      box-sizing: border-box;
      transition:
        border-color .4s;
      outline: none;

      @include to($to-lg) {
        height: 56px;
        font: 300 var(--fs-16) var(--font);
      }
      @include to($to-sm) {
        padding: 11px 0;
        height: 40px;
        font: 300 var(--fs-12) var(--font);
      }

      &:focus,
      &:hover {
        border-color: var(--accent);
      }
      &:focus-visible {
        border-color: var(--accent);
      }
      .input.hasError & {
        border-color: var(--warning);
      }

      &[readonly] {
        cursor: default;
      }
      &[disabled] {
        background: var(--background);
        cursor: default;
        pointer-events: none;
      }
      &[placeholder] {
        text-overflow: ellipsis;
      }

      &::placeholder,
      &.placeholder:before {
        font-weight: inherit;
        color: var(--gray);
        text-overflow: ellipsis;
        transition: opacity .4s;
      }
      &:not([type="tel"]):focus::placeholder,
      &.placeholder:focus:before {
        opacity: 0;
      }

      &::-ms-clear {
        visibility: hidden;
      }
    }

    textarea,
    &.isAutoGrow:after {
      padding: 16px 0;
      height: auto;
      min-height: 160px;
      min-width: 100%;
      font: 300 var(--fs-24) var(--font);
      cursor: text;
    }

    textarea.sm,
    &.isAutoGrow.sm:after {
      min-height: 96px;
    }
    textarea.md,
    &.isAutoGrow.md:after {
      min-height: 162px;
    }
    textarea.lg,
    &.isAutoGrow.lg:after {
      min-height: 224px;
    }
  }

  &__field {
    &.isAutoGrow {
      position: relative;

      textarea {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        resize: none;
        overflow: hidden;

        &[disabled] {
          min-height: 0;
        }
      }

      &:after {
        display: block;
        white-space: pre-wrap;
        border: 1px solid transparent;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        user-select: none;
        box-sizing: border-box;
        content: attr(data-replicated) ' ';
      }

      &.disabled:after {
        min-height: 0;
      }
    }
  }
}