a {
  text-decoration: none;
  color: inherit;
}

a, button, [role="button"] {
  outline: none;

  &:focus-visible,
  &.focus-visible {
    box-shadow: 0 0 0 2px var(--focus-visible);

    @supports (outline-offset: 1px) {
      box-shadow: none;
      outline: 2px solid var(--focus-visible);
      outline-offset: .2em;
    }
  }
}