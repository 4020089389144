@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.module {
	display: block;
	position: relative;
	height: 50vw; // 800px;
	color: var(--white);
	background: var(--dark);

	@include to($to-md) {
		height: 75vw;
	}
	@include to($to-sm) {
		height: 125vw;
	}
}

.container {
	display: block;
	width: 100%;
	height: 100%;

	:global {
		.gm-style iframe + div {
			border-color: var(--anthracite) !important;
		}

		[aria-label]:focus-visible,
		.gmnoprint a:focus-visible,
		.gmnoprint button:focus-visible {
			outline: none;
			box-shadow: none;
			color: var(--accent) !important;
		}
	}
}

.zoomBtn {
	position: absolute;
	z-index: 5;
	top: .4em;
	right: .4em;
	width: 1em;
	height: 1em;
	font-size: 80px;
	color: var(--white);
	background: var(--dark);

	&:after,
	&:before {
		position: absolute;
		left: 50%;
		top: 50%;
		margin: -1px 0 0 -.175em;
		width: .35em;
		border-top: 2px solid;
		content: '';
	}

	&:hover {
		background: var(--accent);
	}

	@include to($to-md) {
		font-size: 48px;
	}
	@include to($to-sm) {
		font-size: 32px;
	}
}

.zoomBtn.zoomIn {
	&:after {
		transform: rotate(-90deg);
	}
}

.zoomBtn.zoomOut {
	margin-top: 1.3em;
}

.marker {
	position: absolute;
	left: 0;
	top: 0;
	cursor: pointer;

	svg {
		position: absolute;
		left: -.5em;
		bottom: 0;
		width: 1em;
		height: 1.208333333333333em;
		font-size: 48px;
		fill: var(--dark);
		transition: fill var(--trans);

		@include to($to-md) {
			font-size: 40px;
		}
		@include to($to-sm) {
			font-size: 32px;
		}

		&:first-child {
			left: calc(-.5em - 1px);
			bottom: -1px;
			width: calc(1em + 2px);
			height: calc(1.208333333333333em + 2px);
			fill: var(--anthracite);
		}
	}

	&:hover,
	&:focus-visible {
		svg {
			fill: var(--accent);
		}
	}
}

.markerActive {
	// pointer-events: none;

	svg {
		fill: var(--accent) !important;
	}
}