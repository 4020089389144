@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.outer {
	position: relative;
	padding-bottom: 100vh;

	@include to($to-lg) {
		padding-bottom: 60vh;
	}
	@include to($to-sm) {
		min-height: 540px;
		padding-bottom: 0;
		overflow: hidden;
	}

	/*
	 * Отступ у непрозрачных секций
	 */
	@include to($to-sm) {
		& + :global(.is-dark-section),
		& + :global(.is-white-section) {
			margin-top: 25vh;
		}
	}

	:global {
		.strips {
			position: absolute;
			bottom: 0;
			left: 60%;
			width: 40%;
			height: 100.1vh;
			overflow: hidden;
			pointer-events: none;

			@include to($to-lg) {
				height: 60vh;
			}
		}
		.strip {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			height: 26%;
			background: var(--white);
			backface-visibility: hidden;

			&:nth-last-child(2) {
				bottom: 25%;
			}
			&:nth-last-child(3) {
				bottom: 50%;
			}
			&:nth-last-child(4) {
				bottom: 75%;
			}
		}

		.strips--reverse {
			left: 0;
			bottom: 0;
			width: 60.5%;
			height: 100vh;

			.strip {
				left: 100%;
				right: auto;
				width: 240%;
			}
		}
	}
}

.outer + :global(.is-white-section) {
	@include to($to-sm) {
		background: var(--white);

		.strips {
			display: none;
		}
	}
}
.outer + :global(.is-dark-section) {
	@include to($to-sm) {
		background: var(--dark);

		.strips {
			display: none;
		}
	}
}

.outerClosed {
	padding-bottom: 0;
}

.outerReverse {
	padding-bottom: 0;
}

.outerNormal,
.outerReverse {
	z-index: 10;
}

.outerNormal {
	/*
	 * Подтягивает следующую за ним секцию с прозрачным фоном,
	 * чтобы она наложилась на область анимации
	 */
	& + :global(.is-transparent-section) {
		margin-top: -50vh;

		@include to($to-lg) {
			margin-top: -30vh;
		}
		@include to($to-sm) {
			margin-top: 0;
		}
	}
}

.inner {
	position: relative;
	min-height: 100vh;
}

/*
 * На десктопе подтягивает блок так,
 * чтобы он лег поверх предыдущей секции для перехода со скроллом
 */
.outerApproach.outerNormal .inner {
	@include from($from-sm) {
		margin-top: -50vh;
	}
}

.sticky {
	position: sticky;
	top: -1px;
	height: calc(100vh + 2px);
	box-sizing: border-box;

	:global {
		@include to($to-lg) {
			.canvas {
				top: 50%;
				right: 50%;
				margin-top: -40vw;
				width: 50% !important;
				height: 80vw !important;
			}
		}
	}
}

.slider {
	position: relative;
	height: 100vh;
	min-height: 100%;
}

.left {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;
	width: 60%;
	overflow: hidden;

	@include to($to-lg) {
		width: 50%;
	}
}

.right {
	position: relative;
	width: 40%;
	color: var(--text);
	background: var(--white);
	overflow: hidden;

	@include to($to-lg) {
		width: 50%;
	}

	@include from($from-sm) {
		transform: translateY(calc(25vh * var(--approachSeek)));
	}
}

.slide {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	padding: 80px 0;
	background: #ccc;
	box-sizing: border-box;
	will-change: clip;

	//@for $i from 1 through 10 {
	//	&:nth-child(#{$i}) {
	//		z-index: #{10 - $i};
	//	}
	//}
}

.slideBg {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: var(--white);
	opacity: 0;
}

.slideContent {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;
	position: relative;
	height: 100%;
	max-width: 50%;
	margin: 0 auto;
	text-align: center;

	@include to($to-hd) {
		max-width: 75%;
	}
	@include to($to-lg) {
		max-width: 65%;
	}
	@include to($to-sm) {
		padding: 0 0 270px;
		box-sizing: border-box;
	}
}

.slideTitle {
	margin-bottom: 24px;

	@include to($to-sm) {
		margin-bottom: 12px;
	}
}

.slideText {
	font: 300 var(--fs-16) var(--font);
	color: var(--gray);

	@include from($from-hd) {
		font: 300 1vw/1.5 var(--font);
	}

	@include to($to-sm) {
		font: 300 var(--fs-12) var(--font);
	}

	p ~ p {
		margin-top: 1.5em;
	}
}

.slideIndex {
	@include from($from-sm) {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
	}
	@include to($to-sm) {
		margin-bottom: 48px;
		font: 400 14px/1.4 $font;
	}
}

.touchSlider {
	:global {
		.splide__list {
			min-height: 540px;
		}
		.splide__slide {
			width: 100%;
		}

		@include to($to-md) {
			.canvas {
				left: 0;
				right: 0;
				bottom: 0;
				top: 30% !important;
				width: 100% !important;
				height: 70% !important;
			}
		}
	}
}

.swipeTip {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 5%;
	font: 300 var(--fs-12) $font;
	letter-spacing: .04em;
	text-align: center;
	pointer-events: none;
}

.focusTrap {
	position: absolute;
	left: 0;
	right: 0;
	height: 1px;
	opacity: 0;

	@include from($from-sm) {
		margin-top: 25vh;
	}
	@include to($to-sm) {
		display: none;
	}
}