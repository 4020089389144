@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.outer {
	position: relative;
	margin-top: 0;
	padding-top: 6vh;
	padding-bottom: 25vh;

	@include to($to-lg) {
		margin: 0;
		padding: 0;
	}
	@include to($to-sm) {
		padding: calc(56px - 6vh) 0 60vw;
	}
}

.inner {
	position: relative;
	min-height: 300vh;
}

.sticky {
	position: sticky;
	top: 0;
	padding: 6vh 0;
	height: 100vh;
	box-sizing: border-box;
	overflow: hidden;

	@include from($from-lg) {
		@media (min-height: 900px) {
			padding: 6vw 0;
		}
	}
}

.content {
	display: flex;
	flex-flow: column;
	justify-content: space-between;
	// min-height: 100%;
	height: 100%;

	@include to($to-lg) {
		justify-content: space-evenly;
	}
}

.contentBox {
	&:last-child {
		@include to($to-sm) {
			display: flex;
			flex-flow: column nowrap;
			justify-content: center;
			height: 100%;
		}
	}
}

.sentence {
	margin-bottom: 0;

	@include from($from-md) {
		font: 400 4.22vh/1.263 var(--font);
	}
	@include to($to-sm) {
		margin-bottom: 32px;
	}
}

.ticker {
	margin-left: 20vw;

	@include to($to-sm) {
		margin-top: 30px;
		// margin-left: -10vw;
	}
}

/*
 * Вариант с болшой длиной текста
 */
.moreText .inner {
	padding: 4vw 0 0;
}

.moreText .sticky {
	padding: 2vw 0;

	@include from($from-lg) {
		@media (min-height: 900px) {
			padding: 2vw 0;
		}
	}
}

/*
 * Вариант с медиа-контентом
 */
.withMedia {
	padding-bottom: 0;
}

/*
 * Медиа-контент
 */
.mediaContent {
	position: absolute;
	z-index: 10;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	background: var(--dark);
	pointer-events: none;
	transform: translateZ(0);

	img {
		object-fit: cover;
		backface-visibility: hidden;
	}
}

.mediaContentVideo {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;

	video {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.bottomMediaContent {
	@include to($to-sm) {
		position: relative;
		margin: 48px -16px 10vh;

		&:before {
			display: block;
			height: 0;
			padding-bottom: 75%;
			content: '';
		}

		span,
		img {
			position: absolute !important;
			left: 0;
			top: 0;
			width: 100% !important;
			height: 100% !important;
			object-fit: cover;
			backface-visibility: hidden;
		}
	}
}

.staticMask {
	position: absolute;
	z-index: 2;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
	content-visibility: auto;
	color: var(--white);

	font-size: 36.8888888888889vh;

	@include from($from-md) {
		@media (min-height: 700px) {
			font-size: 332px;
		}
	}
	@include to($to-md) {
		font: 400 32vh/1.084 var(--font);

		@media (min-height: 900px) {
			font-size: 300px;
		}
	}
	@include to($to-sm) {
		font-size: 92px;
		line-height: 1;
	}
}

.staticMaskBox {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-end;
	height: 100vh;
	margin-left: 20vw;
	box-sizing: border-box;

	svg {
		display: block;
		position: relative;
		height: 1em;
		width: auto;
		fill: currentColor;
		backface-visibility: hidden;
	}
}

.staticMaskBoxInner {
	flex-shrink: 0;
	position: relative;
	top: -.022em;
	height: 1em;

	@include to($to-sm) {
		top: -.03em;
	}

	&:after,
	&:before {
		position: absolute;
		left: -1px;
		top: -1px;
		right: -1px;
		bottom: -1px;
		border: 2px solid currentColor;
		pointer-events: none;
		content: '';
	}

	&:after {
		left: 1px;
		top: 1px;
		right: 1px;
		bottom: 1px;
		border: none;
		outline: 100vh solid currentColor;
	}
}
