@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.module {
	position: relative;
	z-index: 2;
}

.item {
	margin-top: 88px;
	padding-top: 16px;
	border-top: 1px solid var(--stroke-dark);

	@include to($to-md) {
		margin-top: 48px;
	}
	@include to($to-sm) {
		display: block;
		margin-top: 32px;
		padding-top: 6px;
		border-top-color: var(--stroke-light);
	}
}

:global(.is-white-section) .item {
	border-top-color: var(--stroke-light);
}

:global(.is-dark-section) .item {
	border-top-color: var(--stroke-dark);
}

.itemNum {
	width: 28.571428571428571%; // or 20vw?
	color: var(--accent);

	@include to($to-sm) {
		margin-bottom: 18px;
		font: 400 20px/1.22 var(--font);
	}
}

.itemContent {
	width: 71.428571428571429%; // or 20vw?

	@include to($to-sm) {
		width: 100%;
	}
}

.itemTitle {
	margin-bottom: 16px;

	@include to($to-sm) {
		margin-bottom: 0;
		// font: 400 var(--fs-16) var(--font);
	}
}

.itemText {
	@include to($to-sm) {
		display: none;
	}
}

.itemBigText {
	margin-top: 48px;
	color: inherit;

	@include to($to-md) {
		margin-top: 32px;
	}
	@include to($to-sm) {
		margin-top: 24px;
	}
}

/* * * Gray * * */
.module.gray .itemNum {
	color: var(--gray);
}

/* * * Gray with accent hover * * */
.module.grayWithHover .item {
	position: relative;
	cursor: default;

	&:before {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 100%;
		height: 6px;
		background: var(--stroke-light);
		transform-origin: 50% 100%;
		transform: scaleY(.16667);
		transition:
			background var(--medium),
			transform var(--medium);
		content: '';
	}
}

.module.grayWithHover .itemTitle {
	transition: color var(--medium);
}

.module.grayWithHover .itemNum {
	color: var(--gray);
	transition: color var(--medium);
}

.module.grayWithHover .item:hover {
	&:before {
		transform: none;
		background-color: var(--accent);
	}
}
.module.grayWithHover .item:hover .itemNum,
.module.grayWithHover .item:hover .itemTitle {
	color: var(--accent);
}

/* * * Animation * * */
.item,
.title {
	opacity: 0;
	transform: translateY(50%) translateZ(0);
	transition:
		opacity var(--slow),
		transform var(--slow);
}

.item {
	@for $i from 1 through 10 {
		&:nth-child(#{$i}) {
			transition-delay: #{.4 * $i}s;
		}
	}
}

:global(.is-visible) {
	.item,
	.title {
		opacity: 1;
		transform: none;
	}
}
