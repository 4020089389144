@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

$cardH: 60vh;
$cardR: 64px;

.module {
	position: relative;
	min-height: 100vh;

	:root {
		--slideTail: 0;
	}
}

.sticky {
	overflow: visible !important;
}

.title {
	margin-bottom: 40px;

	@include to($to-md) {
		margin-bottom: 30px;
	}
	@include to($to-sm) {
		margin-bottom: 20px;
	}
}

.module .card > * {
	transition: opacity var(--medium);
}
.module.loading .card > * {
	opacity: 0;
}

/*
 * Анимация ленты в конце, когда две карточки съезжают ниже базовой линии
 */
.hasTrailAnimation {
	margin-bottom: 40vh;
}

.hasTrailAnimation .wrap,
.hasTrailAnimation .ticker {
	margin-bottom: -40vh;
	padding-bottom: 40vh;
}

.hasTrailAnimation .card {
	&:nth-last-child(1) {
		transform: translateY(calc(240px * var(--slideTail))) translateZ(0);
	}
	&:nth-last-child(2) {
		transform: translateY(calc(130px * var(--slideTail))) translateZ(0);
	}
	&:nth-last-child(3) {
		transform: translateY(calc(60px * var(--slideTail))) translateZ(0);
	}
}
