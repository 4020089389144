@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.header {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  min-width: var(--min-width);
  color: var(--white);
  box-sizing: border-box;
  opacity: 0;
  transform: translateY(-100%);
  pointer-events: none;
  transition:
    opacity var(--trans),
    transform var(--trans);

  @include to($to-md) {
    z-index: 50;
  }
}

.headerShown {
  opacity: 1;
  transform: none;
  pointer-events: auto;
}

.headerOnWhite {
  color: var(--text);
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;

  @include from($from-md) {
    padding-left: 1.875% !important;
    padding-right: 1.875% !important;
  }
}

.logo {
  display: block;
  position: relative;
  z-index: 102;

  svg {
    fill: currentColor;
  }

  @include to($to-md) {
    margin: 24px 0;

    svg {
      width: 86px;
      height: 18px;
    }
  }
}

/* * * Nav box * * */
.navBox {
  @include to($to-md) {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 68px 0 0;
    background: var(--dark);
    transform: translateX(-100%);
    opacity: 0;
    pointer-events: none;
    transition:
      opacity .4s,
      transform .4s;
  }
}

.navBoxShown {
  transform: none;
  opacity: 1;
  pointer-events: auto;
}

.navBoxInner {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @include to($to-md) {
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    padding: 0 $gapSM 40px;
    box-sizing: border-box;
    @include vertical-scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @include to($to-sm) {
    padding-left: $gap;
    padding-right: $gap;
  }
}

/* * * Menu * * */
.navMenu {
  @include to($to-md) {
    order: 2;
  }
}

.navMenuList {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  font: 400 16px/1.25 var(--font);
  cursor: default;

  @include to($to-lg) {
    font: 400 14px/1.429 var(--font);
  }
  @include to($to-md) {
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    font: 400 20px/1.2 var(--font);
  }

  & > li ~ li {
    margin-left: 22px;

    @include to($to-md) {
      margin-left: 0;
    }
  }
}

.navMenuLink {
  display: block;
  position: relative;
  z-index: 5;
  padding: 20px 0;
  text-decoration: none;
  color: var(--white);

  @include to($to-md) {
    padding: 6px 0;
  }

  &:before {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 4px;
    background: var(--accent);
    transform-origin: 50% 0;
    transform: scaleY(0);
    transition: transform var(--trans);
    pointer-events: none;
    content: '';

    @include to($to-md) {
      content: none;
    }
  }
}

.navMenuLink:focus-visible {
  color: var(--accent);
  outline: none;
  box-shadow: none;
}

:global(.is-mouse) .navMenuList li:focus-within .navMenuLink {
  @include from($from-md) {
    color: var(--accent);
    outline: none;
    box-shadow: none;

    &:before {
      transform: none;
    }
  }
}

.navMenuList li:hover > .navMenuLink {
  color: var(--accent);
}

.navMenuLinkCurrent {
  color: var(--accent);

  @include to($to-md) {
    margin-left: 8px;
  }

  &:before {
    transform: none;
  }
}

.headerOnWhite .navMenuLink {
  @include from($from-md) {
    color: var(--text);

    &:hover {
      color: var(--accent);
    }
  }
}

.headerOnWhite .navMenuLinkCurrent {
  color: var(--accent);
}

/* * * Sub menu mob * * */
.navMenuListSubMob  {
  margin-left: 16px;
  font: 400 16px/1.25 var(--font);
}

/* * * Sub menu * * */
.navMenuListSub {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity var(--medium);

  * {
    pointer-events: none;
  }
}

.headerShown .navMenuList li:hover .navMenuListSub {
  opacity: 1;
  pointer-events: auto;

  * {
    pointer-events: auto;
  }
}

:global(.is-mouse) .headerShown .navMenuList li:focus-within .navMenuListSub {
  @include from($from-md) {
    opacity: 1;
    pointer-events: auto;

    * {
      pointer-events: auto;
    }
  }
}

.navMenuListSubInner {
  position: relative;
  margin-left: calc(var(--left) * -1px);
  margin-right: calc(var(--right) * -1px);
  padding: 60px 0 0;
  background: var(--dark);
}

.navMenuListSubLink {
  display: flex;
  position: relative;
  z-index: 5;
  padding: 17px 1.875vw 18px calc(var(--left) * 1px);
  text-decoration: none;
  color: var(--white);
  border-top: 1px solid var(--stroke-dark);
  transition:
    color var(--fast),
    background var(--fast),
    border-color var(--fast);
  cursor: pointer;

  em {
    position: absolute;
    left: 1.875vw;
    font-weight: 300;
    font-style: normal;
    color: var(--gray);
    transition:
      color var(--fast),
      transform var(--fast);
  }
  span {
    position: relative;
    transition: transform var(--fast);
  }

  &:focus-visible {
    color: var(--white);
    background: var(--accent);
    border-color: var(--accent);
    outline: none;
    box-shadow: none;

    em {
      color: var(--white);
      transform: translateX(16px);
    }
    span {
      transform: translateX(16px);
    }
  }
  &:focus-visible + & {
    border-color: var(--accent);
  }

  &:hover {
    color: var(--white);
    background: var(--accent);
    border-color: var(--accent);

    em {
      color: var(--white);
      transform: translateX(16px);
    }
    span {
      transform: translateX(16px);
    }
  }
  &:hover + & {
    border-color: var(--accent);
  }
}

.navMenuListSubLinkCurrent {
  color: var(--accent);

  em {
    color: var(--accent);
    transform: translateX(16px);
  }
  span {
    transform: translateX(16px);
  }
}

.headerOnWhite .navMenuListSubInner {
  background: var(--white);
  border-bottom: 1px solid var(--stroke-light);
}
.headerOnWhite .navMenuListSubLink {
  color: var(--text);
  border-top-color: var(--stroke-light);

  &:hover {
    color: var(--white);
  }
  &:hover + & {
    border-top-color: var(--accent);
  }
}

/* * * Lang block * * */
.navLang {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  z-index: 5;
  margin-left: 22px;
  font: 400 16px/1.25 var(--font);
  color: var(--gray);

  @include to($to-lg) {
    font: 400 14px/1.25 var(--font);
  }
  @include to($to-md) {
    order: 1;
    margin: 0 0 6px;
    font: 300 14px/1.25 var(--font);
  }

  & > a ~ a {
    margin-left: 12px;
  }
}

.navLangLink {
  display: block;
  padding: 20px 0;
  text-decoration: none;

  @include to($to-md) {
    padding: 16px 0;
  }

  &:focus-visible {
    color: var(--accent);
    outline: none;
    box-shadow: none;
  }

  &:hover {
    color: var(--accent);
  }
}

.navLangLinkCurrent {
  @include to($to-md) {
    color: var(--white);
    pointer-events: none;
  }
  @include from($from-md) {
    display: none;
  }
}

/* * * Contacts block * * */
.contacts {
  order: 3;
  margin-top: 40px;
  font: 300 14px/1.2 var(--font);
  color: var(--gray);

  p ~ p {
    margin-top: 8px;
  }

  a {
    &:hover {
      color: var(--white);
    }
  }

  @include from($from-md) {
    display: none;
  }
}

/* * * Sandwich * * */
.sandwich {
  z-index: 102;
  width: 44px;
  height: 44px;
  margin-right: - 6px;
  color: var(--accent) !important;
  border: none;

  @include from($from-md) {
    display: none;
  }
}

.sandwich .sandwichIcon {
  display: block;
  position: relative;
  margin: 8px auto;
  width: 32px;
  height: 2px;
  background: currentColor;
  border-radius: 0;
  box-sizing: border-box;
  transform: translateZ(0);
  transition: transform var(--trans);

  span:first-child,
  span:last-child {
    position: absolute;
    left: 0;
    width: 32px;
    height: 2px;
    transform-origin: 50% 50%;
    transform: translateZ(0);
    transition: transform var(--trans);

    &:before {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      height: inherit;
      background: currentColor;
      border-radius: 0;
      transform-origin: 50% 50%;
      transform: translateZ(0);
      transition: transform var(--trans);
      content: '';
    }
  }

  span:first-child {
    top: 8px;
  }

  span:last-child {
    bottom: 8px;
  }
}

.sandwichActive .sandwichIcon {
  background: transparent;
  transform: rotate(180deg);

  span:first-child {
    transform: translate(0, -8px);

    &:before {
      transform: rotate(-45deg);
    }
  }
  span:last-child {
    transform: translate(0, 8px);

    &:before {
      transform: rotate(45deg);
    }
  }
}
