@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.item {
	display: block;
	position: relative;
	margin-top: 88px;
	padding: 16px 48px 0 0;
	text-decoration: none;
	color: inherit;
	border-top: 1px solid var(--stroke-light);
	transition: color var(--fast);

	@include to($to-md) {
		margin-top: 32px;
	}
	@include to($to-sm) {
		margin-top: 24px;
		padding: 16px 32px 0 0;
	}

	&:first-child {
		margin-top: 0;
	}

	&:before {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 100%;
		height: 6px;
		background: transparent;
		transform-origin: 50% 100%;
		transform: scaleY(0);
		transition:
			background var(--trans),
			transform var(--trans);
		content: '';
	}
}

:global(.is-dark-section) .item {
	border-top-color: var(--stroke-dark);
}

.caption {
	position: relative;
	color: inherit;

	@include to($to-sm) {}
}

.date {
	display: block;
	margin-top: 48px;
	font: 300 var(--fs-24) var(--font);
	color: var(--gray);

	@include to($to-sm) {
		margin-top: 16px;
		font: 300 var(--fs-12) var(--font);
	}
}

.arrow {
	position: absolute;
	top: 16px;
	right: 0;
	width: 32px;
	height: 32px;
	fill: var(--stroke-light);
	transition:
		fill var(--fast),
		transform var(--fast);

	@include to($to-md) {
		width: 24px;
		height: 24px;
	}
	@include to($to-sm) {
		width: 16px;
		height: 16px;
	}
}

:global(.is-dark-section) .arrow {
	fill: var(--stroke-dark);
}

/* * * Item hover * * */
.item:hover {
	color: var(--accent);
}

.item:hover:before {
	transform: none;
	background-color: var(--accent);
}

.item:hover .arrow {
	fill: var(--dark);
	transform: translate(5px, -5px);
}

:global(.is-dark-section) {
	.item:hover .arrow {
		fill: var(--white);
	}
}

/* * * Animation * * */
.item {
	opacity: 0;
	transform: translateY(50%) translateZ(0);
	transition:
		color var(--fast),
		opacity var(--slow),
		transform var(--slow);
}

.item.isVisible {
	opacity: 1;
	transform: none;
}
