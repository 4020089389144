@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.section {
	position: relative;
	min-height: 100vh;

	@include to($to-lg) {}
	@include to($to-sm) {}
}

.section.sticky {
	min-height: 150vh;

	@include to($to-sm) {
		min-height: 100vh;
	}
}

.screen {
	position: sticky;
	top: 0;
	min-height: 100vh;
	overflow: hidden;
}

.column {
	width: 50%;
	box-sizing: border-box;

	@include to($to-sm) {
		width: 100%;
	}
}

/*
 * Контент
 */
.content {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: space-between;
	padding: 21.32vh 0;

	@include to($to-sm) {
		padding: 56px 0;
	}
}

.slides {
	position: relative;
	width: 100%;
	transition: height var(--medium);

	@include from($from-sm) {
		height: 40vh !important;
		mask-image: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 94%, rgba(0,0,0,0.6) 97%, rgba(0,0,0,0) 100%);
	}

	:global {
		.splide {
			width: 100%;
		}
		.splide__slide {
			width: 100%;
			text-align: center;
		}
	}
}

.slide {
	position: absolute;
	left: 0;
	top: 0;
	padding: 0 5vw;
	width: 100%;
	text-align: center;
	box-sizing: border-box;
	opacity: 0;
	pointer-events: none;
	transition:
		opacity var(--medium);
}

.slideActive {
	opacity: 1;
	pointer-events: auto;
	transition-delay: 800ms;
}

.slideText {
	overflow: hidden;
	margin-top: 40px;
	max-height: 182px;
	font: 300 var(--fs-16) var(--font);

	@include to($to-lg) {
		max-height: 160px;
		font: 300 var(--fs-14) var(--font);
	}
	@include to($to-sm) {
		max-height: 140px;
		font: 300 var(--fs-12) var(--font);
	}

	p {
		margin: .5em 0;

		&:first-child {
			margin-top: 0;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
}

/*
 * Навигация
 */
.nav {
	width: 100%;
	margin-top: 4.447vh;
	overflow: hidden;
	mask-image: linear-gradient(90deg, rgba(0,0,0,0) 10%, rgba(0,0,0,1) 40%, rgba(0,0,0,1) 50%, rgba(0,0,0,1) 60%, rgba(0,0,0,0) 85%);

	@include to($to-sm) {
		margin-top: 56px;
	}

	:global {
		.splide {
			width: calc(120px * 5);
			max-width: 100%;
			margin: 0 auto;
		}
		.splide__track {
			overflow: visible;
		}
		.splide__slide {
			width: 120px;
			margin: 0 -1px;
		}
	}
}

.navBtn {
	justify-content: center;
	padding: 12px 0;
	width: 100%;
	font: 400 56px/1 var(--font);
	color: inherit;
	box-sizing: border-box;
	cursor: pointer;
	transition: color var(--medium);
	pointer-events: none;

	@include to($to-lg) {
		font: 400 48px/1 var(--font);
	}
	@include to($to-sm) {
		font: 400 32px/1 var(--font);
	}

	:global(.splide__slide.is-active) & {
		color: var(--accent);
	}

	&:before {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		border: solid var(--stroke-dark);
		border-width: 0 2px;
		transform: scaleY(var(--scale));
		content: '';
	}

	span {
		position: relative;
		transform: scale(var(--scale));
	}
}

/*
 * Стрелки
 */
.navArrows {
	margin-top: 34px;
	justify-content: center;

	@include to($to-sm) {
		margin-top: 24px;
	}
}

.navArrow {
	margin: 0 8px;
	width: 44px;
	height: 44px;
	color: var(--white);
	background: none;
	border: none;

	svg {
		width: 32px;
		height: 32px;
		fill: currentColor;

		@include to($to-sm) {
			width: 24px;
			height: 24px;
		}
	}

	&:hover {
		color: var(--accent);
	}
}

/*
 * Обертка для курсора
 */
.navWrap {
	position: relative;
	width: 100%;
	transition: opacity var(--trans);

	@include from($from-sm) {
		cursor: none;
	}
	@include to($to-sm) {
		cursor: grab;

		&:active {
			cursor: grabbing;
		}
	}
}

.navWrap .cursor {
	opacity: 0;
	transform: scale(0);
	clip-path: inset(0 0 0 0);
	transition:
		opacity var(--trans),
		transform var(--trans),
		clip-path var(--trans);
}

.navWrap:hover .cursor {
	opacity: 1;
	transform: scale(1);
	clip-path: inset(0 0 0 0);
}

.navWrap:active .cursor {
	clip-path: inset(12px 12px 12px 12px);
}

.navWrap.isLocked {
	pointer-events: none;
	opacity: .75;
}
.navWrap.isLocked .cursor {
	opacity: 0;
	transform: scale(0);
	clip-path: inset(0 0 0 0);
}

/*
 * Вьюбокс для видео
 */
.viewBox {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;
	padding: 40px 5vw;
	background: var(--white);

	@include to($to-sm) {
		padding: 30px 5vw 60vw;
	}
}

.video {
	display: block;
	width: 100%;
	height: auto;
	margin: 0 auto;
	transition: opacity var(--slow);

	&:global(.is-faded) {
		opacity: 0;
	}
}

/*
 * Цвета
 */
.dark {
	color: var(--white);
	background: var(--dark);
}

.light {
	color: var(--text);
	background: var(--white);
}