@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.module {
	position: relative;
	height: 100vh;
	background: var(--graphite);
	cursor: none;
}

.content {
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-between;
	position: relative;
	z-index: 6;
	padding-top: 56px;
	padding-bottom: 56px;
	min-height: var(--vh);
	box-sizing: border-box;

	@include to($to-md) {
		padding-top: 28px;
		padding-bottom: 28px;
	}
}

.head {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	padding-top: 8px;
	font: 300 var(--fs-16) var(--font);
	border-top: 1px solid;

	:global {
		span {
			width: 25%;
		}
		span:first-child {
			width: 50%;
		}
	}

	@include to($to-md) {
		display: none;
	}
}

.pictures {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;

	img {
		object-fit: cover;
	}
}

.background {
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;

	&:before {
		position: absolute;
		z-index: 1;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background: linear-gradient(0deg, rgba(17, 17, 17, 0.3), rgba(17, 17, 17, 0.3));
		content: '';
	}
}

.foreground {
	position: absolute;
	z-index: 2;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
	background: var(--dark);
}

.crossPoint {
	position: absolute;
	z-index: 4;
	left: 50%;
	top: 50%;
}

.crossFrame {
	position: absolute;
	left: -170px;
	right: -170px;
	top: -130px;
	bottom: -130px;
	content: '';

	@include from($from-hd) {
		left: -10.625vw;
		right: -10.625vw;
		top: -8.125vw;
		bottom: -8.125vw;
	}

	@include to($to-sm) {
		left: -78px;
		right: -78px;
		top: -62px;
		bottom: -62px;
	}

	:global {
		.corner {
			position: absolute;
			height: 31.4814814814815%;
			width: 23.943661971831%;
			border-style: solid;
			border-color: var(--white);
		}
	}
}
.crossFrameLT {
	left: 0;
	top: 0;
	border-width: 1px 0 0 1px;
}
.crossFrameRT {
	right: 0;
	top: 0;
	border-width: 1px 1px 0 0;
}
.crossFrameLB {
	left: 0;
	bottom: 0;
	border-width: 0 0 1px 1px;
}
.crossFrameRB {
	right: 0;
	bottom: 0;
	border-width: 0 1px 1px 0;
}

.crossLineLeft {
	position: absolute;
	right: 55.9859154929577%;
	top: 50%;
	width: 53.5211267605634%;
	border-top: 1px solid var(--white);
}
.crossLineRight {
	position: absolute;
	left: 55.9859154929577%;
	top: 50%;
	width: 53.5211267605634%;
	border-top: 1px solid var(--white);
}
.crossLineBottom {
	position: absolute;
	left: 50%;
	top: 57.8703703703704%;
	height: 51.8518518518519%;
	border-left: 1px solid var(--white);
}

.crossCenter {
	position: absolute;
	left: 50%;
	top: 50%;
	width: 19px;
	height: 11px;
	margin-left: -9px;
	margin-top: 3px;

	path {
		fill: none;
		stroke: var(--white);
	}
}
