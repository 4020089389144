@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';
@import '/src/core/CardsSlider/CardsSlider.module';

.module {
	position: relative;
}

.heading {
	position: relative;
	z-index: 3;
	margin-bottom: 104px;

	@include to($to-lg) {
		margin-bottom: 72px;
	}
	@include to($to-md) {
		margin-bottom: 32px;
	}
	@include to($to-sm) {
		margin-bottom: 40px;
	}
}

.slider {
	@include from($from-sm) {
		cursor: none;
	}
}

.preview {
	display: block;
	position: relative;
	flex-shrink: 0;
	color: var(--gray);
	background: var(--dark);
	box-sizing: border-box;
	overflow: hidden;
	height: 100%;
	transition:
		color var(--fast),
		height var(--fast);

	&:before {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		border: 2px solid var(--stroke-card);
		transition: opacity var(--fast);
		pointer-events: none;
		content: '';

		@include to($to-sm) {
			border-width: 1px;
		}
	}
}

/* * * Content * * */
.preview .content {
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-between;
	position: relative;
	z-index: 2;
	min-height: 100%;
	padding: 32px;
	box-sizing: border-box;
	transition:
		color var(--fast),
		background var(--fast);

	@include to($to-sm) {
		padding: 16px;
	}
}

.preview .title {
	color: var(--white);
	transition: color var(--medium);

	@include from($from-lg) {
		font: 400 52px/1.1154 var(--font);
	}
	@include from($from-hd) {
		font: 400 3.25vw/1.1154 var(--font);
	}
}

.arrow {
	position: absolute;
	z-index: 4;
	top: 32px;
	right: 32px;
	width: 32px;
	height: 32px;
	fill: var(--stroke-dark);
	transition: fill var(--trans);

	@include to($to-sm) {
		top: 16px;
		right: 16px;
		width: 16px;
		height: 16px;
	}
}

/* * * Active * * */
:global(.is-mouse) {
	.preview:focus-visible {
		outline: none;
		box-shadow: none;
	}

	.preview:hover,
	.preview:focus-visible {
		z-index: 2;
	}
	.preview:hover .content,
	.preview:focus-visible .content {
		color: var(--white);
		background: var(--accent);
	}
	.preview:hover .title,
	.preview:focus-visible .title {
		color: var(--white);
	}
	.preview:hover .arrow,
	.preview:focus-visible .arrow {
		fill: var(--white);
	}
}

:global(.is-touch .splide__slide.is-active) {
	.preview {
		z-index: 2;
	}
	.preview .content {
		color: var(--white);
		background: var(--accent);
	}
	.preview .title {
		color: var(--white);
	}
	.preview .arrow {
		fill: var(--white);
	}
}

/* * * Active * * */
//:global(.is-mouse) {
//	.preview:hover {
//		z-index: 2;
//		color: var(--white);
//
//		&:before {
//			opacity: 0;
//		}
//	}
//	.preview:hover .content {
//		background: var(--accent);
//	}
//}
//
//:global(.is-touch .splide__slide.is-active) {
//	.preview {
//		z-index: 2;
//		color: var(--white);
//
//		&:before {
//			opacity: 0;
//		}
//	}
//	.preview {
//		background: var(--accent);
//	}
//}
