@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.track {
	position: absolute;
	z-index: 10;
	top: 70%;
	bottom: 25vh;
	color: var(--white);

	@include to($to-sm) {
		top: 50%;
	}
}