@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

$rectTranslateY: 36px;
$rectMobTranslateY: 20px;

.white {
	color: var(--dark);
	background: var(--white);
}
.dark {
	color: var(--white);
	background: var(--dark);
}
.graphite {
	color: var(--white);
	background: var(--graphite);
}

.outer {
	position: relative;
}

.inner {
	position: relative;

	@include from($from-md) {
		background: transparent;
	}
}

.innerPadTop {
	padding-top: 20vh; // 12vw; // 192px;

	@include to($to-sm) {
		padding-top: 56px;
	}
}

.innerPadBottom {
	padding-bottom: 21.5vw;

	@include from($from-hd) {
		padding-bottom: 344px;
	}

	@include to($to-md) {
		padding-bottom: 10vw;
	}
	@include to($to-sm) {
		padding-bottom: 17.5vw; // 56px / 320px
	}
}

.base {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	height: 100%;
}

.bg {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	height: 32.5%;
	content: '';
}

.stripV {
	position: absolute;
	left: 0;
	top: 0;
	width: 21%;
	height: 32%;
	margin-top: -$rectTranslateY;
	will-change: transform;
	pointer-events: none;

	@include to($to-md) {
		margin-top: -$rectMobTranslateY;
		width: 50%;
	}

	&:nth-child(1) {
		width: 100%;
		height: calc(32% + #{$rectTranslateY});

		@include to($to-sm) {
			width: 50%;
			height: calc(100% + #{$rectTranslateY * 3});
			margin-top: -$rectMobTranslateY * 3;
		}
	}

	&:nth-child(2) {
		left: 20%;
		margin-top: #{$rectTranslateY * -2};
		height: calc(32% + #{$rectTranslateY * 2});

		@include to($to-md) {
			left: 50%;
			margin-top: #{$rectMobTranslateY * -2};
			height: calc(100% + #{$rectMobTranslateY * 2});
		}
		@include to($to-sm) {
			height: calc(100% + #{$rectMobTranslateY * 4});
			margin-top: -$rectMobTranslateY * 4;
		}
	}
	&:nth-child(3) {
		left: 40%;
		margin-top: #{$rectTranslateY * -3};
		height: calc(32% + #{$rectTranslateY * 3});

		@include to($to-md) {
			left: 66.661%;
			margin-top: #{$rectMobTranslateY * -3};
			height: calc(32% + #{$rectMobTranslateY * 3});
		}
	}
	&:nth-child(4) {
		left: 60%;
		margin-top: #{$rectTranslateY * -4};
		height: calc(32% + #{$rectTranslateY * 4});
	}
	&:nth-child(5) {
		width: 20%;
		left: 80%;
		margin-top: #{$rectTranslateY * -5};
		height: calc(32% + #{$rectTranslateY * 5});
	}

	@include to($to-md) {
		&:nth-child(2) ~ & {
			display: none;
		}
	}
}

.stripH {
	position: absolute;
	left: 0;
	right: 0;
	transform-origin: 100% 50%;
	will-change: transform;
	pointer-events: none;

	&:nth-child(1) {
		top: 32%;
		height: 13%;
	}
	&:nth-child(2) {
		top: calc(32% + 12%);
		height: 15%;
	}
	&:nth-child(3) {
		top: calc(32% + 12% + 14%);
		height: 20%;
	}
	&:nth-child(4) {
		top: calc(32% + 12% + 14% + 19%);
		height: 23%;
	}

	@include to($to-md) {
		display: none;
	}
}

.startScreen .bg,
.startScreen .inner,
.startScreen .stripH {
	opacity: 0;
	pointer-events: none;
}

.startScreen .stripV {
	top: 150vh;
	pointer-events: auto;
}

.startScreenShown .bg,
.startScreenShown .inner,
.startScreenShown .stripH {
	opacity: 1;
	pointer-events: auto;
	transition: opacity .8s;
	transition-delay: 1.6s;
}
.startScreenShown .stripV {
	top: 0;
	transition: top .8s;
	transition-delay: 1s;

	&:nth-child(2) {
		transition-delay: .8s;
	}
	&:nth-child(3) {
		transition-delay: .6s;
	}
	&:nth-child(4) {
		transition-delay: .4s;
	}
	&:nth-child(5) {
		transition-delay: .2s;
	}
}