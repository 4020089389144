@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.module {
	display: flex;
	flex-flow: row wrap;
	position: relative;
	z-index: 20;
	margin: 0 -5vw 40px;

	@include to($to-md) {
		margin: 0 -16px 8px;
	}
	@include to($to-sm) {
		margin: 0 -16px 16px;
	}
}

.element {
	width: calc(33.332% - 10vw);
	margin: 0 5vw 48px;
	box-sizing: border-box;

	@include to($to-md) {
		width: calc(33.332% - 32px);
		margin: 0 16px 24px;
	}
	@include to($to-sm) {
		width: calc(100% - 32px);
		margin: 0 16px 8px;
	}
}
