.wysiwyg ul,
.wysiwyg ol {
  clear: both;
  list-style: none;
  margin: $textOffset 0;
}

.wysiwyg li ul,
.wysiwyg li ol {
  margin: $textOffset 0 !important;
}

.wysiwyg li {
  position: relative;
  margin: 0 0 16px;
  padding-left: 22px;

  @include to($to-sm) {
    margin: 0 0 12px;
  }

  &:before {
    position: absolute;
    left: 0;
    top: 0;
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.wysiwyg ul > li:before {
  left: 0;
  top: .565em;
  width: 6px;
  height: 6px;
  background: currentColor;
  color: currentColor;
  content: '';
}
.wysiwyg ol > li:before {
  left: 0;
  top: 0;
  font: 400 var(--fs-16) var(--font);
  color: var(--gray);
  background: none;
}

.wysiwyg ol {
  margin-left: 0;
  counter-reset: list;
}
.wysiwyg ol ol {
  margin-left: 0;
  counter-reset: sublist;
}
.wysiwyg ol ol ol {
  margin-left: 0;
  counter-reset: thirdlist;
}

.wysiwyg ol > li {
  padding-left: 56px;
}
.wysiwyg ol ol > li {
  padding-left: 84px;
}
.wysiwyg ol ol ol > li {
  padding-left: 112px;
}

.wysiwyg ol > li:before {
  counter-increment: list;
  content: '0' counter(list) '.';
}
.wysiwyg ol > li:nth-child(9) ~ li:before {
  content: counter(list) '.';
}
.wysiwyg ol ol > li:before {
  counter-increment: sublist;
  content: counter(list) '.' counter(sublist);
}
.wysiwyg ol ol ol > li:before {
  counter-increment: thirdlist;
  content: counter(list) '.' counter(sublist) '.' counter(thirdlist);
}