@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.module {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;

	@include to($to-md) {}
	@include to($to-sm) {}
}

.point {
	position: absolute;
	z-index: 1;

	&:nth-child(1) {
		left: 16.875%;
		top: 26.667%;

		@include to($to-md) {
			left: 20.875%;
			top: 26.667%;
		}

		transform:
			translateX(calc(10vw * var(--mx)))
			translateY(calc(10vh * var(--my)))
			translateZ(0);
	}
	&:nth-child(2) {
		left: 53.125%;
		top: 16.223%;

		@include to($to-md) {
			left: 63.125%;
			top: 18.223%;
		}

		transform:
			translateX(calc(8vw * var(--mx)))
			translateY(calc(8vh * var(--my)))
			translateZ(0);
	}
	&:nth-child(3) {
		left: 85.625%;
		top: 26.667%;

		@include to($to-md) {
			left: 85.625%;
			top: 42.667%;
		}

		transform:
			translateX(calc(12vw * var(--mx)))
			translateY(calc(12vh * var(--my)))
			translateZ(0);
	}
	&:nth-child(4) {
		left: 22.625%;
		top: 76.112%;

		@include to($to-md) {
			left: 22.625%;
			top: 76.112%;
		}

		transform:
			translateX(calc(16vw * var(--mx)))
			translateY(calc(16vh * var(--my)))
			translateZ(0);
	}
	&:nth-child(5) {
		left: 70.625%;
		top: 80.334%;

		@include from($from-hd) {
			left: 68%;
		}
		@include to($to-md) {
			left: 75.625%;
			top: 85.334%;
		}

		transform:
			translateX(calc(10vw * var(--mx)))
			translateY(calc(10vh * var(--my)))
			translateZ(0);
	}

	&:nth-child(5) ~ & {
		display: none;
	}
}

.pointInner {
	position: absolute;
	left: 0;
	top: 0;

	:root {
		--fade: 0;
		--up: 0;
	}
	opacity: var(--fade);
	transform: translateY(calc(6vh * var(--up))) translateZ(0);
}

.pointBox {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	pointer-events: none;

	&:before {
		display: block;
		height: 0;
		content: '';
	}
}

.pointBoxText {
	display: flex;
	align-items: flex-end;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	padding: 1.25vw; // 20px;
	font: 300 16px/1.5 var(--font);
	color: var(--gray);
	border: 1px solid var(--stroke-dark);
	pointer-events: none;

	p {
		max-width: 50%;
	}

	@include to($to-lg) {
		font: 300 14px/1.5 var(--font);

		p {
			max-width: 60%;
		}
	}
	@include to($to-md) {
		font: 300 13px/1.5 var(--font);

		p {
			max-width: 100%;
		}
	}
}

.pointBoxMedia {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	pointer-events: auto;
	// clip-path: polygon(var(--showMedia) 0, 100% 0, 100% 100%, var(--showMedia) 100%);

	picture, video, img {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	transform:
		translateX(calc(5vw * var(--mx)))
		translateY(calc(5vh * var(--my)))
		translateZ(0);
}

.point {
	&:nth-child(1) {
		.pointBoxMedia {
			clip-path: polygon(var(--showMediaC) 0, 100% 0, 100% 100%, var(--showMediaC) 100%);
		}
	}
	&:nth-child(2) {
		.pointBoxMedia {
			clip-path: polygon(var(--showMediaA) 0, 100% 0, 100% 100%, var(--showMediaA) 100%);
		}
	}
	&:nth-child(3) {
		.pointBoxMedia {
			clip-path: polygon(var(--showMediaD) 0, 100% 0, 100% 100%, var(--showMediaD) 100%);
		}
	}
	&:nth-child(4) {
		.pointBoxMedia {
			clip-path: polygon(var(--showMediaB) 0, 100% 0, 100% 100%, var(--showMediaB) 100%);
		}
	}
	&:nth-child(5) {
		.pointBoxMedia {
			clip-path: polygon(var(--showMediaE) 0, 100% 0, 100% 100%, var(--showMediaE) 100%);
		}
	}
}

.pointBoxMediaEntity {
	position: absolute;
	z-index: 1;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background: var(--stroke-card);
	clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
	transition: clip-path var(--slow);
}

.pointBoxMediaEntity.isShown {
	z-index: 2;
	clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
	transition: clip-path var(--slow);
}
.pointBoxMediaEntity.isDown {
	z-index: 1 !important;
}

.pointBoxVideo {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

.variant-1 .pointBox {
	width: 20vw;
	min-width: 210px;

	&:before {
		padding-bottom: 56.25%; // 320px / 180px;
	}
}
.variant-1 .pointBoxMedia {
	left: 18.75%;
	top: 61.667%;

	@include from($from-hd) {
		left: 16.75%;
		top: 46.75%;
	}
	@include to($to-md) {
		left: 18.75%;
		top: 61.667%;
	}
}
.variant-1 .pointBoxText {
	align-items: flex-start;
}

.variant-2 .pointBox {
	width: 25vw;
	min-width: 256px;

	&:before {
		padding-bottom: 58%; // 400px / 232px;
	}

	@include from($from-hd) {
		width: 22vw;
	}
}
.variant-2 .pointBoxMedia {
	left: 40%;
	top: 26.138%;

	@include from($from-hd) {
		left: 50%;
		top: 20%;
	}
	@include to($to-md) {
		left: 20%;
		bottom: 44.138%;
		top: auto;
	}
}

.variant-3 .pointBox {
	width: 15vw;
	min-width: 156px;

	&:before {
		padding-bottom: 66.667%; // 240px / 160px;
	}
}
.variant-3 .pointBoxMedia {
	left: 40.833%;
	top: 81.25%;

	@include to($to-md) {
		right: 40.833%;
		bottom: 61.25%;
		top: auto;
		left: auto;
	}
}

.variant-4 .pointBox {
	width: 25vw;
	min-width: 256px;

	&:before {
		padding-bottom: 58%; // 400px / 232px;
	}

	@include from($from-hd) {
		width: 22vw;
	}
}
.variant-4 .pointBoxMedia {
	left: 40%;
	bottom: 24.138%;
	top: auto;

	@include from($from-hd) {
		left: 40%;
		bottom: 30%;
	}
	@include to($to-md) {
		left: 30%;
		bottom: 44.138%;
	}
}

.variant-5 .pointBox {
	width: 20vw;
	min-width: 204px;

	&:before {
		padding-bottom: 65.625%; // 320px / 210px;
	}
}
.variant-5 .pointBoxMedia {
	left: 18.75%;
	bottom: 61.667%;
	top: auto;

	@include to($to-md) {
		left: 18.75%;
		bottom: 41.667%;
	}
}