/* -------------------- Suisse -------------------- */
@font-face {
	font-family: 'Suisse Intl';
	src: local('Suisse Intl Light'), local('SuisseIntl-Light'),
	url('../../fonts/suisse-intl/SuisseIntl-Light-WebM.woff2') format('woff2'),
	url('../../fonts/suisse-intl/SuisseIntl-Light-WebM.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Suisse Intl';
	src: local('Suisse Intl Regular'), local('SuisseIntl-Regular'),
	url('../../fonts/suisse-intl/SuisseIntl-Regular-WebM.woff2') format('woff2'),
	url('../../fonts/suisse-intl/SuisseIntl-Regular-WebM.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}
