@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.module {
	position: relative;

	@include to($to-sm) {
		display: none;
	}
}

.frame {
	position: absolute;
	z-index: 1;
	right: 0;
	top: 0;
	width: 20.5vw;
	height: 11.75vw;
	will-change: transform;

	//transform:
	//	translateX(calc(8vw * var(--mx)))
	//	translateY(calc(8vw * var(--my)));

	@include to($to-hd) {

	}
	@include to($to-lg) {

	}

	&:before {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		border: 1px solid var(--stroke-light);
		content: '';
	}
}

.triangle {
	position: absolute;
	left: .875vw;
	top: 0;
	width: 18.75vw;
	height: 6.625vw;

	transform:
		translateX(calc(15.75vw * var(--seek)))
		translateY(calc(6.625vw * var(--seek)))
		translateZ(0);

	svg {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		fill: none;
		stroke: var(--stroke-light);
	}

	@include to($to-hd) {}
}

.triangle.intersect {
	svg {
		fill: var(--graphite);
		stroke: none;
	}
}

.frameInner {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

:global(.is-dark-section) {
	.frame:before {
		border-color: var(--stroke-dark);
	}
}

.play .triangle {
	transform:
		translateX(0)
		translateY(0)
		translateZ(0);
	transition:
		transform 1.2s var(--ease);
}