@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.sharing {
	display: block;
	position: relative;
	padding-top: 8px;
	margin: 88px 0;
	border-top: 1px solid var(--stroke-light);
	color: var(--text);
	cursor: default;

	@include to($to-md) {
		margin: 48px 0;
	}
	@include to($to-sm) {
		margin: 32px 0;
	}

	&:first-child {
		margin-top: 0;
	}
	&:last-child {
		margin-bottom: 0;
	}
}

.title {
	margin-bottom: 24px;
	font: 300 var(--fs-24) var(--font);
	color: var(--gray);

	@include from($from-hd) {
		font: 300 1.5vw/1.332 var(--font);
	}

	@include to($to-lg) {
		font: 300 var(--fs-16) var(--font);
	}
	@include to($to-sm) {
		margin-bottom: 8px;
		font: 300 var(--fs-12) var(--font);
	}
}

.links {
	display: flex;
	margin: 0 -48px -32px 0;
	font: 400 38px/1.263 $font;
	letter-spacing: -0.02em;

	@include from($from-hd) {
		font: 400 2.375vw/1.263 var(--font);
	}

	@include to($to-lg) {
		font: 400 34px/1.263 $font;
	}
	@include to($to-md) {
		font: 400 28px/1.263 $font;
	}
	@include to($to-sm) {
		font: 400 20px/1.263 $font;
	}

	a {
		margin: 0 48px 32px 0;
		color: inherit;
		text-decoration: none;

		&:hover {
			color: var(--accent);
		}
	}
}

:global(.is-dark-section) .sharing {
	color: var(--white);
}

:global(.is-dark-section) .title {
	color: var(--stroke-light);
}
