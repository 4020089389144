.wysiwyg p:first-child,
.wysiwyg li:first-child,
.wysiwyg ul:first-child,
.wysiwyg ol:first-child,
.wysiwyg dl:first-child,
.wysiwyg hr:first-child,

.table:first-child {
  margin-top: 0;
}

.wysiwyg p:last-child,
.wysiwyg li:last-child,
.wysiwyg ul:last-child,
.wysiwyg ol:last-child,
.wysiwyg dl:last-child,
.wysiwyg hr:last-child,

.table:last-child {
  margin-bottom: 0;
}
