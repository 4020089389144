@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.module {
	position: relative;
	min-height: 100vh;

	@include to($to-md) {
		margin-top: -120px;
	}
	@include to($to-sm) {
		margin-top: -56px;
	}
}
