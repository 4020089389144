@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.section {
	padding: 144px 0 204px;

	@include to($to-lg) {
		padding: $offsetShortLG 0;
	}
	@include to($to-md) {
		padding: $offsetShortMD 0;
	}
	@include to($to-sm) {
		padding: $offsetShortSM 0;
	}
}

.heading {
	position: relative;
}

.feed {
	margin-bottom: -104px;

	@include to($to-md) {
		margin-bottom: -48px;
	}
	@include to($to-sm) {
		margin-bottom: -32px;
	}
}

.item {
	display: block;
	position: relative;
	width: 33.332%;
	margin-bottom: 104px;
	padding-top: 8px;
	padding-right: 5vw;
	font: 300 var(--fs-16) var(--font);
	box-sizing: border-box;
	cursor: default;

	@include from($from-hd) {
		font: 300 1vw/1.5 var(--font);
	}
	@include to($to-md) {
		width: 50%;
		margin-bottom: 48px;
		font: 300 var(--fs-12) var(--font);
	}
	@include to($to-sm) {
		width: 100%;
		margin-bottom: 32px;
		padding-top: 6px;
		padding-right: 0;
	}

	&:before {
		position: absolute;
		left: 0;
		top: -1px;
		width: 100%;
		border-top: 1px solid var(--stroke-dark);
		transform-origin: 0 0;
		content: '';
	}
}

.itemInner {
	position: relative;
}

.itemCaption {
	margin-bottom: 4px;
	color: var(--gray);
}

.itemLinks {
	font: 400 2.375vw/1.263 var(--font);
	letter-spacing: -0.02em;

	@supports (font-size: var(--vw)) {
		font: 400 calc(var(--vw) * .02375)/1.263 var(--font);
	}

	@include to($to-md) {
		font: 400 28px/1.263 var(--font);
	}
	@include to($to-sm) {
		font: 400 20px/1.263 var(--font);
	}

	a {
		text-decoration: none;
		color: inherit;

		&:hover {
			color: var(--accent);
		}
	}
}

/* * * Animation * * */
.item:before {
	transform: scaleX(0);

	@include from($from-sm) {
		width: 200%;
	}
	@include from($from-md) {
		width: 300%;
	}
}

.itemInner {
	opacity: 0;
	transform: translateY(50%);
}

@include range($from-sm, $to-md) {
	.item:nth-child(2n+2):before {
		content: none;
	}
}
@include from($from-md) {
	.item:nth-child(3n+2):before,
	.item:nth-child(3n+3):before {
		content: none;
	}
}

.section.isShown {
	.item:before {
		transform: none;
		transition: transform var(--slow);
		transition-delay: 1.6s;
	}

	.itemInner {
		opacity: 1;
		transform: none;
		transition:
			opacity var(--slow),
			transform var(--slow);
		transition-delay: 2.0s;
	}

	@include range($from-sm, $to-md) {
		.item:nth-child(2n+2) .itemInner {
			transition-delay: 2.4s;
		}
	}
	@include from($from-md) {
		.item:nth-child(3n+2) .itemInner {
			transition-delay: 2.4s;
		}
		.item:nth-child(3n+3) .itemInner {
			transition-delay: 2.8s;
		}
	}
}
