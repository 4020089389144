@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';
@import '/src/core/CardsSlider/CardsSlider.module';

.section {
	position: relative;
}

.section.verticalOnMob {
	@include to($to-md) {
		padding-bottom: $offsetLG;
	}
	@include to($to-sm) {
		padding-bottom: $offsetMD;
	}
}

.heading {
	position: relative;
}

.headingSticky {
	margin-bottom: 0;
}

.stickyScreen {
	padding: 6vh 0;
	justify-content: space-between;
	height: 100vh;

	@include to($to-md) {
		padding: 12vh 0;
	}
	@include to($to-sm) {
		padding: 56px 0;
	}
}

.ticker {
	margin-top: 0;
	height: auto;
	max-height: none;
}

.sliderContainer {
	overflow: hidden;
}

.slider {
	padding-bottom: 80px;

	:global {
		.splide__track {
			overflow: visible;
		}

		.splide__list {
			align-items: stretch;
			height: auto;
		}

		.splide__slide {
			margin-left: 0;
			margin-right: 11vw;
			min-width: 0;
			width: auto !important;
			height: auto !important;

			@include to($to-md) {
				margin-right: 6vw;
			}
			@include to($to-sm) {
				margin-right: 36px;
				width: 100% !important;
			}
		}
	}

	@include from($from-sm) {
		cursor: none;
	}
}

.preview {
	display: flex;
	flex-flow: row nowrap;
	flex-shrink: 0;
	position: relative;
	min-width: 720px !important;
	width: 49vw !important;
	margin-left: 0 !important;
	margin-right: 11vw;
	padding-top: 70px;
	box-sizing: border-box;

	@include to($to-md) {
		width: 67.5vw !important;
		min-width: 216px !important;
		margin-right: 6vw;
	}
	@include to($to-sm) {
		flex-flow: column nowrap;
		justify-content: space-between;
		margin-right: 16px;
		padding-top: 48px;
		width: auto !important;
	}

	&:last-child {
		margin-right: 0;
	}
}

.previewPic {
	flex-shrink: 0;
	width: 20vw;
	max-width: 320px;
	margin-right: 3vw;

	@media (max-width: 1200px) {
		width: 240px;
		margin-right: 36px;
	}

	span, img {
		display: block !important;
		width: 100% !important;
		height: auto !important;
	}

	@include to($to-sm) {
		order: 2;
		width: 100%;
		max-width: 100%;
		margin: 24px 0 0;
	}
}

.previewContent {
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-between;
	width: 100%;
	//width: calc(100% - 23vw);
	//
	//@media (max-width: 1200px) {
	//	width: calc(100% - 276px);
	//}

	@include to($to-sm) {
		order: 1;
		width: 100%;
	}
}

.previewCounter {
	position: absolute;
	left: 0;
	top: 8px;
	font-weight: 400;

	span {
		color: var(--gray);
	}
}

.previewTitle {
	margin-bottom: 8px;
}

.previewText {
	margin-top: 24px;
}

.verticalFeed {
	@include to($to-sm) {
		padding-bottom: $offsetSM;
	}

	.preview {
		width: 100%;
		max-width: 100%;
		margin-right: 0;
	}
	.preview ~ .preview {
		margin-top: 32px;
	}
}

/*
 * Линия над слайдами
 */
.slider,
.ticker {
	position: relative;
	padding-top: 1px;

	&:before {
		position: absolute;
		top: 0;
		left: 0;
		right: -$gap;
		border-top: 1px solid var(--stroke-dark);
		content: '';

		@include from($from-sm) {
			right: -$gapSM;
		}
		@include from($from-md) {
			right: -$padVw;
		}
		@include from($from-lg) {
			right: -$padVwLG;
		}
	}
}