@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.module {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;

	@include to($to-md) {}
	@include to($to-sm) {}
}

.point {
	position: absolute;
	z-index: 1;
	transform: translateX(calc(100px * var(--sx))) translateZ(0);

	&:nth-child(1) {
		left: 80.3125%;
		top: 35.15625%;
	}
	&:nth-child(2) {
		left: 25.625%;
		top: 65.0390625%;
	}
	&:nth-child(3) {
		left: 15%;
		top: 98.4375%;
	}
	&:nth-child(4) {
		left: 71.875%;
		top: 86.328125%;
	}
	&:nth-child(5) {
		left: 25.625%;
		top: 24.8046875%;
	}

	&:nth-child(5) ~ & {
		display: none;
	}
}

.pointInner {
	position: absolute;
	left: 0;
	top: 0;
}

.pointBox {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);

	&:before {
		display: block;
		height: 0;
		content: '';
	}
}

.pointBoxMedia {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;

	picture, video, span, img {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.pointBoxVideo {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

.variant-1 .pointBox {
	width: 40vw;
	min-width: 128px;

	&:before {
		padding-bottom: 54.688%;
	}
}

.variant-2 .pointBox {
	width: 55vw;
	min-width: 176px;

	&:before {
		padding-bottom: 57.955%;
	}
}

.variant-3 .pointBox {
	width: 30vw;
	min-width: 96px;

	&:before {
		padding-bottom: 65.625%;
	}
}

.variant-4 .pointBox {
	width: 48.125vw;
	min-width: 154px;

	&:before {
		padding-bottom: 57.792%;
	}
}

.variant-5 .pointBox {
	width: 41.25vw;
	min-width: 132px;

	&:before {
		padding-bottom: 65.152%;
	}
}

.variant-1 .pointInner {
	opacity: var(--scale);
	transform: translateY(calc(-22vh * var(--up))) translateZ(0);
}
.variant-2 .pointInner {
	opacity: var(--scale);
	transform: translateY(calc(-30vh * var(--up))) translateZ(0);
}
.variant-3 .pointInner {
	opacity: var(--scale);
	transform: translateY(calc(-18vh * var(--up))) translateZ(0);
}
.variant-4 .pointInner {
	opacity: var(--scale);
	transform: translateY(calc(-22vh * var(--up))) translateZ(0);
}
.variant-5 .pointInner {
	opacity: var(--scale);
	transform: translateY(calc(-36vh * var(--up))) translateZ(0);
}
