@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.head {
	margin-bottom: 64px;

	@include to($to-md) {
		margin-bottom: 40px;
	}
}

.title {
	margin-bottom: 32px;
}

.preamble {
	margin-bottom: 80px;
	max-width: 50%;

	@include to($to-hd) {
		max-width: 60%;
	}
	@include to($to-md) {
		margin-bottom: 40px;
		max-width: 100%;
	}
	@include to($to-sm) {
		display: none;
	}
}

.sliderHead {
	position: relative;
	padding-top: 70px;
	border-top: 1px solid var(--stroke-light);

	@include to($to-sm) {
		padding-top: 40px;
	}
}

.sliderCounter {
	position: absolute;
	left: 0;
	top: 8px;
	font: 400 var(--fs-16) var(--font);
	color: var(--gray);

	@include to($to-sm) {
		font: 400 var(--fs-12) var(--font);
	}
}

.sliderCounter .current {
	color: var(--accent);
}

:global(.is-dark-section) {
	.sliderHead {
		border-top-color: var(--stroke-dark)
	}
	.sliderCounter {
		color: var(--stroke-dark);
	}
	.sliderCounter .current {
		color: var(--white);
	}
}

.slider {
	position: relative;
	overflow: hidden;

	:global {
		.splide {
			cursor: none;

			@include to($to-sm) {
				cursor: pointer;
			}

			// cursor: grab;
			// &:active {
			// 	cursor: grabbing;
			// }
		}

		.splide__track {
			overflow: visible;
		}

		.splide__list {
			align-items: flex-end;
		}

		.splide__slide {
			width: 27vw;
			margin-right: 24px;

			@include to($to-md) {
				width: 30vw;
				margin-right: 16px;
			}
			@include to($to-sm) {
				width: 216px;
			}
		}

		.splide__slide.is-active {
			@include to($to-sm) {
				img {
					filter: grayscale(0) !important;
				}
			}
		}
	}
}

.slider .wrap {
	position: relative;
}

.slider .wrap .cursor {
	opacity: 0;
	transform: scale(0);
	clip-path: inset(0 0 0 0);
	transition:
		opacity var(--trans),
		transform var(--trans),
		clip-path var(--trans);
}

.slider .wrap:hover .cursor {
	opacity: 1;
	transform: scale(1);
	clip-path: inset(0 0 0 0);
}

.slider .wrap:active .cursor {
	clip-path: inset(12px 12px 12px 12px);
}

.picture {
	display: block;
	position: relative;
	overflow: hidden;

	@include to($to-sm) {
		max-height: none;
	}

	img {
		display: block;
		width: 100%;
		height: auto;
		filter: grayscale(1);
		transition: filter var(--trans);
	}
}

.picture:hover {
	img {
		filter: grayscale(0);
	}
}

:global(.splide__slide:focus-visible) .picture {
	img {
		filter: grayscale(0);
	}
}

/*
 * Ритм лесенки
 */
.slider {
	.picture {
		&:before {
			display: block;
			height: 0;
			content: '';
		}

		img {
			object-fit: cover;
			image-rendering: -webkit-optimize-contrast;
		}
	}

	:global(.splide__slide:nth-child(3n+1)) .picture:before {
		padding-top: 83.332%;
	}
	:global(.splide__slide:nth-child(3n+2)) .picture:before {
		padding-top: 103.7%;
	}
	:global(.splide__slide:nth-child(3n+3)) .picture:before {
		padding-top: 68.51%;
	}
}