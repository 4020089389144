@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.heading {
	position: relative;
}

.feed {
	transition:
		opacity var(--medium),
		transform var(--medium);
}

.feed.isLoading {
	opacity: 0;
	transform: translateY(10%) translateZ(0);
}

/* * * Animation * * */
// .isVisible {}