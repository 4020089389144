@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.module {
	position: relative;
	font: 400 30px/1.4 var(--font);

	@include to($to-lg) {
		font: 400 24px/1.4 var(--font);
	}
	@include to($to-md) {
		font: 400 16px/1.4 var(--font);
	}
	@include to($to-sm) {
		font: 400 var(--fs-12) var(--font);
	}
}

.list {
	position: relative;
}

.item {
	display: flex;
	flex-flow: row nowrap;
	position: relative;
	padding-top: 8px;
	margin-bottom: 88px;
	border-top: 1px solid var(--stroke-dark);

	@include to($to-lg) {
		padding-top: 8px;
		margin-bottom: 48px;
	}
	@include to($to-md) {
		padding-top: 6px;
		margin-bottom: 42px;
	}
	@include to($to-sm) {
		padding-top: 4px;
		margin-bottom: 32px;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

:global(.is-white-section) .item {
	border-top-color: 1px solid var(--stroke-light);
}

.itemNum {
	flex-shrink: 0;
	align-self: flex-start;
	padding: 5px 10px 5px 0;
	width: 56px;
	font: 400 var(--fs-16) var(--font);
	color: var(--gray);
	box-sizing: border-box;

	@include to($to-md) {
		padding-top: 0;
		font: 400 var(--fs-14) var(--font);
	}
	@include to($to-sm) {
		padding-top: 0;
		width: 34px;
		font: 400 var(--fs-12) var(--font);
	}
}

.itemText {
	width: 100%;
}