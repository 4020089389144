@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.module {
	position: relative;
}

.white {
	color: var(--dark);
	background: var(--white);
}
.dark {
	color: var(--white);
	background: var(--dark);
}
.graphite {
	color: var(--white);
	background: var(--graphite);
}
.transparent {
	color: var(--white);
	background: transparent;
}

.offsetTop {
	margin-top: $offset;

	@include to($to-lg) {
		margin-top: $offsetLG;
	}
	@include to($to-md) {
		margin-top: $offsetMD;
	}
	@include to($to-sm) {
		margin-top: $offsetSM;
	}
}
.offsetBottom {
	margin-bottom: $offset;

	@include to($to-lg) {
		margin-bottom: $offsetLG;
	}
	@include to($to-md) {
		margin-bottom: $offsetMD;
	}
	@include to($to-sm) {
		margin-bottom: $offsetSM;
	}
}

.paddingTop {
	padding-top: $offset;

	@include to($to-lg) {
		padding-top: $offsetLG;
	}
	@include to($to-md) {
		padding-top: $offsetMD;
	}
	@include to($to-sm) {
		padding-top: $offsetSM;
	}
}

.paddingTopShort {
	padding-top: $offsetShort;

	@include to($to-lg) {
		padding-top: $offsetShortLG;
	}
	@include to($to-md) {
		padding-top: $offsetShortMD;
	}
	@include to($to-sm) {
		padding-top: $offsetShortSM;
	}
}

.paddingBottom {
	padding-bottom: $offset;

	@include to($to-lg) {
		padding-bottom: $offsetLG;
	}
	@include to($to-md) {
		padding-bottom: $offsetMD;
	}
	@include to($to-sm) {
		padding-bottom: $offsetSM;
	}
}

.startScreen .inner {
	opacity: 0;
	pointer-events: none;
}

.startScreenShown .inner {
	opacity: 1;
	pointer-events: auto;
	transition: opacity .8s;
	transition-delay: 1.6s;
}

:global(.with-striped-tail) + .module:before {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 100%;
	height: 100vh;
	background: inherit;
	pointer-events: none;
	content: '';
}