.wysiwyg h1, .h1 {
  font: 400 92px/1.08695652173913 var(--font);
  letter-spacing: -0.02em;

  @include from($from-hd) {
    font: 400 5.75vw/1.08695652173913 var(--font);

    @supports (font-size: var(--vw)) {
      font: 400 calc(var(--vw) * .0575)/1.08695652173913 var(--font);
    }
  }

  @include to($to-lg) {
    font: 400 82px/1.087 var(--font);
  }
  @include to($to-md) {
    font: 400 48px/1.15625 var(--font);
  }
  @include to($to-sm) {
    font: 400 32px/1.15625 var(--font);
  }

  // Regular offsets
  margin-bottom: 144px;

  @include to($to-lg) {
    margin-bottom: 112px;
  }
  @include to($to-md) {
    margin-bottom: 72px;
  }
  @include to($to-sm) {
    margin-bottom: 56px;
  }

  // Magazine layout offsets
  &.magazine-offset {
    margin-bottom: 104px;

    @include to($to-lg) {
      margin-bottom: 64px;
    }
    @include to($to-md) {
      margin-bottom: 48px;
    }
    @include to($to-sm) {
      margin-bottom: 24px;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.wysiwyg h2, .h2 {
  font: 400 64px/1.1875 var(--font);
  letter-spacing: -0.02em;

  @include from($from-md) {
    font: 400 4vw/1.1875 var(--font);

    @supports (font-size: var(--vw)) {
      font: 400 calc(var(--vw) * .04)/1.1875 var(--font);
    }
  }

  @include to($to-lg) {
    font: 400 56px/1.1875 var(--font);
  }
  @include to($to-md) {
    font: 400 28px/1.1875 var(--font);
  }
  @include to($to-sm) {
    font: 400 24px/1.2 var(--font);
  }
}

.wysiwyg h3, .h3 {
  font: 400 38px/1.263 var(--font);
  letter-spacing: -0.02em;

  @include from($from-lg) {
    font: 400 2.375vw/1.263 var(--font);

    @supports (font-size: var(--vw)) {
      font: 400 calc(var(--vw) * .02375)/1.263 var(--font);
    }
  }

  @include to($to-lg) {
    font: 400 34px/1.263 var(--font);
  }
  @include to($to-md) {
    font: 400 28px/1.263 var(--font);
  }
  @include to($to-sm) {
    font: 400 20px/1.263 var(--font);
  }
}

.wysiwyg h4, .h4 {
  font: 400 30px/1.4 var(--font);
  letter-spacing: -0.02em;

  @include from($from-hd) {
    font: 400 1.875vw/1.4 var(--font);

    @supports (font-size: var(--vw)) {
      font: 400 calc(var(--vw) * .01875)/1.4 var(--font);
    }
  }

  @include to($to-md) {
    font: 400 24px/1.4 var(--font);
  }
  @include to($to-sm) {
    font: 400 16px/1.24 var(--font);
  }
}
.wysiwyg h5, .h5 {}
.wysiwyg h6, .h6 {}

h1:first-child, h2:first-child, h3:first-child,
h4:first-child, h5:first-child, h6:first-child,

.h1:first-child, .h2:first-child, .h3:first-child,
.h4:first-child, .h5:first-child, .h6:first-child {
  margin-top: 0;
}

.wysiwyg h1:last-child, .wysiwyg h2:last-child, .wysiwyg h3:last-child,
.wysiwyg h4:last-child, .wysiwyg h5:last-child, .wysiwyg h6:last-child,

.h1:last-child, .h2:last-child, .h3:last-child,
.h4:last-child, .h5:last-child, .h6:last-child {
  margin-bottom: 0;
}

.wysiwyg h1 + *, .wysiwyg h2 + *, .wysiwyg h3 + *,
.wysiwyg h4 + *, .wysiwyg h5 + *, .wysiwyg h6 + *,

.wysiwyg .h1 + *, .wysiwyg .h2 + *, .wysiwyg .h3 + *,
.wysiwyg .h4 + *, .wysiwyg .h5 + *, .wysiwyg .h6 + * {
  margin-top: 0 !important;
}

/*
 * Отступы
 */
//.wysiwyg h1, .wysiwyg h2, .wysiwyg h3,
//.wysiwyg h4, .wysiwyg h5, .wysiwyg h6,
//
//.wysiwyg .h1, .wysiwyg .h2, .wysiwyg .h3,
//.wysiwyg .h4, .wysiwyg .h5, .wysiwyg .h6 {
//  margin-bottom: 88px;
//
//  @include to($to-sm) {
//    margin-bottom: 32px;
//  }
//}
