@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.module {
	position: relative;
	z-index: 2;
}

.heading {
	position: relative;
}

.feed {
	margin-bottom: -88px;

	@include to($to-md) {
		margin-bottom: -48px;
	}
	@include to($to-sm) {
		margin-bottom: -32px;
	}
}

.item {
	position: relative;
	width: 50%;
	margin-bottom: 88px;
	padding-top: 16px;
	padding-right: 10vw;
	box-sizing: border-box;

	@include to($to-md) {
		margin-bottom: 48px;
	}
	@include to($to-sm) {
		display: block;
		width: 100%;
		margin-bottom: 32px;
		padding-top: 6px;
		padding-right: 0;
	}

	&:before {
		position: absolute;
		left: 0;
		top: -1px;
		width: 200%;
		border-top: 1px solid var(--stroke-dark);
		transform-origin: 0 0;
		content: '';

		@include to($to-sm) {
			width: 100%;
		}
	}
}

:global(.is-white-section) .item:before {
	border-top-color: 1px solid var(--stroke-light);
}

.itemTitle {
	margin-bottom: 24px;

	@include to($to-sm) {
		margin-bottom: 16px;
	}
}

.itemText {
	@include to($to-sm) {}
}

.itemIcon {
	position: absolute;
	left: 0;
	bottom: 0;
	margin-top: 24px;
	width: 115px;

	@include to($to-md) {
		width: 80px;
	}
	@include to($to-sm) {
		margin-top: 12px;
		width: 80px;
	}

	span, svg, img {
		display: block !important;
		max-width: 100%;
		height: auto;
	}
}

/* * * Animation * * */
.heading {
	opacity: 0;
	transform: translateY(50%);
	transition:
		opacity var(--slow),
		transform var(--slow);
}

:global(.is-visible) {
	.heading {
		opacity: 1;
		transform: none;
	}
}

.item:before {
	transform: scaleX(0);
	transition: transform var(--slow);
}
.item:nth-child(2n+2):before {
	@include from($from-sm) {
		content: none;
	}
}

.item .itemInner {
	position: relative;
	padding-bottom: 139px;
	box-sizing: border-box;
	height: 100%;
	opacity: 0;
	transform: translateY(50%);
	transition:
		opacity var(--slow),
		transform var(--slow);
}
.item:nth-child(2n+2) .itemInner {
	transition-delay: .4s;

	@include to($to-sm) {
		transition-delay: 0s;
	}
}

.itemVisible:before {
	transform: none;
}

.itemVisible .itemInner {
	opacity: 1;
	transform: none;
}
