@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.section {
	position: relative;
	padding: 168px 0;
	color: var(--white);

	@include to($to-md) {
		padding: $offsetMD 0;
	}
	@include to($to-sm) {
		padding: $offsetSM 0;

		:global(.strips-vertical) & {
			padding-top: 0;
		}
	}
}

.dark {
	color: var(--white);
	background: var(--dark);
}

:global(.is-dark-section) .section {
	color: var(--white);
	background: var(--dark);
}

.white {
	color: var(--text);
	background: var(--white);
}

:global(.is-white-section) .section {
	color: var(--text);
	background: var(--white);
}

/* * * Headline * * */
.headline {
	margin-bottom: 112px;

	@include to($to-md) {
		margin-bottom: 72px;
	}
	@include to($to-md) {
		margin-bottom: 56px;
	}
	@include to($to-sm) {
		margin-bottom: 21px;
	}
}

.headlineLeft {
	position: relative;
	z-index: 2;
	width: 62.5%;
	padding-right: 30px;
	box-sizing: border-box;

	@include to($to-sm) {
		width: 100%;
	}
}

.headlineRight {
	position: relative;
	width: 37.5%;
	padding-top: 2vw;

	@include to($to-sm) {
		display: none;
	}
}

.headlineTitle {
	margin-bottom: 0;
}

.headlineText {
	margin-top: 40px;

	@include to($to-sm) {
		margin-top: 24px;
	}

	a {
		font-weight: 400;
		text-decoration: none;
		color: var(--accent);

		&:hover {
			color: var(--white);
		}
	}

	:global(.is-white-section) & {
		a {
			&:hover {
				color: var(--dark);
			}
		}
	}
}

/* * * Form * * */
.formFlow {
	justify-content: space-between;
	margin-bottom: -52px;

	@include to($to-lg) {
		margin-bottom: -32px;
	}
	@include to($to-sm) {
		margin-bottom: -24px;
	}
}

.formChild {
	width: calc(50% - 5vw);
	margin-bottom: 52px;

	@include to($to-lg) {
		margin-bottom: 32px;
	}
	@include to($to-sm) {
		width: 100%;
		margin-bottom: 24px;
	}
}

.formFooter {
	margin-top: 80px;

	@include to($to-lg) {
		margin-top: 56px;
	}
	@include to($to-sm) {
		margin-top: 32px;
	}
}

.formFooterNotice {
	margin-top: 16px;
	cursor: default;

	a {
		text-decoration: none;
		color: inherit;

		&:hover {
			color: var(--accent);
		}
	}
}