@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.section {
	position: relative;
	height: 150vh;

	@include to($to-lg) {}
	@include to($to-sm) {}
}

.sticky {
	position: sticky;
	top: 0;
	height: 100vh;
	box-sizing: border-box;
}

.layout {
	position: relative;
	height: 100vh;

	@include to($to-sm) {
		flex-flow: column wrap;
	}
}

.left {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;
	width: 60%;

	@include to($to-lg) {
		width: 50%;
	}
	@include to($to-sm) {
		height: 50%; // 56.25vw;
		width: 100%;
		order: 2;
	}
}

.right {
	position: relative;
	width: 40%;
	color: var(--text);
	background: var(--white);
	overflow: hidden;

	@include to($to-lg) {
		width: 50%;
	}
	@include to($to-sm) {
		height: 50%; // calc(100% - 56.25vw);
		width: 100%;
		order: 1;
	}
}

.slide {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;
	position: relative;
	padding: 40px 6.25vw;
	height: 100%;
	text-align: center;
	box-sizing: border-box;

	@include to($to-hd) {

	}
	@include to($to-lg) {

	}
	@include to($to-sm) {

	}
}

.title {
	margin-bottom: 16px;
}

.text {
	max-width: 320px;
	margin: 0 auto;
}

.arrow {
	margin-top: 88px;

	@include to($to-md) {
		margin-top: 48px;
	}
	@include to($to-sm) {
		margin-top: 32px;
	}
}

.picture {
	display: block;
	position: relative;
	width: 100%;
	height: 100%;

	&:before {
		position: absolute;
		z-index: 1;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.32), rgba(0, 0, 0, 0.32));
		content: '';
	}

	img {
		object-fit: cover;
	}
}