@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.module {
	position: absolute;
	z-index: 10;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background: var(--stroke-card);
	clip-path: polygon(50% 0, 50% 0, 50% 100%, 50% 100%);
	transform: translateZ(0);

	@include to($to-md) {}
	@include to($to-sm) {}
}

.slides {
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	padding-top: 12vh;
	padding-bottom: 12vh;
}

.slide {
	position: relative;
	font: 400 16px/1.146875 var(--font);
	letter-spacing: -0.02em;
	border-top: 1px solid;
	opacity: 0;
	transition: opacity .6s;
}

.slide ~ .slide {
	margin-top: 56px;
}

.odometer {
	display: flex;
	font: 400 64px/1.15625 var(--font);

	div {
		color: inherit !important;
	}
}

.odometerGt {
	position: relative;
	margin-right: .1em;
	transform: translateX(-140%) translateZ(0);
	transition: transform .6s;
}

.label {
	display: block;
	position: relative;
	margin-top: .5em;
	overflow: hidden;
}

.labelInner {
	display: block;
	position: relative;
	transform: translateY(110%) translateZ(0);
	transition: transform .6s;
}

.slideActive {
	opacity: 1;
}
.slideActive .odometerGt {
	transform: none;
}
.slideActive .labelInner {
	transform: none;
}