@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.footer {
  position: relative;
  padding: 120px 0;
  font: 400 var(--fs-18) var(--font);
  color: var(--white);

  @include to($to-lg) {
    padding: 100px 0;
    font: 400 var(--fs-16) var(--font);
  }
  @include to($to-md) {
    padding: 80px 0;
    font: 400 var(--fs-14) var(--font);
  }
  @include to($to-sm) {
    padding: 56px 0;
    font: 400 20px/1.2 var(--font);
  }
}

.logo {
  display: block;
  margin: 120px 0 208px;

  @include to($to-lg) {
    margin: 100px 0 140px;
  }
  @include to($to-md) {
    margin: 40px 0 80px;
  }

  svg {
    display: block;
    margin: 0 auto;
    max-width: 720px;
    width: 56.25%;
    height: auto;
  }

  @include to($to-sm) {
    display: none;
  }
}

.nav {
  position: relative;
}

.menu {
  margin-bottom: 16px;
  padding: 0 20px 0 0;
  width: 25%;
  box-sizing: border-box;

  li ~ li {
    margin-top: 16px;
  }

  a:hover {
    color: var(--accent);
  }

  @include to($to-sm) {
    margin-bottom: 0;
    width: 100%;

    li ~ li {
      margin-top: 0;
    }

    a {
      display: block;
      padding: 6px 0;
    }
  }
}

.contactsLink {
  @include to($to-sm) {
    font: 300 14px/1.2 var(--font);
    color: var(--gray);

    a {
      padding: 3px 0;
    }
    a:hover {
      color: var(--white);
    }
  }
}

.menu li:not(.contactsLink) + .contactsLink {
  @include to($to-sm) {
    margin-top: 48px;
  }
}

.bottom {
  position: relative;

  @include to($to-md) {
    justify-content: space-between;
  }
  @include to($to-sm) {
    flex-flow: row nowrap;
    padding: 16px 0 8px;
    margin-top: 32px;
    border-top: 1px solid var(--stroke-dark);
  }
}

.copyright {
  padding: 0 20px 0 0;
  width: 75%;
  font: 300 14px/1.285 var(--font);
  box-sizing: border-box;

  p {
    max-width: 586px;
  }

  @include to($to-lg) {
    font: 300 12px/1.285 var(--font);
  }
  @include to($to-md) {
    width: 50%;
  }
  @include to($to-sm) {
    width: 100%;
    font: 300 var(--fs-12) var(--font);
  }
}

.terms {
  font: 300 14px/1.285 var(--font);

  @include to($to-lg) {
    font: 300 12px/1.285 var(--font);
  }
  @include to($to-sm) {
    font: 300 var(--fs-10) var(--font);
  }
}

.developer {
  width: 25%;
  font: 300 14px/1.285 var(--font);

  @include to($to-lg) {
    font: 300 12px/1.285 var(--font);
  }
  @include to($to-md) {
    width: auto;
  }
  @include to($to-sm) {
    flex-shrink: 0;
    width: auto;
    margin-left: 16px;
    font: 300 var(--fs-12) var(--font);
  }
}

.developerLink {
  display: inline-flex;
  color: var(--white);

  svg {
    margin-top: 2px;
    margin-right: 8px;

    @include to($to-sm) {
      margin-top: 3px;
      margin-right: 4px;
      width: 23px;
      height: 10px;
    }
  }

  &:hover {
    color: var(--redcollar)
  }
}

.documentLinks {
  display: flex;
  flex-flow: row wrap;
  row-gap: 8px;
  column-gap: 16px;
  margin-top: 16px;
  font: 300 14px/1.285 var(--font);
  color: var(--gray);

  @include to($to-sm) {
    padding-top: 16px;
    font: 300 var(--fs-10) var(--font);
    border-top: 1px solid var(--stroke-dark);
  }

  a {
    color: inherit;
  }
  a:hover {
    color: var(--accent);
  }
}
