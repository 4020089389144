@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.module {
	position: relative;
	padding: 0;
}

.head {
	padding-bottom: 40px;
}

.title {
	margin-bottom: 0;
}

.text {
	margin-top: 12px;
	font: 300 var(--fs-12) var(--font);
	letter-spacing: -0.02em;
	color: var(--gray);
}