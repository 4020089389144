@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.module {
	position: relative;
	z-index: 2;
	padding: 0 !important;

	@include to($to-sm) {
		margin-bottom: -10vw;
	}
	@include from($from-sm) {
		z-index: 10;
	}

	&:last-child {
		margin-bottom: 10vw;
	}
}

.topCollapse {
	@include from($from-sm) {
		margin-top: -22.5vw;
	}
}

.topStripsMobile {
	:global {
		.strips + div,
		.strips + .clip-path-wrapper > div {
			padding-top: 0 !important;
			background: none;
		}
	}
}

.heading {
	position: relative;
	margin-bottom: 0;
	padding-bottom: 144px;

	@include to($to-lg) {
		padding-bottom: 112px;
	}
	@include to($to-md) {
		padding-bottom: 72px;
	}
	@include to($to-sm) {
		padding-bottom: 56px;
	}

	&:before {
		position: absolute;
		left: 0;
		top: 100%;
		right: 0;
		height: calc(200px + 5vw);
		background: inherit;
	}
}

:global(.is-dark-section) {
	.heading {
		color: var(--white);
		background: var(--dark);

		&:before {
			content: '';
		}
	}
}

:global(.is-white-section) {
	.heading {
		color: var(--text);
		background: var(--white);

		&:before {
			content: '';
		}
	}
}

.white-mob {
	@include to($to-sm) {
		.heading {
			color: var(--text);
			background: var(--white);
		}
	}
}

.dark-mob {
	@include to($to-sm) {
		.heading {
			color: var(--white);
			background: var(--dark);
		}
	}
}

.white-mob .feed,
.dark-mob .feed {
	margin: -150px 0 calc(12.5vh + 100px);
	bottom: -150px;
}