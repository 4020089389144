@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.section {
	position: relative;
	z-index: 2;

	@include to($to-md) {}
	@include to($to-sm) {}
}

.heading {
	position: relative;
}

.item {
	margin-top: 96px;

	@include to($to-md) {
		margin-top: 48px;
	}
	@include to($to-sm) {
		margin-top: 32px;
	}

	&:first-child {
		margin-top: 0;
	}
}