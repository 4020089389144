color-fuchsia,
.color-fuchsia {
	color: var(--fuchsia);

	&--important {
		color: var(--fuchsia) !important;
	}
}

color-accent,
.color-accent {
	color: var(--accent);

	&--important {
		color: var(--accent) !important;
	}
}