@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.module {
	display: flex;
	flex-flow: row wrap;
	// margin: 0 -32px -32px 0;
	margin: 0 0 -32px 0;
	font: 300 var(--fs-24) var(--font);
	color: var(--gray);

	@include to($to-md) {
		font: 300 var(--fs-18) var(--font);
	}
	@include to($to-sm) {
		// margin: 0 -20px -20px 0;
		margin: 0 0 -20px 0;
		font: 300 var(--fs-12) var(--font);
	}
}

a.link {
	margin: 0 32px 32px 0;
	text-decoration: none;
	color: inherit;

	@include to($to-sm) {
		margin: 0 20px 20px 0;
	}

	&:hover {
		color: var(--white);
	}
}

:global(.is-white-section) {
	.module {
		color: var(--gray);
	}

	a.link:hover {
		color: var(--accent);
	}

	a.linkActive {
		color: var(--accent);
	}
}

:global(.is-dark-section) {
	.module {
		color: var(--gray);
	}

	a.link:hover {
		color: var(--accent);
	}

	a.linkActive {
		color: var(--white);
	}
}

:global(.is-transparent-section) {
	.module {
		color: var(--gray);
	}

	a.link:hover {
		color: var(--accent);
	}

	a.linkActive {
		color: var(--white);
	}
}
