@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.section {
	position: relative;
	padding-bottom: 168px;

	@include to($to-md) {
		padding-bottom: 88px;
	}
	@include to($to-sm) {
		padding-bottom: 56px;
	}
}