@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.section {

	@include to($to-lg) {}
	@include to($to-sm) {}
}

/* * * Preamble * * */
.preamble {
	position: relative;
}

.indicator {
	position: relative;
	margin-bottom: 5vw;

	@include to($to-sm) {
		margin-bottom: 32px;
	}
}

.indicatorValue {
	width: 75%;
	padding-right: 30px;
	margin-top: -.1783em;
	font: 400 23.125vw/1.08 var(--font);
	letter-spacing: -0.02em;
	color: var(--accent);
	box-sizing: border-box;

	@include to($to-lg) {
		width: 65%;
	}
	@include to($to-sm) {
		width: 100%;
		font: 400 31.25vw/1.08 var(--font);
	}
}

.indicatorLabel {
	padding-top: 8px;
	width: 25%;
	font: 400 2.375vw/1.263 var(--font);
	letter-spacing: -0.02em;
	border-top: 1px solid var(--stroke-light);

	@include to($to-lg) {
		width: 35%;
		font: 400 3.125vw/1.263 var(--font);
	}
	@include to($to-sm) {
		width: 75%;
		padding-top: 0;
		margin-top: 16px;
		font: 400 6.25vw/1.263 var(--font);
		border-top: none;

		br {
			display: none;
		}
	}
}

/* * * Presentation * * */
.presentation {
	position: relative;
	margin-top: 0;
	min-height: 200vh;
	padding: calc(#{$offset} - 6vh) 0;
	content-visibility: auto;

	@include from($from-lg) {
		@media (min-height: 900px) {
			padding: calc(#{$offset} - 6vw) 0;
		}
	}
	@include to($to-lg) {
		padding: calc(#{$offsetLG} - 6vh) 0;
	}
	@include to($to-md) {
		padding: calc(#{$offsetMD} - 6vh) 0;
	}
	@include to($to-sm) {
		padding: calc(#{$offsetSM} - 6vh) 0;
	}
}

.presentationSticky {
	position: sticky;
	top: 0;
	padding: 6vh 0;
	height: 100vh;
	box-sizing: border-box;
	overflow: hidden;

	@include from($from-lg) {
		@media (min-height: 900px) {
			padding: 6vw 0;
		}
	}
}

.presentationContent {
	display: flex;
	flex-flow: column;
	justify-content: space-between;
	position: relative;
	min-height: 100%;

	@include to($to-sm) {
		justify-content: center;
	}
}

.presentationDesc {
	position: relative;
	z-index: 2;
	width: 37.5%;

	@include to($to-lg) {
		top: 10vh;
	}
	@include to($to-md) {
		width: 60%;
	}
	@include to($to-sm) {
		top: 0;
		width: 100%;
		margin-bottom: 48px;
	}
}

.presentationDescCaption {
	position: relative;
	margin-bottom: 8px;
	padding-bottom: 32px;
	color: var(--accent);
	border-bottom: 1px solid var(--stroke-light);
	transition: height var(--slow);
	overflow: hidden;

	@include to($to-sm) {
		padding-bottom: 16px;
		font: 400 var(--fs-24) var(--font);
	}
}

.presentationDescCaptionFrag {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	background: var(--white);
	opacity: 0;
	pointer-events: none;
	transition: opacity var(--slow);

	&:first-child {
		position: relative;
	}
}

.presentationDescCaptionFrag.isShown {
	opacity: 1;
	pointer-events: auto;
}

.presentationDescText {
	position: relative;

	@include to($to-sm) {
		font: 300 var(--fs-16) var(--font);
	}
}

.presentationDescTextFrag {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	background: var(--white);
	opacity: 0;
	pointer-events: none;
	transition: opacity var(--slow);

	&:first-child {
		position: relative;
	}
}

.presentationDescTextFrag.isShown {
	opacity: 1;
	pointer-events: auto;
}

:global(.is-dark-section) .presentationDescCaption {
	border-bottom-color: var(--stroke-dark);
}

.presentationChart {
	position: absolute;
	bottom: 0;
	right: 3.4375vw;
	width: 72.5vw;

	@include to($to-lg) {
		left: 32px;
		right: 32px;
		width: auto;
	}
	@include to($to-sm) {
		position: relative;
		left: auto;
		right: auto;
		width: 100%;
	}
}
