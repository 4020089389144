@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.cursor {
	display: block !important;
	position: fixed;
	z-index: 5;
	left: 50%;
	top: 50%;
	margin: -.5em 0 0 -.5em;
	width: 1em;
	height: 1em;
	font-size: 96px;
	color: var(--white);
	background: var(--accent);
	pointer-events: none;
	overflow: hidden;
	transform: scale(0);
	transition: background var(--trans);
	opacity: 0;

	svg {
		display: block;
		width: 100%;
		height: 100%;
		fill: currentColor;
	}

	@include to($to-md) {
		font-size: 64px;
	}
	@include to($to-sm) {
		display: none !important;
	}
}

.reverse {
	background: var(--dark);
}