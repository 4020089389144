@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.module {
	position: relative;
}

.heading {

}
