:root {
  --vh: 100vh;
  --vw: 100vw;

  --white: #fff;
  --black: #000;
  --dark: #222222;
  --graphite: #1D1D1D;
  --anthracite: #444444;
  --gray: #8D8D8D;
  --fuchsia: #B41E9C;
  --redcollar: #F51B1B;

  --bg: var(--graphite);
  --text: var(--dark);
  --accent: var(--fuchsia);
  --warning: var(--redcollar);

  --stroke-dark: #6D6D6D;
  --stroke-light: #B8B8B8;
  --stroke-card: var(--anthracite);

  --focus-visible: rgba(141, 141, 141, .5);
  --focus-outline: 0 0 0 2px var(--focus-visible);

  --font: 'Suisse Intl', sans-serif;

  --fs-24: 24px/1.332;
  --fs-18: 18px/1.221;
  --fs-16: 16px/1.5;
  --fs-14: 14px/1.5;
  --fs-12: 12px/1.54;
  --fs-10: 10px/1.54;

  --trans: .3s cubic-bezier(.215, .61, .355, 1);
  --fast: .4s cubic-bezier(.215, .61, .355, 1);
  --medium: .6s cubic-bezier(.215, .61, .355, 1);
  --slow: .8s cubic-bezier(.215, .61, .355, 1);

  --ease: cubic-bezier(.215, .61, .355, 1);

  --transIn: 400ms cubic-bezier(.55, .055, .675, .19);
  --transOut: 800ms cubic-bezier(.215, .610, .355, 1);
}