@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.filters {
	position: relative;
	margin-bottom: 100px;
	cursor: default;

	@include to($to-md) {
		margin-bottom: 56px;
	}
	@include to($to-sm) {
		margin-bottom: 42px;
	}
}

/* * * Toggle * * */
.toggle {
	display: block;
	position: relative;
	overflow: visible;
	width: 100%;
	padding-top: 14px;
	padding-right: 48px;
	font: 300 var(--fs-24) var(--font);
	color: var(--gray);
	transition:
		color var(--fast),
		border-color var(--fast);
	cursor: pointer;

	@include to($to-md) {
		font: 300 var(--fs-18) var(--font);
	}
	@include to($to-sm) {
		padding-top: 10px;
		padding-right: 32px;
		font: 300 var(--fs-14) var(--font);
	}

	svg {
		position: absolute;
		top: 18px;
		right: 0;
		width: 32px;
		height: 28px;
		fill: currentColor;

		@include to($to-md) {
			top: 12px;
			width: 24px;
			height: 24px;
		}
		@include to($to-sm) {
			top: 12px;
			width: 16px;
			height: 16px;
		}
	}

	&:before {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		height: 1px;
		background: var(--stroke-light);
		transition:
			background var(--medium),
			left var(--slow),
			right var(--slow);
		will-change: left, right;
		content: '';
	}
}

:global(.is-dark-section) {
	.toggle {
		&:before {
			background: var(--stroke-dark);
		}
	}
}

.isExpanded .toggle {
	color: var(--text);

	&:before {
		background: var(--text);
		left: -10vw;
		right: -10vw;

		@include to($to-lg) {
			left: -5vw;
			right: -5vw;
		}
		@include to($to-md) {
			left: -$gapSM;
			right: -$gapSM;
		}
		@include to($to-sm) {
			left: -$gap;
			right: -$gap;
		}
	}
}

:global(.is-dark-section) {
	.isExpanded .toggle {
		color: var(--white);

		&:before {
			background: var(--white);
		}
	}
}

.isActive .toggle {
	color: var(--text);

	&:before {
		background: var(--text);
	}
}

:global(.is-dark-section) {
	.isActive .toggle {
		color: var(--white);

		&:before {
			background: var(--white);
		}
	}
}

.toggle:hover,
.toggle:focus-visible {
	color: var(--accent);
	outline: none;
	box-shadow: none;

	&:before {
		background: var(--accent);
	}
}

:global(.is-dark-section) {
	.toggle:hover {
		color: var(--accent);

		&:before {
			background: var(--accent);
		}
	}
}

/* * * Content * * */
.container {
	padding-top: 48px;

	@include to($to-md) {
		padding-top: 32px;
	}
	@include to($to-sm) {
		padding-top: 24px;
	}
}

/* * * Groups * * */
.group {
	margin-bottom: 32px;

	@include to($to-sm) {
		margin-bottom: 24px;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

.groupCaption {
	margin-bottom: 8px;
	font: 300 var(--fs-16) var(--font);
	color: var(--gray);

	@include to($to-sm) {
		font: 300 var(--fs-12) var(--font);
	}
}

.groupLinks {
	margin: 0 -28px -1.332em 0;
	font: 300 var(--fs-24) var(--font);

	@include to($to-sm) {
		margin: 0 -16px -.5em 0;
		font: 300 var(--fs-16) var(--font);
	}
}

.groupLink {
	margin: 0 28px 1.332em 0;
	text-decoration: none;
	color: var(--gray);

	@include to($to-sm) {
		margin: 0 16px .5em 0;
	}
}

:global(.is-white-section) {
	.groupLink {
		color: var(--text) !important;
	}

	.groupLink:hover {
		color: var(--accent) !important;
	}

	.groupLinkActive {
		color: var(--accent) !important;

		&:hover {
			opacity: .75;
		}
	}
}

:global(.is-dark-section) {
	.groupLink {
		color: var(--white) !important;
	}

	.groupLink:hover {
		color: var(--accent) !important;
	}

	.groupLinkActive {
		color: var(--accent) !important;

		&:hover {
			opacity: .75;
		}
	}
}

/* * * Reset btn * * */
.resetBtn {
	color: var(--gray);
	background: none;
	border: none;
	opacity: 0;
	pointer-events: none;
	transition:
		color var(--medium),
		opacity var(--slow);

	&:hover,
	&:active {
		color: var(--accent);
	}
}

.resetBtn.isShown {
	opacity: 1;
	pointer-events: auto;
}

:global(.is-dark-section) {
	.resetBtn {
		&:hover,
		&:active {
			color: var(--white);
		}
	}
}