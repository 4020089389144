@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.video {
	position: relative;

	video,
	canvas {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
	}
}
