@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';
@import '/src/core/SimpleWrapper/SimpleWrapper.module';

$rectShiftY: 36px;
$rectMobShiftY: 20px;

.white {
	color: var(--dark);
	background: var(--white);
}
.dark {
	color: var(--white);
	background: var(--dark);
}
.graphite {
	color: var(--white);
	background: var(--graphite);
}

.outer {
	position: relative;
	padding-bottom: $offset;

	@include to($to-lg) {
		padding-bottom: $offsetLG;
	}
	//@include to($to-md) {
	//	padding-bottom: $offsetMD;
	//}
	//@include to($to-sm) {
	//	padding-bottom: $offsetSM;
	//}
}

.inner {
	position: relative;
}

.innerPadTop {
	padding-top: 12vw; // 192px;

	@include to($to-md) {}
	@include to($to-sm) {}
}

.innerPadBottom {
	padding-bottom: $offset;

	@include to($to-lg) {
		padding-bottom: $offsetLG;
	}
	@include to($to-md) {
		padding-bottom: $offsetMD;
	}
	@include to($to-sm) {
		padding-bottom: $offsetSM;
	}
}

.strip {
	position: absolute;
	left: 0;
	top: 0;
	bottom: $rectShiftY;
	width: 21%;
	will-change: transform;
	pointer-events: none;

	@include to($to-md) {
		bottom: $rectMobShiftY;
	}
	@include to($to-sm) {
		width: 50%;
	}

	&:nth-child(2) {
		left: 20%;
		bottom: #{$rectShiftY * 2};

		@include to($to-md) {
			bottom: #{$rectMobShiftY * 2};
		}
	}
	&:nth-child(3) {
		left: 40%;
		bottom: #{$rectShiftY * 3};

		@include to($to-md) {
			bottom: #{$rectMobShiftY * 3};
		}
		@include to($to-sm) {
			left: 0;
		}
	}
	&:nth-child(4) {
		left: 60%;
		bottom: #{$rectShiftY * 4};

		@include to($to-md) {
			bottom: #{$rectMobShiftY * 4};
		}
		@include to($to-sm) {
			left: 50%;
		}
	}
	&:nth-child(5) {
		left: 80%;
		bottom: #{$rectShiftY * 5};

		@include to($to-md) {
			bottom: #{$rectMobShiftY * 5};
		}
	}

	@include to($to-sm) {
		&:nth-child(1), &:nth-child(2), &:nth-child(5) {
			display: none;
		}
	}
}