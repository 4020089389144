@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';
@import '/src/core/SimpleWrapper/SimpleWrapper.module';

$rectTranslateY: -36px;
$rectMobTranslateY: -20px;

.white {
	color: var(--dark);
	background: var(--white);
}
.dark {
	color: var(--white);
	background: var(--dark);
}
.graphite {
	color: var(--white);
	background: var(--graphite);
}
.accent {
	color: var(--white);
	background: var(--accent);
}

.outer {
	position: relative;
}

.outer.withStripedTail {
	z-index: 2;
}

.inner {
	position: relative;
}

.innerPadTop {
	padding-top: 20vh; // 12vw; // 192px;

	@include to($to-sm) {
		padding-top: 56px;
	}
}

.innerPadBottom {
	padding-bottom: $offset;

	@include to($to-lg) {
		padding-bottom: $offsetLG;
	}
	@include to($to-md) {
		padding-bottom: $offsetMD;
	}
	@include to($to-sm) {
		padding-bottom: $offsetSM;
	}
}

.strip {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	margin-top: $rectTranslateY;
	width: 21%;
	will-change: transform;
	pointer-events: none;

	@include to($to-md) {
		margin-top: $rectMobTranslateY;
	}
	@include to($to-sm) {
		width: 50%;
		bottom: 25vh;
	}

	&:nth-child(2) {
		left: 20%;
		margin-top: #{$rectTranslateY * 2};

		@include to($to-md) {
			margin-top: #{$rectMobTranslateY * 2};
		}
	}
	&:nth-child(3) {
		left: 40%;
		margin-top: #{$rectTranslateY * 3};

		@include to($to-md) {
			margin-top: #{$rectMobTranslateY * 3};
		}
		@include to($to-sm) {
			left: 0;
		}
	}
	&:nth-child(4) {
		left: 60%;
		margin-top: #{$rectTranslateY * 4};

		@include to($to-md) {
			margin-top: #{$rectMobTranslateY * 4};
		}
		@include to($to-sm) {
			left: 50%;
		}
	}
	&:nth-child(5) {
		width: 20%;
		left: 80%;
		margin-top: #{$rectTranslateY * 5};

		@include to($to-md) {
			margin-top: #{$rectMobTranslateY * 5};
		}
	}

	@include to($to-sm) {
		&:nth-child(1), &:nth-child(2), &:nth-child(5) {
			display: none;
		}
	}
}

.hideStrip {
	@include from($from-md) {
		display: none;
	}
}

.startScreen .inner {
	opacity: 0;
	pointer-events: none;
}

.startScreen .strip {
	top: 100vh;
	pointer-events: auto;

	@include to($to-sm) {
		bottom: 0;
	}
}

.startScreenShown .strip {
	top: 0;
	transition: top .8s;
	transition-delay: 1s;

	&:nth-child(2) {
		transition-delay: .8s;
	}
	&:nth-child(3) {
		transition-delay: .6s;
	}
	&:nth-child(4) {
		transition-delay: .4s;
	}
	&:nth-child(5) {
		transition-delay: .2s;
	}
}

.startScreenShown .inner {
	opacity: 1;
	pointer-events: auto;
	transition: opacity .8s;
	transition-delay: 1.6s;
}

:global(.with-striped-tail) .strip {
	bottom: 10vh;
}