@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.spoiler {
	position: relative;
}

.head {
	position: relative;
	padding-right: 72px;
	padding-top: 8px;
	border-top: 1px solid var(--stroke-light);
	transition: color var(--fast);
	cursor: pointer;

	&:before {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 100%;
		height: 6px;
		background: var(--stroke-light);
		transform-origin: 50% 100%;
		transform: scaleY(.16667);
		transition:
			background var(--trans),
			transform var(--trans);
		content: '';
	}

	&:focus-visible {
		outline: none;
		box-shadow: none;
	}

	@include to($to-sm) {
		padding-right: 42px;
	}
}

:global(.is-dark-section) {
	.head {
		border-top-color: var(--stroke-dark);

		&:before {
			background-color: var(--stroke-dark);
		}
	}
}

.plus {
	position: absolute;
	top: 22px;
	right: 0;
	width: 1em;
	height: 1em;
	font-size: 32px;
	color: var(--stroke-light);
	transition:
		color var(--fast),
		transform var(--slow);
	animation: plusInactive 400ms linear forwards;

	@include to($to-sm) {
		top: 12px;
		font-size: 24px;
	}

	&:after,
	&:before {
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -1px 0 0 -.5em;
		width: 1em;
		height: 2px;
		background: currentColor;
		content: '';
	}

	&:after {
		transform: rotate(-90deg);
	}
}

:global(.is-dark-section) {
	.plus {
		color: var(--stroke-dark);
	}
}

.body {
	position: relative;
}

.inner {
	padding-top: 40px;
	color: var(--gray);

	@include to($to-md) {
		padding-top: 30px;
	}
	@include to($to-sm) {
		padding-top: 20px;
	}
}

:global(.is-mouse) {
	.head:hover,
	.head:focus-visible {
		color: var(--accent);

		&:before {
			background: var(--accent);
			transform: scaleY(1);
		}

		.plus {
			color: var(--dark);
		}
	}
}

:global(.is-mouse .is-dark-section) {
	.head:hover,
	.head:focus-visible {
		color: var(--accent);

		.plus {
			color: var(--white);
		}
	}
}

.active .head {
	color: var(--accent);

	&:before {
		background: var(--accent);
		transform: scaleY(1);
	}

	.plus {
		color: var(--dark);
		animation: plusActive 400ms linear forwards;
	}
}

:global(.is-dark-section) {
	.active .head {
		.plus {
			color: var(--white);
		}
	}
}

@keyframes plusActive {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(-135deg); }
}
@keyframes plusInactive {
	0% { transform: rotate(-135deg); }
	100% { transform: rotate(0deg); }
}

//@keyframes plusActive {
//	0% { opacity: 1; transform: rotate(0deg) scale(1); }
//	50% { opacity: 0; transform: rotate(0deg) scale(0); }
//	51% { opacity: 0; transform: rotate(-45deg) scale(0); }
//	100% { opacity: 1; transform: rotate(-45deg) scale(1); }
//}
//@keyframes plusInactive {
//	0% { opacity: 1; transform: rotate(-45deg) scale(1); }
//	50% { opacity: 0; transform: rotate(-45deg) scale(0); }
//	51% { opacity: 0; transform: rotate(0deg) scale(0); }
//	100% { opacity: 1; transform: rotate(0deg) scale(1); }
//}