@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

$cardH: 60vh;
$cardR: 64px;

.module {
	display: block;
	position: relative;
	color: var(--gray);
	background: var(--dark);
	box-sizing: border-box;
	overflow: hidden;

	&:before {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		border: 2px solid var(--stroke-card);
		box-sizing: border-box;
		content: '';

		@include to($to-sm) {
			border-width: 1px;
		}
	}
}

.pic {
	position: absolute;
	z-index: 1;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	min-height: calc(#{$cardH} + #{$cardR * .625});
	pointer-events: none;
	opacity: 0;
	transition: opacity var(--trans);

	@include to($to-sm) {
		min-height: 248px;
	}

	&:before {
		position: absolute;
		z-index: 1;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.42), rgba(0, 0, 0, 0.42));
		content: '';
	}

	img {
		object-fit: cover;
		backface-visibility: hidden;
	}
}

.content {
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-between;
	position: relative;
	z-index: 2;
	padding: 32px 10vw 32px 32px;
	height: 100%;
	box-sizing: border-box;
	color: var(--gray);
	transition: color var(--trans);
	backface-visibility: hidden;

	@include smallVH {
		padding-right: 6vw;
	}
	@include to($to-md) {
		padding-right: 80px;
	}
	@include to($to-sm) {
		padding: 16px 60px 16px 16px;
	}
}

.title {
	position: relative;
	font: 300 var(--fs-24) var(--font);

	@include from($from-hd) {
		font: 300 1.5vw/1.332 var(--font);
	}

	@include to($to-lg) {
		font: 300 20px/1.332 var(--font);
	}
	@include to($to-sm) {
		font-size: 12px;
		line-height: 1.54;
	}
}

.date {
	margin-top: 20px;
	font: 400 1.875vw/1 var(--font); // 30px
	color: var(--white);

	span {
		margin-right: .075em;
		font-size: 3.066666666666667em;

		@include smallVH {
			margin-right: .05em;
			font-size: 2.4em;
		}
	}

	@include to($to-md) {
		font-size: 24px;
	}
	@include to($to-sm) {
		font-size: 24px;
		color: var(--gray);

		span {
			font-size: 1.416666666666667em;
		}
	}
}

.arrow {
	position: absolute;
	top: 32px;
	right: 32px;
	width: 32px;
	height: 32px;
	fill: var(--stroke-dark);
	transition: fill var(--trans);

	@include to($to-sm) {
		top: 16px;
		right: 16px;
		width: 16px;
		height: 16px;
	}
}

:global(.is-mouse) {
	.module:hover,
	.module:focus-visible {
		z-index: 2;
		outline: none;
		box-shadow: none;
	}
	.module:hover .pic,
	.module:focus-visible .pic {
		opacity: 1;
	}
	.module:hover .content,
	.module:focus-visible .content {
		color: var(--white);
	}
	.module:hover .date,
	.module:focus-visible .date {
		color: var(--white);
	}
	.module:hover .arrow,
	.module:focus-visible .arrow {
		fill: var(--white);
	}
}

:global(.is-touch .splide__slide.is-active) {
	.module {
		z-index: 2;
	}
	.module .pic {
		opacity: 1;
	}
	.module .content {
		color: var(--white);
	}
	.module .date {
		color: var(--white);
	}
	.module .arrow {
		fill: var(--white);
	}
}