@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

$rectTranslateY: -36px;
$rectMobTranslateY: -20px;

.module {
	position: relative;
}

.sticky {
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-between;
	position: sticky;
	top: 0;
	padding: 56px 0 0;
	height: 100vh;
	box-sizing: border-box;
	clip-path: inset(0 0 0 0);

	@include to($to-md) {}
	@include to($to-sm) {}
}

.track {
	position: absolute;
	z-index: 3;
	top: 100vh;
	left: 0;
	right: 0;
	bottom: 140vh;
}

.content {
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	min-height: 100vh;

	@include to($to-sm) {
		justify-content: flex-end;
		padding: 8vh 0;
		box-sizing: border-box;
	}
}
.contentBox {
	max-width: 50%;
	font: 300 24px/1.332 var(--font);
	letter-spacing: -0.02em;
	text-align: center;

	@include to($to-md) {
		font: 300 16px/1.5 var(--font);
	}
	@include to($to-sm) {
		max-width: 75%;
		margin: 0 auto;
		font: 300 var(--fs-12) var(--font);
	}
}

.title {
	margin: 0 auto 6.16vh; // 56px;

	color-accent {
		color: inherit !important;
	}
	@include to($to-sm) {
		margin: 0 auto 4vh;
	}
}

.text {
	margin: 0 auto;
	max-width: 420px;
}

.link {
	margin: 11vh auto 0;

	@include to($to-md) {
		margin-top: 56px;
	}
	@include to($to-sm) {
		display: none;
	}
}

/*
 * Полоски сверху
 */
.strips {
	position: absolute;
	z-index: 5;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	pointer-events: none;
}

.strip {
	position: absolute;
	left: 0;
	top: 0;
	margin-top: $rectTranslateY;
	height: 175vh;
	width: 21%;
	background: var(--accent);
	will-change: transform;
	pointer-events: none;

	@include to($to-md) {
		margin-top: $rectMobTranslateY;
		width: 50%;
	}

	&:nth-child(2) {
		left: 20%;
		margin-top: #{$rectTranslateY * 2};

		@include to($to-md) {
			left: 50%;
			margin-top: #{$rectMobTranslateY * 2};
		}
	}
	&:nth-child(3) {
		left: 40%;
		margin-top: #{$rectTranslateY * 3};

		@include to($to-md) {
			left: 66.661%;
			margin-top: #{$rectMobTranslateY * 3};
		}
	}
	&:nth-child(4) {
		left: 60%;
		margin-top: #{$rectTranslateY * 4};
	}
	&:nth-child(5) {
		left: 80%;
		margin-top: #{$rectTranslateY * 5};
	}

	@include to($to-md) {
		&:nth-child(2) ~ & {
			display: none;
		}
	}
}
