@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.module {
	position: relative;
	z-index: 2;

	@include to($to-md) {}
	@include to($to-sm) {}
}

.heading {
	@include from($from-lg) {
		margin-bottom: 80px;
	}
}

.aside {
	margin-right: 10vw;
	height: 100%;

	@include to($to-sm) {
		margin-right: 0;
	}
}

.pictures {
	display: flex;
	flex-flow: column;
	align-items: flex-start;
	margin-bottom: -15vh;

	@include to($to-lg) {
		margin-bottom: 0;
	}
	@include to($to-sm) {
		margin-top: 13.75vw;
	}
}

.picturesGroup {
	display: inline-flex;
	position: relative;
	margin-top: 13.75vw;

	&:before {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		margin: 4vw 0 0 4vw;
		border: 1px solid var(--stroke-light);
		pointer-events: none;
		content: '';
	}

	&:first-child {
		margin-top: 0;
	}

	&:nth-child(2n+1) {
		margin-left: -10vw;

		@include to($to-lg) {
			margin-left: -2.5vw;
		}

		@include to($to-sm) {
			margin-left: 0;
		}
	}
	&:nth-child(2n+2) {
		margin-left: -1.5vw;

		@include to($to-lg) {
			margin-left: 2.5vw;
		}
		@include to($to-sm) {
			margin-left: 0;
			margin-right: 2.5vw;
			align-self: flex-end;
		}
	}
}

.picturesGroupInner {
	display: block;
	position: relative;
	overflow: hidden;
	width: 30vw;
	height: auto;

	@include to($to-sm) {
		width: 70vw;
	}

	&:before {
		display: block;
		height: 0;
		padding-top: 83.3333333333333%;
		content: '';
	}
}

.picturesGroup:nth-child(2n+1) {
	opacity: calc(1 - var(--sy));
	transform:
		translateY(calc(35vh * var(--sy))) translateZ(0);

	@include to($to-lg) {
		transform:
			translateY(calc(15vh * var(--sy))) translateZ(0);
	}
}

.picturesGroup:nth-child(2n+1):before {
	transform:
		translateX(calc(5vw * var(--mx)))
		translateY(calc(5vh * var(--my)))
		translateZ(0);
}
.picturesGroup:nth-child(2n+1) .picturesGroupInner {
	transform:
		translateX(calc(10vw * var(--mx)))
		translateY(calc(10vh * var(--my)))
		translateZ(0);
}

.picturesGroup:nth-child(2n+2) {
	opacity: calc(1 - var(--sy));
	transform:
		translateY(calc(45vh * var(--sy))) translateZ(0);

	@include to($to-lg) {
		transform:
			translateY(calc(25vh * var(--sy))) translateZ(0);
	}
}

.picturesGroup:nth-child(2n+2):before {
	transform:
		translateX(calc(3vw * var(--mx)))
		translateY(calc(3vh * var(--my)))
		translateZ(0);
}
.picturesGroup:nth-child(2n+2) .picturesGroupInner {
	&:before {
		padding-top: 60%;
	}

	transform:
		translateX(calc(6vw * var(--mx)))
		translateY(calc(6vh * var(--my)))
		translateZ(0);
}

.picturesEntity {
	display: block;
	position: absolute;
	left: -1px;
	top: -1px;
	right: -1px;
	bottom: -1px;
	clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
	transition: clip-path var(--slow);

	span, img {
		display: block !important;
		position: relative;
		z-index: 1;
		width: 100% !important;
		height: 100% !important;
		object-fit: cover;
	}

	& ~ & {
		z-index: 1;
	}
}

.picturesEntity.isShown {
	z-index: 2;
	clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
	transition: clip-path var(--slow);
}
.picturesEntity.isDown {
	z-index: 1 !important;
}

:global(.is-dark-section) {
	.picturesGroup:before {
		border-color: var(--stroke-dark);
	}
}