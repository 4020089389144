@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.module {
	display: flex;
	align-items: flex-start;
	position: relative;
	padding-top: 40px;
	padding-bottom: 40px;
	padding-right: 10vw;
	font: 300 var(--fs-16) var(--font);
	color: var(--gray);
	cursor: pointer;

	@include from($from-hd) {
		font: 300 1vw/1.5 var(--font);
	}

	@include to($to-md) {
		padding-top: 20px;
		padding-bottom: 20px;
	}

	&:first-child {
		margin-top: -40px;

		@include to($to-md) {
			margin-top: -20px;
		}
		@include to($to-sm) {
			margin-top: 0;
		}
	}

	@include to($to-md) {
		font: 300 14px/1.5 var(--font);
	}
	@include to($to-sm) {
		flex-flow: column nowrap;
		justify-content: space-between;
		padding: 40px 16px 0;
		min-height: 100%;
		font: 300 var(--fs-12) var(--font);
		box-sizing: border-box;
	}

	&:before {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		width: 1.875vw;
		background: var(--accent);
		transform-origin: 100% 50%;
		transform: scaleX(0);
		transition: transform var(--trans);
		content: '';

		@include to($to-sm) {
			content: none;
		}
	}

	:root {
		--mx: 0;
		--my: 0;
	}
}

.left {
	width: 14.286%;
	padding: 0 30px 0 0;
	font-weight: 400;
	color: var(--dark);
	box-sizing: border-box;

	@include to($to-md) {
		padding: 4px 10px 0 0;
		text-align: center;
	}
	@include to($to-sm) {
		position: absolute;
		left: 16px;
		top: 0;
		padding: 0;
		width: auto;
		font: 400 14px/1.5 var(--font);
		text-align: left;
	}

	@include from($from-sm) {
		&:after {
			content: '.';
		}
	}
}
.middle {
	width: 57.142%;
	padding-right: 10vw;
	box-sizing: border-box;

	@include to($to-lg) {
		padding-right: 7.5vw;
	}
	@include to($to-md) {
		padding-right: 30px;
	}
	@include to($to-sm) {
		position: relative;
		width: 100%;
	}
}
.right {
	width: 28.572%;
	box-sizing: border-box;

	@include to($to-sm) {
		position: relative;
		width: calc(100% + 32px);
		margin: 40px -16px 0;
	}
}

.title {
	position: relative;
	margin-bottom: 16px;
	color: var(--dark);
	transition: color var(--trans);

	@include to($to-lg) {
		font: 400 30px/1.26 $font;
	}
	@include to($to-md) {
		font: 400 20px/1.26 $font;
	}
	@include to($to-sm) {
		margin-bottom: 12px;
		margin-right: 24px;
		font: 400 24px/1.26 $font;
	}
}

.text {
	position: relative;
}

.pictureWrap {
	display: block;
	position: relative;
	margin: -3.5vw -2.5vw 0 -3.5vw;
	pointer-events: none;

	@include to($to-sm) {
		margin: 0;
	}
}

.picture {
	display: block;
	position: relative;
	backface-visibility: hidden;

	@include from($from-sm) {
		z-index: 50;
		will-change: transform;
		transform:
			translateX(calc(var(--mx) * -1px))
			translateY(calc(var(--my) * -1px))
			translateZ(0);
	}

	&:before {
		display: block;
		height: 0;
		padding-bottom: 62.5%;
		content: '';
	}

	& > span {
		left: 3.5vw !important;
		top: 3.5vw !important;
		right: 2.5vw !important;
		transition:
			left var(--trans),
			top var(--trans),
			right var(--trans);
	}

	img {
		object-fit: cover;
		filter: grayscale(100%);
		transition: filter var(--trans);
		backface-visibility: hidden;
	}

	@include to($to-sm) {
		margin: 0;

		& > span {
			left: 0 !important;
			top: 0 !important;
			right: 0 !important;
		}
	}
}

.tab {
	position: absolute;
	z-index: 4;
	top: 0;
	bottom: 0;
	right: 100%;
	width: 48px;
	padding: 8px;
	font: 400 14px/1.5 var(--font);
	text-align: center;
	color: var(--white);
	background: var(--accent);
	backface-visibility: hidden;

	@include from($from-sm) {
		display: none;
	}
}

.arrow {
	position: absolute;
	top: 0;
	right: 4vw;
	width: 2vw;
	height: 2vw;
	fill: var(--stroke-light);
	transition: fill var(--trans);

	@include to($to-sm) {
		right: 0;
		top: 8px;
		width: 16px;
		height: 16px;
		fill: var(--dark);
	}
}

.module:focus-visible {
	outline: none;
	box-shadow: none;
}

.module:hover,
.module:focus-visible {
	&:before {
		transform: none;
	}
}

.module:hover .title,
.module:focus-visible .title {
	color: var(--accent);
}

.module:hover .picture,
.module:focus-visible .picture {
	& > span {
		left: 0 !important;
		top: 0 !important;
		right: 0 !important;
	}

	img {
		filter: grayscale(0);
	}
}

.module:hover .arrow,
.module:focus-visible .arrow {
	fill: var(--dark);

	@include to($to-sm) {
		fill: var(--accent);
	}
}

:global(.is-dark-section) {
	.left, .title {
		color: var(--white);
	}

	.arrow {
		fill: var(--stroke-dark);
	}

	.module:hover .arrow,
	.module:focus-visible .arrow {
		fill: var(--white);

		@include to($to-sm) {
			fill: var(--accent);
		}
	}
}

/*
 * Появление карточке по скроллу на декстопе
 */
.module {
	@include from($from-sm) {
		opacity: 0;
		transform: translateY(75%);
		transition:
			opacity var(--slow),
			transform var(--slow);

		picture {
			clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
			transition: clip-path var(--slow);
		}

		&.visible {
			opacity: 1;
			transform: none;

			picture {
				clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
			}
		}
	}
}