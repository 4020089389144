.pad-xs-top { padding-top: $gap; }
.pad-xs-bottom { padding-bottom: $gap; }

.off-xs-top { margin-top: $gap; }
.off-xs-bottom { margin-bottom: $gap; }

[class*="off-"]:first-child { margin-top: 0; }
[class*="off-"]:last-child { margin-bottom: 0; }

.indent-margin {
	@include from($from-md) {
		margin-left: 5vw;
	}
	@include from($from-lg) {
		margin-left: 10vw;
	}
}

.indent-text {
	@include from($from-md) {
		text-indent: 5vw;
	}
	@include from($from-lg) {
		text-indent: 10vw;
	}
}
