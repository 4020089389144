.background {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  //width: 100% !important;
  //height: 100% !important;
  backface-visibility: hidden;
  pointer-events: none;
  background: #000;
  transform: translateZ(0px);
}

.canvas {
  position: absolute;
  left: 0;
  top: 0;
  width: 100% !important;
  height: 100% !important;
  opacity: .3;
  transform: translateZ(0px);
}