@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.feed {
	position: relative;
	transition:
		opacity var(--medium),
		transform var(--medium);
}

.feed.isLoading {
	opacity: 0;
	transform: translateY(10%);
}

.empty {
	padding-top: 16px;
	font: 300 var(--fs-16) var(--font);
	color: var(--gray);
	border-top: 1px solid var(--stroke-light);

	@include from($from-hd) {
		font: 300 1vw/1.5 var(--font);
	}

	@include to($to-md) {
		font: 300 var(--fs-14) var(--font);
	}
	@include to($to-sm) {
		font: 300 var(--fs-12) var(--font);
	}
}

:global(.is-dark-section) .empty {
	border-top-color: var(--stroke-dark);
}