//@import '/src/assets/styles/config/variables';
//@import '/src/assets/styles/config/mixins';

.page, #__next {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100%;
  min-width: var(--min-width);
  color: var(--white);
  background-color: var(--graphite);
  box-sizing: border-box;
}

.page__main {
  display: block;
  padding: 0;
  min-height: 100vh;
  font: 400 var(--fs-16) var(--font);
  box-sizing: border-box;
}

.page__content {
  position: relative;
  z-index: 2;
}
