@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.response {
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	pointer-events: none;

	:global {
		.h1, .h2, .h3 {
			margin-bottom: 32px;

			@include to($to-sm) {
				margin-bottom: 24px;
			}
		}
	}
}

:global(.is-white-section) {
	.response {
		background: var(--white);
	}
}

:global(.is-dark-section) {
	.response {
		background: var(--dark);
	}
}

.title {
	margin-bottom: 32px;

	@include to($to-sm) {
		margin-bottom: 24px;
	}
}

.isShown {
	pointer-events: auto;
	animation: fadeIn var(--slow) forwards;
}

@keyframes fadeIn {
	from { opacity: 0; }
	to { opacity: 1; }
}

@keyframes fadeOut {
	from { opacity: 1; }
	to { opacity: 0; }
}
