@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

$cardHeight: 460px;
$cardHeightMob: 248px;

$cardDisplacement: 64px;
$cardDisplacementSM: 48px;
$cardDisplacementMob: 32px;

$cardShift: 40px;
$cardShiftMob: 24px;

$cardWidthTab: 320px;
$cardWidthMob: 256px;

.feed {
	position: relative;

	:global {
		.splide__list {
			display: flex;
			flex-flow: row nowrap;
			align-items: flex-end;
			height: $cardHeight;

			@include to($to-sm) {
				height: $cardHeightMob;
			}
		}

		.splide__slide {
			margin-left: -2px;
			width: 40vw;
			min-width: 420px;
			height: 100%;
			transition: height var(--trans);

			@include to($to-lg) {
				min-width: $cardWidthTab;
			}
			@include to($to-sm) {
				margin-left: -1px;
				width: 80vw;
				min-width: $cardWidthMob;
			}

			&:nth-child(3n + 1) {
				height: calc(100% - #{$cardDisplacement * .5} - #{$cardShift});

				@include smallVH {
					height: calc(100% - #{$cardDisplacementSM * .5} - #{$cardShift});
				}

				@include to($to-sm) {
					height: calc(100% - #{$cardDisplacementMob * .5} - #{$cardShiftMob});
				}
			}
			&:nth-child(3n + 2) {
				height: calc(100% - #{$cardShift});

				@include smallVH {
					height: calc(100% - #{$cardShift});
				}

				@include to($to-sm) {
					height: calc(100% - #{$cardShiftMob});
				}
			}
			&:nth-child(3n + 3) {
				height: calc(100% - #{$cardDisplacement} - #{$cardShift});

				@include smallVH {
					height: calc(100% - #{$cardDisplacementSM} - #{$cardShift});
				}

				@include to($to-sm) {
					height: calc(100% - #{$cardDisplacementMob} - #{$cardShiftMob});
				}
			}
		}
	}
}

:global(.is-mouse) {
	.feed {
		:global {
			.splide__slide:hover,
			.splide__slide:focus-visible {
				height: calc(100%);
			}
		}
	}
}

:global(.is-touch) {
	.feed {
		:global {
			.splide__slide.is-active {
				height: calc(100%);
			}
		}
	}
}

.card {
	flex-shrink: 0;
	width: 100%;
	height: 100%;
	margin-left: -1px;
}

/* * * Drag cursor * * */
.feed .cursor {
	opacity: 0;
	transform: scale(0);
	clip-path: inset(0 0 0 0);
	transition:
		opacity var(--trans),
		transform var(--trans),
		clip-path var(--trans),
		background var(--trans);
}

.feed:hover .cursor {
	opacity: 1;
	transform: scale(1);
	clip-path: inset(0 0 0 0);
}

.feed:active .cursor {
	clip-path: inset(12px 12px 12px 12px);
}

:global(.is-touch) .cursor {
	display: none;
}