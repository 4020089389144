@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.fileInput {
  display: block;
  position: relative;
  font: 300 var(--fs-24) var(--font);
  transition: color var(--fast);
  cursor: pointer;

  @include to($to-lg) {
    font: 300 var(--fs-16) var(--font);
  }
  @include to($to-sm) {
    font: 300 var(--fs-12) var(--font);
  }

  &__field {
    display: flex;
    flex-flow: row nowrap;
    align-items: baseline;
    justify-content: space-between;
    padding: 16px 0;
    width: 100%;
    height: 64px;
    border: solid var(--stroke-light);
    border-width: 0 0 1px;
    box-sizing: border-box;
    transition: border-color var(--fast);

    @include to($to-lg) {
      height: 56px;
    }
    @include to($to-sm) {
      padding: 11px 0;
      height: 40px;
    }
  }

  &__text {
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__placeholder {
    position: absolute;
    left: 0;
    top: 16px;
    width: 80%;
    transform-origin: 0 0;
    transition:
      top var(--trans),
      transform var(--trans);
    color: var(--gray);

    @include to($to-sm) {
      top: 11px;
    }

    &.isActive {
      top: -16px;
      transform: scale(.75);
      opacity: 1;

      @include to($to-sm) {
        top: -11px;
        transform: scale(1);
      }
    }
  }

  &__ext {
    flex-shrink: 0;
    margin: 0 0 0 16px;
    text-transform: uppercase;
    color: var(--gray);
  }

  &__restriction {
    display: block;
    margin-top: 4px;
    font: 300 var(--fs-16) var(--font);
    color: var(--gray);
    transition: color var(--fast);

    @include to($to-lg) {
      font: 300 var(--fs-12) var(--font);
    }
  }

  &:hover &__field {
    border-color: var(--accent);
  }

  &:hover &__restriction {
    color: var(--accent);
  }

  input:focus ~ &__field,
  input:focus-visible ~ &__field {
    border-color: var(--accent);
  }

  input:focus ~ &__restriction,
  input:focus-visible ~ &__restriction {
    color: var(--accent);
  }
}