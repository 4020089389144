@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.module {
	position: relative;
	min-height: 100vh;

	@include to($to-md) {}
	@include to($to-sm) {}
}

.sticky {
	position: sticky;
	top: 0;
	height: 100vh;
	overflow: hidden;
}

.content {
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	position: absolute;
	z-index: 10;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	padding: 5vh 0;

	@include to($to-md) {}
	@include to($to-sm) {
		padding: 10vh 0;
	}

	:root {
		--odometerA: 0;
		--odometerB: 0;
		--odometerC: 0;
		--odometerD: 0;
	}

	@include to($to-sm) {
		& > div {
			display: flex;
			flex-flow: column nowrap;
			justify-content: space-between;
			height: 100%;
		}
	}
}

.heading {
	position: relative;
	opacity: 0;
	transform: translateY(20%) translateZ(0);
	transition:
		opacity var(--medium),
		transform var(--medium);
}

.progress {
	z-index: 7;
}

/* * * Slider * * */
.layout {
	display: flex;
	flex-flow: row nowrap;
	position: relative;

	@include to($to-sm) {
		flex-flow: column nowrap;
	}
}

.left {
	width: 75%;

	@include to($to-sm) {
		width: 100%;
	}
}

.right {
	position: relative;
	width: 25%;
	margin-top: 1.375vw;
	opacity: 0;
	transition: opacity var(--medium);

	&:before {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		border-top: 1px solid;
		opacity: 0;
		transform-origin: 0 0;
		transform: scaleX(.25);
		transition: all var(--medium);
		content: '';

		@include to($to-sm) {
			content: none;
		}
	}

	@include to($to-sm) {
		width: 100%;
		padding-bottom: 15vh;
		margin-top: 24px;
	}
}

/* * * Odometer * * */
.odometerWrap {
	overflow: hidden;
	height: .8em;
	margin: 0;
	font: 400 23.125vw/1 var(--font);
	letter-spacing: -0.04em;
	text-align: center;
	mask-image: linear-gradient(180deg, transparent 0%, black 4%, black 96%, transparent 100%);
	mask-mode: alpha;

	@include smallVH {
		font: 400 46.25vh/1 var(--font);
	}

	@include to($to-sm) {
		font: 400 40vw/1 var(--font);
	}
}

.odometer {
	display: flex;
	margin: -.1em 0 -.1em -.0297em;
	height: 1em;
}

.odometerC {
	display: flex;
	flex-flow: column nowrap;
	position: relative;

	&:nth-child(1) {
		transform: translateY(var(--odometerA)) translateZ(0);
	}
	&:nth-child(2) {
		transform: translateY(var(--odometerB)) translateZ(0);
	}
	&:nth-child(3) {
		transform: translateY(var(--odometerC));
	}
	&:nth-child(4) {
		transform: translateY(var(--odometerD));
	}
}

.odometerD {
	display: block;
	position: relative;
	height: 1em;
}

.odometerAccent {
	color: var(--accent);
}

.odometerC:nth-child(2) .odometerD {
	margin-top: .3em;
}
.odometerC:nth-child(3) .odometerD {
	margin-top: .8em;
}
.odometerC:nth-child(4) .odometerD {
	margin-top: 1.3em;
}

/* * * Counter * * */
.counter {
	display: flex;
	position: absolute;
	left: 0;
	right: 0;
	bottom: .5vw;
	height: 1em;
	font: 400 2.375vw/1 var(--font);
	letter-spacing: -0.02em;
	color: var(--white);
	overflow: hidden;
	opacity: 0;
	transition: opacity var(--medium);

	span {
		margin-left: .25em;
	}

	@include to($to-sm) {
		bottom: 0;
		font: 400 24px/1.3 var(--font);
	}
}

.counterTrack {
	display: flex;
	flex-flow: column nowrap;
	position: relative;
	height: 100%;
	text-align: center;
	transform: translateY(var(--odometerC));

	div {
		height: 1em;
		box-sizing: border-box;
	}

	div ~ div {
		margin-top: .8em;
		height: 1em;
	}
}

/* * * Texts * * */
.text {
	font: 400 2.375vw/1.3 var(--font); // fs: 38px
	letter-spacing: -0.02em;

	@include to($to-sm) {
		font: 400 24px/1.3 var(--font);
	}
}

.textHelper {
	height: 0;
	overflow-x: scroll;
	white-space: nowrap;
	opacity: 0;
	pointer-events: none;
}

.textLine {
	margin-top: 8px;
	height: 1.3em;
	overflow: hidden;
	mask-image: linear-gradient(180deg, transparent 0%, black 20%, black 80%, transparent 100%);
	mask-mode: alpha;
}

.text .textTrack {
	display: flex;
	flex-flow: column nowrap;
	position: relative;
	height: 100%;
	transform: translateY(var(--odometerA));

	div {
		height: 1.3em;
		box-sizing: border-box;
	}
}

/* * * Activate * * */
.isActive .heading {
	opacity: 1;
	transform: none;
}

.isActive .right {
	opacity: 1;
}

.isActive .right:before {
	opacity: 1;
	transform: none;
}

.isActive .counter {
	opacity: .3;
}

/* * * Mobile slides * * */
.mobSlides {
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	//position: absolute;
	//left: 0;
	//top: 0;
	//right: 0;
	//bottom: 0;
	//padding-top: 12vh;
	//padding-bottom: 12vh;
}

.mobSlide {
	position: relative;
	font: 400 16px/1.146875 var(--font);
	letter-spacing: -0.02em;
	border-top: 1px solid;
	opacity: 0;
	transition: opacity .6s;
}

.mobSlide ~ .mobSlide {
	margin-top: 56px;
}

.mobSlideOdometer {
	display: flex;
	font: 400 64px/1.15625 var(--font);

	div {
		color: inherit !important;
	}
}

.mobSlideLabel {
	display: block;
	position: relative;
	margin-top: .5em;
	overflow: hidden;
}

.mobSlideLabelInner {
	display: block;
	position: relative;
	transform: translateY(110%);
	transition: transform .6s;
}

.mobSlideActive {
	opacity: 1;
}
.mobSlideActive .mobSlideLabelInner {
	transform: none;
}
