@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.section {
	display: flex;
	flex-flow: column nowrap;
	position: relative;
	min-height: 100vh;
}

.panel {
	padding: 56px 0;
	background: var(--dark);

	@include to($to-md) {
		padding: 48px 0;
	}
	@include to($to-sm) {
		padding: 32px 0;
	}
}

.panelFlow {
	justify-content: space-between;
	margin: 0 0 -5vw;

	@include to($to-md) {
		margin: 0 0 -48px;
	}
	@include to($to-sm) {
		margin: 0 0 -32px;
	}
}

.panelCard {
	display: block;
	position: relative;
	width: 26%;
	margin: 0 0 5vw;
	text-align: center;
	transition: color var(--fast);
	cursor: pointer;

	&.onlyTwo {
		@include from($from-md) {
			width: 46%;
		}
	}

	@include to($to-md) {
		width: 48%;
		margin: 0 0 48px;
	}
	@include to($to-sm) {
		width: 100%;
		margin: 0 0 32px;
		padding-top: 8px;
		text-align: left;
		border-top: 1px solid var(--stroke-dark);

		&:before {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 100%;
			height: 6px;
			background: transparent;
			transform-origin: 50% 100%;
			transform: scaleY(0);
			transition:
				background var(--trans),
				transform var(--trans);
			content: '';
		}
	}

	&:hover,
	&.isActive {
		color: var(--accent);

		&:before {
			transform: none;
			background-color: var(--accent);
		}
	}
}

.panelCardTitle {
	margin-bottom: 24px;

	@include to($to-md) {
		margin-bottom: 16px;
	}
	@include to($to-sm) {
		margin-bottom: 16px;
	}
}

.map {
	flex: 1 0 auto;
	background: var(--graphite);
}