@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.section {
	position: relative;
	margin-top: 32px;
	border-top: 1px solid var(--stroke-light);

	@include to($to-sm) {
		margin-top: 24px;
		border-top: none;
	}
}

:global(.is-dark-section) .section {
	border-top-color: var(--stroke-dark);
}

.sectionLeft {
	width: 28.572%;
	padding-right: 20px;
	box-sizing: border-box;

	@include to($to-sm) {
		width: 100%;
	}
}

.sectionRight {
	margin-top: -1px;
	width: 71.428%;

	@include to($to-sm) {
		width: 100%;
	}
}

.sectionLeftCell {
	display: inline-block;
	text-align: center;
}

.sectionNum {
	padding-top: 4px;
	color: var(--gray);

	@include to($to-sm) {
		padding-top: 14px;
	}
}

.sectionSublabel {
	font: 300 var(--fs-16) var(--font);
	color: var(--gray);
}

.sectionCaption {
	padding-top: 22px;
	font: 300 var(--fs-24) var(--font);
	text-align: left;
	color: var(--gray);

	@include to($to-md) {
		font: 300 var(--fs-18) var(--font);
	}
	@include to($to-sm) {
		padding-top: 0;
		margin: 0 0 16px;
		font: 300 var(--fs-14) var(--font);
	}
}

.item {
	display: block;
	position: relative;
	padding: 16px 48px 56px 0;
	text-decoration: none;
	color: inherit;
	border-top: 1px solid var(--stroke-light);
	transition: color var(--fast);

	:global(.is-dark-section) & {
		border-top-color: var(--stroke-dark);
	}

	@include from($from-sm) {
		&:first-child,
		:global(.is-dark-section) &:first-child {
			border-top-color: transparent;
		}
	}
	@include to($to-sm) {
		padding: 16px 32px 24px 0;
	}

	&:before {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 100%;
		height: 6px;
		background: transparent;
		transform-origin: 50% 100%;
		transform: scaleY(0);
		transition:
			background var(--trans),
			transform var(--trans);
		content: '';
	}
}

.caption {
	position: relative;

	@include to($to-sm) {
		font: 400 18px/1.24 var(--font);
	}
}

.arrow {
	position: absolute;
	top: 16px;
	right: 0;
	width: 32px;
	height: 32px;
	fill: var(--stroke-light);
	opacity: 0;
	transform: translate(-5px, 5px);
	transition:
		fill var(--fast),
		opacity var(--fast),
		transform var(--fast);

	@include to($to-md) {
		width: 24px;
		height: 24px;
		opacity: 1;
		transform: none;
	}
	@include to($to-sm) {
		top: 20px;
		width: 16px;
		height: 16px;
	}
}

:global(.is-dark-section) .arrow {
	fill: var(--stroke-dark);
}

/* * * Item hover * * */
.item:hover {
	color: var(--accent);
}

.item:hover:before {
	transform: none;
	background-color: var(--accent);
}

.item:hover .arrow {
	opacity: 1;
	transform: none;
	fill: var(--dark);
}

:global(.is-dark-section) {
	.item:hover .arrow {
		fill: var(--white);
	}
}

/* * * Animation * * */
.section {
	opacity: 0;
	transform: translateY(50%);
	transition:
		opacity var(--slow),
		transform var(--slow);
}

.section.isVisible {
	opacity: 1;
	transform: none;
}
