@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.dark {
	color: var(--white);
	background: var(--dark);
}
.white {
	color: var(--dark);
	background: var(--white);
}

.intro {
	position: sticky;
	top: 0;
	min-height: 644px;
	padding: 156px 0 240px;
	box-sizing: border-box;

	@include to($to-md) {
		min-height: 400px;
		padding: 124px 0 80px;
	}
}

.title {
	position: relative;
	opacity: 0;

	@include to($to-md) {
		font: 400 48px/1.15625 $font;
	}
	@include to($to-sm) {
		font: 400 32px/1.15625 $font;
	}

	:global {
		.node {
			display: inline-block;
		}

		word {
			display: inline-block;
			margin-bottom: -.195652173913043em;
			white-space: nowrap;
			overflow: hidden;
		}

		letter {
			display: inline-block;
			position: relative;
			top: -.0125em;
			opacity: 0;
			transform: translate3d(0, 120%, 0);
		}
	}
}

.introSimple {
	position: relative;
	z-index: 1;
	min-height: 0;
	padding-bottom: 0;

	& + :global(.page__content) {
		z-index: auto;
	}
}

.introSimple .title {
	transform: translateY(50%);
	opacity: 0;
}

:global(.is-proceed) .title {
	transform: translateY(0);
	opacity: 1;
	transition:
		opacity var(--slow),
		transform var(--slow);

	letter {
		opacity: 1;
		transform: translate3d(0, 0, 0);
		transition:
			opacity var(--medium),
			transform var(--medium);
	}
}

.shape {
	position: absolute;
	top: 46%;
	right: .32em;
	margin-top: -.5em;
	width: 1em;
	height: 1em;
	font-size: 28vw;
	opacity: 0;
	transform: scale(0);
	transition:
		opacity var(--slow),
		transform var(--slow);
	pointer-events: none;

	@include to($to-lg) {
		top: 46%;
		right: 10%;
		margin-top: -160px;
		font-size: 320px;
	}
	@include to($to-sm) {
		display: none;
	}

	svg {
		display: block;
		position: relative;
		width: 98%;
		height: 98%;
		margin: 1%;
		fill: none;
		stroke: var(--stroke-dark);
		stroke-width: .000064em;
		animation: rotation 32s linear infinite;
	}
}

.intro.complete .shape {
	opacity: 1;
	transform: none;
}

@keyframes rotation {
	from { transform: rotate(143deg); }
	to { transform: rotate(503deg); }
}