@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.post {
	display: flex;
	align-items: flex-start;
	position: relative;
	padding-top: 16px;
	margin-bottom: 88px;
	font: 300 var(--fs-24) var(--font);
	border-top: 1px solid var(--stroke-light);
	color: var(--gray);

	@include to($to-md) {
		margin-bottom: 48px;
		font: 300 var(--fs-16) var(--font);
	}
	@include to($to-sm) {
		display: block;
		margin-bottom: 32px;
		font: 300 var(--fs-12) var(--font);
	}

	&:last-child {
		margin-bottom: 0;
	}

	&:before {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 100%;
		height: 6px;
		background: var(--stroke-light);
		transform-origin: 50% 100%;
		transform: scaleY(.16667);
		transition:
			background var(--trans),
			transform var(--trans);
		content: '';
	}
}

.left {
	flex-shrink: 0;
	width: 28.571%;
	padding-right: 30px;
	box-sizing: border-box;

	@include to($to-sm) {
		width: 100%;
		padding-right: 0;
	}
}

.main {
	position: relative;
	width: 71.429%;
	padding-right: 10vw;
	box-sizing: border-box;

	@include to($to-sm) {
		position: static;
		width: 100%;
		padding-right: 0;
		margin-top: 16px;
	}
}

.date {
	margin-top: 20px;
	font: 400 1.875vw/1 var(--font); // 30px
	color: var(--accent);

	span {
		margin-right: .075em;
		font-size: 2.132em;
	}

	@include to($to-md) {
		font-size: 24px;

		span {
			font-size: 1.417em;
		}
	}
}

.arrow {
	position: absolute;
	top: 0;
	right: 0;
	width: 32px;
	height: 32px;
	fill: var(--stroke-light);
	transition: fill var(--trans);

	@include to($to-md) {
		width: 24px;
		height: 24px;
	}
	@include to($to-sm) {
		top: 16px;
		width: 16px;
		height: 16px;
	}
}

.post:hover,
.post:focus-visible {
	outline: none;
	box-shadow: none;

	&:before {
		background: var(--accent);
		transform: scaleY(1);
	}
}

.post:hover .arrow,
.post:focus-visible .arrow {
	fill: var(--dark);

	@include to($to-sm) {
		fill: var(--accent);
	}
}