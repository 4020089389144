@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

$duration: .8s;

$rectTranslateY: -36px;
$rectMobTranslateY: -20px;

.popup {
	position: fixed;
	z-index: 150;
	left: 0;
	top: 0;
	right: 0;
	bottom: -100px;
	padding-bottom: 100px;
	width: 100%;
	font: 400 16px/1.5 'Fact', sans-serif;
	pointer-events: none;
	//overflow-x: hidden;
	//overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	backface-visibility: hidden;

	:root {
		--sb: 0;
	}

	&__scroll {
		position: relative;
		height: 100%;
		overflow-x: hidden;
		overflow-y: scroll;
	}

	&__capsule {
		display: table;
		position: relative;
		width: 100%;
		height: 100%;
		border-collapse: separate;
		border-spacing: 0;
		table-layout: fixed;
		// overflow: hidden;
	}

	&__inner {
		display: table-cell;
		vertical-align: middle;
		padding-top: 144px;
		padding-bottom: 144px;
		text-align: center;

		@include to($to-lg) {
			padding-top: 112px;
			padding-bottom: 112px;
		}
		@include to($to-md) {
			padding-top: 72px;
			padding-bottom: 72px;
		}
		@include to($to-sm) {
			padding-top: 56px;
			padding-bottom: 56px;
		}

		.isFullScreen & {
			vertical-align: top;
		}
	}

	&__perspective {
		position: relative;
		z-index: 10;
		width: 100%;
		max-width: 545px;
		margin-left: auto;
		margin-right: auto;
		text-align: left;
	}

	&__overlay {
		position: fixed;
		left: 0;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, .75);
		transform: translateZ(-1000px);
		overflow: hidden;

		&.strips {
			background: none;
		}
		&.solid {
			background: var(--white);
		}
	}

	&__box {
		position: relative;
		z-index: 1300;
		margin-left: auto;
		margin-right: auto;
		color: var(--text);
		background: var(--white);
		box-sizing: content-box;
		border-radius: 0;

		&.withStrips,
		&.withOverlay {
			background: none;
		}
	}

	&__close {
		top: 21px;
		right: calc(22px + var(--sb));

		&.isSticky {
			position: fixed;
		}
	}
}

//.popup.isShown {
//	pointer-events: auto;
//
//	.popup__box {
//		pointer-events: auto;
//		animation: fadeIn var(--medium) forwards;
//
//		//&.withOverlay {
//		//	animation: fadeInDelay 1.2s var(--ease) forwards;
//		//}
//	}
//
//	.popup__solid {
//		pointer-events: auto;
//		animation: fadeIn var(--medium) forwards;
//	}
//}
//
//@keyframes fadeIn {
//	from { opacity: 0; }
//	to { opacity: 1; }
//}
//
//@keyframes fadeOut {
//	from { opacity: 1; }
//	to { opacity: 0; }
//}

/* * * To interactive * * */
.popup.isShown {
	pointer-events: auto;

	.popup__box {
		pointer-events: auto;
	}
	.popup__overlay {
		pointer-events: auto;
	}
}

/* * * Close btn animation * * */
.popup.waiting {
	.popup__close {
		opacity: 0;
	}
}

.popup.enter {
	.popup__close {
		opacity: 1;
		transition: opacity .8s var(--ease);
	}

	&.strips {
		.popup__close {
			transition: opacity 1.2s var(--ease);
			transition-delay: $duration;
		}
	}
}

.popup.exit {
	.popup__close {
		opacity: 0;
		transition: opacity var(--medium);
		transition-delay: 0s;
	}
}

/* * * Box animation * * */
.popup.waiting {
	.popup__box {
		opacity: 0;
		transform: translateY(10vh);
	}
}

.popup.enter {
	.popup__box {
		opacity: 1;
		transform: translateY(0);
		transition:
			opacity .8s var(--ease),
			transform var(--medium);
	}

	&.strips {
		.popup__box {
			transition:
				opacity 1.2s var(--ease),
				transform var(--medium);
			transition-delay: $duration;
		}
	}
}

.popup.exit {
	.popup__box {
		opacity: 0;
		transform: translateY(0);
		transition:
			opacity var(--medium),
			transform var(--medium);
		transition-delay: 0s;
	}
}

/* * * Overlay solid animation * * */
.popup.waiting {
	.popup__overlay.solid {
		opacity: 0;
	}
}

.popup.enter {
	.popup__overlay.solid {
		opacity: 1;
		transition: opacity var(--medium);
	}
}

.popup.exit {
	.popup__overlay.solid {
		opacity: 0;
		transition: opacity var(--medium);
	}
}

/* * * Overlay strips animation * * */
.popup__strip {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	width: 21%;
	will-change: transform;
	background: var(--white);

	@for $i from 1 through 5 {
		&:nth-child(#{$i}) {
			left: #{20% * ($i - 1)};
			margin-top: #{$rectTranslateY * ($i - 1)};
		}
	}

	@include to($to-sm) {
		width: 51%;

		&:nth-child(2) {
			left: 50%;
		}

		&:nth-child(3), &:nth-child(4), &:nth-child(5) {
			display: none;
		}
	}
}

.popup.waiting .popup__strip {
	transform: translateY(100%);
}

.popup.enter .popup__strip {
	transform: translateY(0);
	transition: transform #{$duration} var(--ease);

	@for $i from 1 through 5 {
		&:nth-child(#{$i}) {
			transition-delay: #{120 * (5 - $i)}ms;
		}
	}
}

.popup.exit .popup__strip {
	transform: translateY(-100%);
	transition: transform #{$duration} var(--ease);

	@for $i from 1 through 5 {
		&:nth-child(#{$i}) {
			transition-delay: #{120 * (5 - $i)}ms;
		}
	}
}