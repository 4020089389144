@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.module {
	position: relative;
	overflow: hidden;
}

.head {
	margin-bottom: calc(56px - 20px);
}

.title {
	margin-bottom: 20px;
}

.module .card > * {
	transition: opacity var(--medium);
}
.module.loading .card > * {
	opacity: 0;
}
