@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.heading {
	margin-bottom: 120px;

	@include to($to-lg) {
		margin-bottom: 112px;
	}
	@include to($to-md) {
		margin-bottom: 48px;
	}
	@include to($to-sm) {
		margin-bottom: 24px;
	}
}

.heading.regular {
	margin-bottom: 144px;

	@include to($to-lg) {
		margin-bottom: 112px;
	}
	@include to($to-md) {
		margin-bottom: 72px;
	}
	@include to($to-sm) {
		margin-bottom: 56px;
	}
}

.heading.magazine {
	margin-bottom: 104px;

	@include to($to-lg) {
		margin-bottom: 64px;
	}
	@include to($to-md) {
		margin-bottom: 48px;
	}
	@include to($to-sm) {
		margin-bottom: 24px;
	}
}

.title {
	margin-bottom: 48px;

	@include to($to-sm) {
		margin-bottom: 20px;
	}
}