@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

$topPad: 10vw;
$topPadLG: 15vh;
$topPadMD: 15vh;
$topPadSM: 12vw;

$endPoint: calc(#{$offset} + 19vw);
$endPointLG: calc(#{$offsetLG} + 19vw);
$endPointMD: calc(#{$offsetMD} + 24vw);
$endPointSM: calc(#{$offsetSM} + 40vw);

.section {
	padding: 0;

	@include to($to-md) {}
	@include to($to-sm) {}
}

.track {
	position: absolute;
	z-index: 4;
	left: 0;
	right: 0;
	top: 0;
	bottom: $endPoint;
	height: auto;
	min-height: 100vh;
	content-visibility: auto;
	pointer-events: none;

	@include to($to-lg) {
		bottom: $endPointLG;
	}
	@include to($to-md) {
		bottom: $endPointMD;
	}
	@include to($to-sm) {
		bottom: $endPointSM;
		min-height: 50vh;
	}
}

.sticky {
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	position: sticky;
	z-index: 6;
	top: 50%;
	box-sizing: border-box;
	pointer-events: none;
}

.sentence {
	position: relative;
	padding-left: 10vw;
	font: 400 5.5vw/#{1.068} var(--font);
	letter-spacing: -0.02em;

	@include to($to-md) {
		padding-left: 66px;
	}
	@include to($to-sm) {
		padding-left: 57px;
		font: 400 24px/#{1.068} var(--font);
	}

	div {
		display: inline;
		pointer-events: auto;
	}
}

.sentenceIcon {
	position: absolute;
	left: 0;
	top: .18182em;
	width: 74px;
	height: 52px;
	fill: var(--stroke-light);

	@include to($to-md) {
		width: 46px;
		height: 32px;
	}
	@include to($to-sm) {
		width: 37px;
		height: 26px;
	}
}

.mediaFeed {
	display: flex;
	flex-flow: column nowrap;
	position: relative;
	z-index: 2;
	min-height: 100vh;
	padding-top: $topPad;
	padding-bottom: $offset;
	box-sizing: border-box;

	@include to($to-lg) {
		padding-top: $topPadLG;
		padding-bottom: $offsetLG;
	}
	@include to($to-md) {
		padding-top: $topPadMD;
		padding-bottom: $offsetMD;
	}
	@include to($to-sm) {
		padding-top: $topPadSM;
		padding-bottom: $offsetSM;
	}
}

.mediaItem {
	display: block;
	position: relative;

	&:nth-child(5n+1) {
		margin-top: 12vw;
		margin-left: 18.25vw;
		width: 30vw;

		@include to($to-md) {
			margin-top: 12vw;
			margin-left: 6vw;
			width: 40vw;
		}
		@include to($to-sm) {
			width: 50vw;
		}

		transform:
			translateX(calc(6vw * var(--mx)))
			translateY(calc(6vh * var(--my)))
			translateZ(0);
	}
	&:nth-child(5n+2) {
		margin-top: 3vw;
		margin-left: 74.5vw;
		width: 20vw;

		@include to($to-md) {
			margin-top: 5vw;
			margin-left: 65vw;
			width: 25vw;
		}
		@include to($to-sm) {
			margin-top: 15vw;
			margin-left: 55vw;
			width: 35vw;
		}

		transform:
			translateX(calc(4vw * var(--mx)))
			translateY(calc(4vh * var(--my)))
			translateZ(0);
	}
	&:nth-child(5n+3) {
		margin-top: -3.125vw;
		margin-left: 64.5vw;
		width: 20vw;

		@include to($to-md) {
			margin-left: 50vw;
			width: 30vw;
		}
		@include to($to-sm) {
			margin-top: 30vw;
			margin-left: 47vw;
			width: 35vw;
		}

		transform:
			translateX(calc(2vw * var(--mx)))
			translateY(calc(2vh * var(--my)))
			translateZ(0);
	}
	&:nth-child(5n+4) {
		margin-top: -25.875vw; // -23.875vw;
		margin-left: 4.625vw;
		width: 20vw;

		@include to($to-md) {
			margin-top: -40vw;
			margin-left: 3vw;
			width: 30vw;
		}
		@include to($to-sm) {
			margin-top: -75vw;
			margin-left: 3vw;
			width: 35vw;
		}

		transform:
			translateX(calc(3vw * var(--mx)))
			translateY(calc(3vh * var(--my)))
			translateZ(0);
	}

	&:nth-child(5n+5) {
		margin-top: 20vw; // 13.75vw;
		margin-left: 10vw;
		width: 30vw;

		@include to($to-md) {
			margin-top: 30vw;
			margin-left: 10vw;
			width: 40vw;
		}
		@include to($to-sm) {
			margin-top: 70vw;
			margin-left: 5vw;
			width: 40vw;
		}

		transform:
			translateX(calc(6vw * var(--mx)))
			translateY(calc(6vh * var(--my)))
			translateZ(0);
	}
	&:nth-child(5n+6) {
		margin-top: 15vw;

		@include to($to-sm) {
			margin-top: 50vw;
		}
	}
}

.mediaItemFrame {
	display: block;
	position: relative;

	transform:
		translateY(calc(var(--sy) * 20vh))
		translateZ(0);

	@include to($to-sm) {
		transform:
			translateY(calc(var(--sy) * 20vh))
			translateZ(0);
	}

	.mediaItem:nth-child(5n+2) & {
		@include to($to-sm) {
			transform:
				translateY(calc(var(--sy) * 30vh))
				translateZ(0);
		}
	}
	.mediaItem:nth-child(5n+4) & {
		transform:
			translateY(calc(var(--sy) * 30vh))
			translateZ(0);

		@include to($to-sm) {
			transform:
				translateY(calc(var(--sy) * 30vh))
				translateZ(0);
		}
	}

	&:before {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		margin: 4vw 0 0 2vw;
		border: 1px solid var(--stroke-light);
		will-change: transform;
		pointer-events: none;
		content: '';

		transform:
			translateY(calc(var(--fy) * -8vw))
			translateZ(0);
	}
}

:global(.is-dark-section) {
	.mediaItemFrame:before {
		border-color: var(--stroke-dark);
	}
}

.mediaItemBox {
	display: block;
	position: relative;
	overflow: hidden;
	backface-visibility: hidden;
}

.mediaItemEntity {
	display: block;
	position: relative;
	clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
	transition: clip-path var(--slow);

	&:before {
		display: block;
		height: 0;
		content: '';
	}

	span, img {
		display: block;
		z-index: 1;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.mediaItem:nth-child(5n+1) & {
		&:before {
			padding-top: 58.332%;
		}
	}
	.mediaItem:nth-child(5n+2) & {
		&:before {
			padding-top: 129.375%;
		}
	}
	.mediaItem:nth-child(5n+3) & {
		&:before {
			padding-top: 100%;
		}
	}
	.mediaItem:nth-child(5n+4) & {
		&:before {
			padding-top: 100%;
		}
	}
	.mediaItem:nth-child(5n+5) & {
		&:before {
			padding-top: 58.332%;
		}
	}
}

.mediaItemEntity ~ .mediaItemEntity {
	position: absolute;
	z-index: 1;
	left: -1px;
	top: -1px;
	right: -1px;
	bottom: -1px;
	margin: 0;

	span, img {
		width: 100% !important;
		height: 100% !important;
		object-fit: cover;
	}
}

.mediaItemEntity.isShown {
	z-index: 2;
	clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
	transition: clip-path var(--slow);
}
.mediaItemEntity.isDown {
	z-index: 1 !important;
}

/*
 * Вариант позиционирований, когда секция первая
 *//*
.main {
	margin-top: -6vw;
}

.main .track {
	top: 24vw;
	min-height: 50vh;

	@include to($to-sm) {
		top: 25vh;
	}
}

.main .mediaFeed {
	padding-top: 0 !important;
}

.main .mediaItem:nth-child(1) {
	margin-top: 0;
}

.main .mediaItem:nth-child(1) .mediaItemFrame {
	transform:
		translateY(calc(var(--sy) * -10vh));

	@include to($to-sm) {
		transform:
			translateY(calc(var(--sy) * 5vh));
	}
}*/

/*
 * Вариант позиционирований, когда секция между других
 */
.inter {
	margin-top: -6vw;

	@include to($to-sm) {
		margin-top: -12vw;
	}
}

.inter .track {
	top: 24vw;
	min-height: 50vh;

	@include to($to-sm) {
		top: 25vh;
	}
}

.inter .mediaFeed {
	padding-top: 0 !important;
}

.inter .mediaItem:nth-child(1) {
	margin-top: 0;
}