* {
  margin: 0;
  border: none;
  -webkit-overflow-scrolling: auto;
}

article, aside, figure, footer, header, nav, main, section, details, summary {
  display: block;
}
body, p, ul, li, ol, pre, caption, h1, h2, h3, h4, h5, h6, form, div, fieldset, legend {
  padding: 0;
}

html {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
html.ios-scroll-fixed {
  height: 100vh;
  backface-visibility: hidden;
}

body {
  display: block;
  position: relative;
  min-height: 100%;
  height: 100%;
  width: 100%;
  font: 400 .625em var(--font);
  color: var(--white);
  background: var(--graphite);
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: scrollbar;

  &.scroll-locked {
    overflow: hidden;
  }
  &.scroll-locked-fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

html:not(.is-ready-to-scroll) body {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

img {
  backface-visibility: hidden;
}
img, fieldset, iframe {
  border: 0;
}

li {
  list-style: none outside none;
}

a,
button {
  text-decoration: none;
  transition:
      color .4s,
      background .4s,
      border .4s,
      opacity .4s;
}

a svg,
button svg {
  pointer-events: none;
}

button {
  display: inline-flex;
  margin: 0;
  padding: 0;
  font: inherit;
  color: inherit;
  background: none;
  border: none;
  -webkit-touch-callout: none;
  user-select: none;
  text-align: left;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;

  &::-moz-focus-inner {
    border: 0;
  }
}

[fill] {
  transition: fill .4s;
}
[stroke] {
  transition: stroke .4s;
}

input, textarea {
  box-sizing: border-box;
}
input::-moz-focus-inner,
textarea::-moz-focus-inner,
select::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
input[type="text"],
input[type="password"],
button,
textarea {
  -webkit-appearance: none;
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: color 5000s ease-in-out 0s, background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: var(--text) !important;
}

input:required {
  box-shadow: none;
}
input:invalid {
  box-shadow: 0 0 0 transparent;
}