@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.module {
	display: flex;
}

.roll {
	display: block;
	position: relative;
	overflow: hidden;
	height: 1em;
}

.rollTrack {
	display: flex;
	flex-flow: column nowrap;
	position: relative;
	transform: translateY(0);
	transition:
		transform 1.6s,
		color .8s;
}

.accent {
	color: var(--accent);
}

.roll:nth-child(2) .rollTrack {
	transition-delay: 200ms;
}
.roll:nth-child(3) .rollTrack {
	transition-delay: 400ms;
}
.roll:nth-child(4) .rollTrack {
	transition-delay: 600ms;
}

.digit {
	display: block;
	text-align: center;
}
.digitAccent {
	color: var(--accent);
}