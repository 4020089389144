@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.property {
	display: block;
	position: relative;
	padding-top: 8px;
	margin: 88px 0;
	border-top: 1px solid var(--stroke-light);
	color: var(--text);
	cursor: default;

	@include to($to-md) {
		margin: 48px 0;
	}
	@include to($to-sm) {
		margin: 32px 0;
	}

	&:first-child {
		margin-top: 0;
	}
	&:last-child {
		margin-bottom: 0;
	}
}

.property + .property {
	margin-top: -32px;

	@include to($to-sm) {
		margin-top: -8px;
	}
}

.label {
	margin-bottom: 24px;
	font: 300 var(--fs-16) var(--font);
	color: var(--gray);
	transition: color var(--trans);

	@include from($from-hd) {
		font: 300 1vw/1.5 var(--font);
	}

	@include to($to-sm) {
		margin-bottom: 8px;
		font: 300 var(--fs-12) var(--font);
	}
}

.text {
	font: 400 38px/1.263 $font;
	letter-spacing: -0.02em;

	@include from($from-hd) {
		font: 400 2.375vw/1.263 var(--font);
	}

	@include to($to-lg) {
		font: 400 34px/1.263 $font;
	}
	@include to($to-md) {
		font: 400 28px/1.263 $font;
	}
	@include to($to-sm) {
		font: 400 20px/1.263 $font;
	}
}

/* * * With link * * */
a.property {
	text-decoration: none !important;
	color: var(--text) !important;
	border-bottom: none !important;
	cursor: pointer;

	svg {
		position: absolute;
		top: 16px;
		right: 0;
		width: 32px !important;
		height: 32px !important;
		fill: var(--stroke-light) !important;
		transition: fill var(--trans);

		@include to($to-sm) {
			width: 24px !important;
			height: 24px !important;
		}
	}

	&:hover {
		color: var(--accent) !important;

		svg {
			fill: var(--accent) !important;
		}

		.label {
			color: var(--accent);
		}
	}
}

:global(.is-dark-section) .property {
	color: var(--white);
}

:global(.is-dark-section) .label {
	color: var(--stroke-light);
}

:global(.is-dark-section) a.property {
	color: var(--white) !important;

	&:hover {
		color: var(--accent) !important;
	}
}
