@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.announce {
	display: block;
	position: relative;
	font: 300 var(--fs-16) var(--font);
	color: var(--gray);

	@include from($from-hd) {
		font: 300 1vw/1.5 var(--font);
	}

	@include to($to-md) {
		font: 300 var(--fs-16) var(--font);
	}
	@include to($to-sm) {
		font: 300 var(--fs-12) var(--font);
	}
}

.picture {
	display: block;
	position: relative;
	margin-bottom: 32px;
	filter: grayscale(1);
	transition: filter var(--slow);

	span, img {
		display: block !important;
		width: 100% !important;
		height: auto !important;
		transition: transform var(--slow);
	}

	@include to($to-sm) {
		margin-bottom: 16px;
	}
}

.title {
	color: var(--dark);
	transition: color var(--trans);
}

.text {
	margin-top: 8px;

	@include from($from-hd) {
		margin-top: .5vw;
	}
}

.announce:focus-visible {
	outline: none;
	box-shadow: none;
}

.announce:hover .picture,
.announce:focus-visible .picture {
	filter: grayscale(0);

	img {
		transform: scale(1.15);
		transition-duration: 1.6s;
	}
}
.announce:hover .title,
.announce:focus-visible .title {
	color: var(--accent);
}