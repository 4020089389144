@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.flow {
	flex-flow: row wrap;
	position: relative;
	z-index: 2;

	&:before {
		position: absolute;
		left: 0;
		right: 0;
		top: calc(200px + 5vw);
		bottom: 0;
		background: peachpuff;
	}
}

.darkEnd {
	&:before {
		background: var(--dark);
		content: '';
	}
}

.whiteEnd {
	&:before {
		background: var(--white);
		content: '';
	}
}

.card {
	:root {
		--q: 0;
	}

	/*
	 * Небольшой хак:
	 * -2.025px вместо -2px, чтобы при машстабе отличном от 100%
	 * ширина одной карточки не превышала 20% + 2px
	 */
	width: calc(20% + 2px);
	margin: 0 0 -2px -2.025px;

	@include from($from-sm) {
		&:nth-child(5n + 5) {
			transform: translateY(calc(30vw * var(--q)));
		}
		&:nth-child(5n + 4) {
			top: 25px;
			transform: translateY(calc(25vw * var(--q)));
		}
		&:nth-child(5n + 3) {
			top: 50px;
			transform: translateY(calc(20vw * var(--q)));
		}
		&:nth-child(5n + 2) {
			top: 75px;
			transform: translateY(calc(15vw * var(--q)));
		}
		&:nth-child(5n + 1) {
			top: 100px;
			transform: translateY(calc(10vw * var(--q)));
		}
	}
	@include from($from-sm) {
		&:nth-child(5n + 4) {
			top: 50px;
		}
		&:nth-child(5n + 3) {
			top: 100px;
		}
		&:nth-child(5n + 2) {
			top: 150px;
		}
		&:nth-child(5n + 1) {
			top: 200px;
		}
	}

	@include to($to-sm) {
		width: calc(50% + 1px);
		margin: 0 0 -1px -1px;

		&:nth-child(2n + 2) {
			transform: translateY(calc(-12px * var(--q) * 3));
		}
		&:nth-child(2n + 1) {
			transform: translateY(calc(12px * var(--q)));
		}
	}
}
