@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.module {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	backface-visibility: hidden;
	pointer-events: none;

	@supports (clip-path: inset(0 0 0 0)) {
		position: fixed;
	}

	@include to($to-sm) {
		display: block;
		position: relative;
		width: 100%;
		overflow: hidden;

		&:before {
			display: block;
			height: 0;
			padding-bottom: 75%;
			content: '';

			/*
			 * Хак для округления на полпулярных телефонах
			 */
			@media (min-width: 375px) and (max-width: $to-sm) {
				padding-bottom: 74.935%;
			}
			@media (min-width: 390px) and (max-width: $to-sm) {
				padding-bottom: 74.875%;
			}
		}
	}

	video, picture, img {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&:after {
		position: absolute;
		z-index: 2;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background: linear-gradient(0deg, rgba(17, 17, 17, 0.2), rgba(17, 17, 17, 0.2));
		content: '';
	}
}

.video {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}