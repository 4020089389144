@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.text {
	font: 300 var(--fs-24) var(--font);
	color: var(--gray);

	@include to($to-lg) {
		font: 300 20px/1.332 var(--font);
	}
	@include to($to-md) {
		font: 300 14px/1.54 var(--font);
	}
	@include to($to-sm) {
		font: 300 var(--fs-12) var(--font);
	}

	p ~ p {
		margin-top: 1.667em; // 40px;
	}
}