@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.section {
	position: relative;
	z-index: auto;
	padding: 0 0 352px;
	color: var(--white);
	background: var(--dark);

	@include to($to-md) {
		padding: 0 0 240px;
	}
	@include to($to-sm) {
		padding: 0 0 120px;
	}
}

.title {
	appearance: none;
}

.feed {
	position: relative;
	z-index: 10;
}