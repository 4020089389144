@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.breadcrumbs {
	display: flex;
	flex-flow: row wrap;
	position: relative;
	margin-bottom: 16px;
	font: 300 var(--fs-16) var(--font);
	color: var(--gray);
	cursor: default;
	overflow: hidden;

	@include from($from-hd) {
		font: 300 1vw/1.5 var(--font);
	}

	@include to($to-md) {
		left: 0;
		margin-bottom: 20px;
		font: 300 var(--fs-14) var(--font);
	}
}

.item {
	position: relative;
	margin: 0 16px 8px 0;
	transform: translateY(200%);
	opacity: 0;

	&:not(:last-child):after {
		position: absolute;
		right: -11px;
		top: 0;
		content: '/';
	}
}

.link {
	text-decoration: none;
	color: inherit;

	&:focus-visible {
		outline: none;
		box-shadow: none;
	}

	&:hover,
	&:focus-visible {
		color: var(--accent);
	}
}

//:global(.is-white-section) .link:hover {
//	color: var(--accent);
//}

:global(.is-shown) .item {
	transform: translateY(0);
	opacity: 1;
	transition:
		opacity var(--slow),
		transform var(--slow);
	transition-delay: .4s;
}