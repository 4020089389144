@import '/src/assets/styles/config/variables';
@import '/src/assets/styles/config/mixins';

.module {
	position: relative;
	min-height: 100vh;
	content-visibility: auto;

	@include to($to-md) {}
	@include to($to-sm) {}
}

.sticky {
	position: sticky;
	top: 0;
	height: 100vh;
	overflow: hidden;
}